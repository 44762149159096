import React, { useEffect } from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./toast.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface IToast extends IDefaultProps {
  label: string;
  type: "info" | "success" | "warning" | "error" | "default" | "dark";
}
interface IProps extends IDefaultProps {
  close: any;
  closeParam: any;
  data: IToast;
}

const Toast = (props: IProps) => {

  useEffect(() => {
    const options = {
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => {
        props.close(props.closeParam);
      }
    }

    switch(props.data.type) {
      case "info":
        toast.info(props.data.label, options);
        break;
      case "success":
        toast.success(props.data.label, options);
        break;
      case "warning":
        toast.warning(props.data.label, options);
        break;
      case "error":
        toast.error(props.data.label, options);
        break;
      case "dark":
        toast.dark(props.data.label, options);
        break;
      default:
        toast(props.data.label, options);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={makeClassName("toast", props)}>
      <ToastContainer />
    </div>
  );
};

export default Toast;
