import React from "react";
import { IPopupProps } from "components/common/popup/IPopupProps";
import Button from "components/common/button/Button";
import Spinner from "components/common/spinner/Spinner";
import { makeClassName } from "utils/componentUtils";
import "./popup.scss";

const Popup = (props: IPopupProps) => {
  const closeFromBg = () => {
    if (props.closeFromBackground === true && props.closable !== false) close();
  };

  const close = () => {
    if (props.closable !== false) props.closeParam !== undefined ? props.onClose(props.closeParam) : props.onClose(false);
  };

  const contentStyle:any = {};
  if (props.height !== undefined) {
    contentStyle.height = props.height;
  }
  return (
    <div className={makeClassName("popup", props)}>
      {props.hideBackground !== true && <div className="background" onClick={closeFromBg}></div> }
      <div style={contentStyle} className="content">
        <div className="header">
          {props.title && (
            <div className="title">
              {props.titleIcon && <div className="icon">{props.titleIcon}</div>}
              <span> {props.title}</span>
            </div>
          )}
          <Button className="btnClose" onClick={close} />
        </div>
        <div className={props.scrollableContent ? "scrollableItems" : "items"}>
          {props.content}
          {props.children}
        </div>
        {props.footerContent && <div className="footer">{props.footerContent}</div>}
        {props.showPreloader === true ? <Spinner /> : null}
      </div>
    </div>
  );
};

export default Popup;
