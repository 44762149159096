import React, { useState, useEffect } from "react";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./checkbutton.scss";
import Button from "../button/Button";
import { IValue } from "interfaces/common/IValue";

interface IProps  extends IDefaultProps {
  key?: React.Key | null | undefined
  name?: string;
  color: string;
  label: string;
  value: IValue;
  checked?: boolean;
  onChange?: any;
  tooltip?: string;
  checkFromParentOnly?: boolean;
}

const CheckButton = (props: IProps) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked)
    // eslint-disable-next-line
  }, [props.checked]);

  const onClick = () => {
    if (!props.checkFromParentOnly)
      setChecked(!checked);
    if (props.onChange) props.onChange(props.value);
  };

  
  return (
    <Button
      active={true}
      className={"checkbutton" + ((checked === true) ? " checkbtn--" : "  checktoggled--") + props.color}
      onClick={onClick}
      //content={<RiCheckLine />}
      tooltip={props.tooltip ? props.tooltip : "Séléctionner"}
      {...props}
    />
  );
};

export default CheckButton;