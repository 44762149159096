import React from "react";
import "./expandButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";
import { MdExpandLess } from "react-icons/md";
import { MdExpandMore } from "react-icons/md";

interface IProps extends IButtonProps {
  expanded?: boolean;
}

const ExpandButton = (props: IProps) => {
  return (
    <Button
      className="expandButton"
      content={props.expanded === true ? <MdExpandLess /> : <MdExpandMore />}
      tooltip={props.tooltip ? props.tooltip : "développer"}
      {...props}
    />
  );
};

export default ExpandButton;
