import React, { useEffect, useRef, useState } from "react";
import "./dropdown.scss";
import { IDropdownProps } from "./IDropdownProps";
import Select from "react-select";
import { makeClassName, makeStyleProperties } from "utils/componentUtils";
import { deepClone } from "utils/cloneUtils";
import { arrayFilter, createArrayFromKey, sortArray, sortArrayByIndex } from "utils/arrayUtils";
import { useTranslate } from "services/translationService";

const Dropdown = (props: IDropdownProps) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const { t } = useTranslate();
  const ref = useRef(null);
  const [isDefaultInactive, setIsDefaultInactive] = useState(false);

  useEffect(() => {
    if (!props.options.length) {
      setOptions([]);
      setValue(null);
      return;
    }
    const newOptions: any = [];

    props.options.forEach((element: any) => {
      const newOption = deepClone(element);
      if (props.optionsIdent !== undefined) newOption.label = element[props.optionsIdent];
      if (props.ident !== undefined) newOption.value = element[props.ident];
      newOptions.push(newOption);
    });

    if (!props.noSort) sortArray(newOptions, t);
    if (props.sortIdent) sortArrayByIndex(newOptions, props.sortIdent);

    if (props.selectAll !== undefined) {
      newOptions.unshift({
        label: props.selectAll,
        value: -1,
      });
    }

    if (props.selectFirstOption) {
      setValue(newOptions[0]);
    }

    setOptions(newOptions);
    if (props.autoSelectIfOneItem === true && props.options.length && props.options.length === 1) {
      const newValue = deepClone(props.options[0]);
      if (newValue.label === undefined && props.optionsIdent !== undefined) newValue.label = newValue[props.optionsIdent];
      if (newValue.value === undefined && props.ident !== undefined) newValue.value = newValue[props.ident];
      setValue(props.multi ? [newValue] : newValue);
      change(props.multi ? [newValue] : newValue);
    }
    // eslint-disable-next-line
  }, [props.options]);

  useEffect(() => {
    let newValue = deepClone(props.value);
    let clearedOnNullValue = false;
    if (props.clearOnNullValue === true && props.value == null) {
      clearedOnNullValue = true;
      setValue(null);
      return;
    }
    if (!clearedOnNullValue && (props.value == null || props.options.length === undefined)) return;
    if (props.multi === true) {
      newValue.forEach((element: any) => {
        if (element.label === undefined && props.optionsIdent !== undefined) element.label = element[props.optionsIdent];
        if (element.value === undefined && props.ident !== undefined) element.value = element[props.ident];
      });
    } else {
      if (newValue.label === undefined && props.optionsIdent !== undefined) newValue.label = newValue[props.optionsIdent];
      if (newValue.value === undefined && props.ident !== undefined) newValue.value = newValue[props.ident];
    }
    setValue(newValue);

    // eslint-disable-next-line
  }, [props.value]);

  const change = (option: any) => {
    const ident = props.ident !== undefined ? props.ident : "value";
    setValue(deepClone(option));
    if (props.onChange) {
      if (props.multi === true) {
        props.onChange(option);
      } else {
        if (props.selectOnlyIdent === true) {
          props.onChange(option ? option[props.ident as any] : "");
        } else {
          props.onChange(option);
        }
      }
    }
    if (props.onNativeChange) {
      if (props.multi === true) {
        const selectAllElement = arrayFilter(option, "value", -1);
        if (props.selectAll !== undefined && selectAllElement) {
          const newValues = deepClone(props.options);
          newValues.forEach((newValue: any) => {
            if (newValue.label === undefined && props.optionsIdent !== undefined) newValue.label = newValue[props.optionsIdent];
            if (newValue.value === undefined && props.ident !== undefined) newValue.value = newValue[props.ident];
          });
          setValue(newValues);
          props.onNativeChange({
            target: {
              name: props.name,
              value: createArrayFromKey(props.options, ident),
            },
          });
          return;
        }
        props.onNativeChange({
          target: {
            name: props.name,
            value: createArrayFromKey(option, ident),
          },
        });
      } else {
        if (!option) {
          props.onNativeChange({
            target: {
              name: props.name,
              value: null,
            },
          });
        } else {
          props.onNativeChange({
            target: {
              name: props.name,
              value: option[ident],
            },
          });
        }
      }
    }
  };

  const handleFilter = (option: any, inputValue: any) => {
    let inputExistsInDropdown = false;
    if (
      option &&
      option.label &&
      ((typeof option.label === "string" && option.label.toLowerCase().includes(inputValue.toLowerCase())) || typeof option.label !== "string")
    )
      inputExistsInDropdown = true;
    return inputExistsInDropdown;
  };

  const defaultValueExists = () => {
    return props.defaultInactiveValue !== undefined && props.defaultInactiveValue !== null;
  };

  useEffect(() => {
    setIsDefaultInactive(false);
    if (
      (props.value === null || props.value === undefined) &&
      defaultValueExists() &&
      props.defaultInactiveValue.name !== undefined &&
      props.defaultInactiveValue.isActiv === false
    ) {
      const newValue = deepClone(props.defaultInactiveValue);
      if (newValue.label === undefined && props.optionsIdent !== undefined) newValue.label = newValue[props.optionsIdent];
      if (newValue.value === undefined && props.ident !== undefined) newValue.value = newValue[props.ident];
      setValue(newValue);
      setIsDefaultInactive(true);
    }

    // eslint-disable-next-line
  }, [props.defaultInactiveValue]);

  return (
    <div
      ref={ref}
      style={makeStyleProperties(props)}
      className={makeClassName(
        "dropdown" + (props.multi === true && props.filter === true ? " multiEdit" : "") + (props.enlarge === true ? " enlarge" : ""),
        props
      )}
    >
      {props.label && (
        <span className="label">
          {props.label}
          {props.required ? <span className="required"></span> : ""}
        </span>
      )}
      <div className={"header" + (props.multi === true ? " multi" : "")}>
        <Select
          isLoading={props.loader}
          onMenuOpen={props.onMenuOpen}
          menuPlacement="auto"
          isClearable={!props.hideDeleteButton}
          className={"select" + (isDefaultInactive ? " defaultInactive" : "")}
          classNamePrefix="select"
          isMulti={props.multi}
          options={options}
          placeholder={props.placeholder}
          onChange={change}
          value={value}
          isDisabled={props.disabled}
          isSearchable={props.filter}
          closeMenuOnSelect={!props.multi}
          autoFocus={props.autoFocus}
          filterOption={handleFilter}
          noOptionsMessage={() => "Aucun résultat"}
          loadingMessage={() => "Chargement..."}
          {...(props.overlay && { menuPortalTarget: document.body, styles: { menuPortal: (base : any) => ({ ...base, zIndex: 9999 }) } })}
        />
        {props.tooltip !== undefined && <span className="tooltip">{props.tooltip}</span>}
      </div>
      {props.error ? <span className="error">{props.error}</span> : null}
    </div>
  );
};

export default Dropdown;
