import React from "react";
import "./pencilButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";
import { FaPencilAlt } from "react-icons/fa";

interface IProps extends IButtonProps {}

const PencilButton = (props: IProps) => {
  return (
    <Button className="pencilButton round" content={<FaPencilAlt />} tooltip={props.tooltip ? props.tooltip : "Modifier"} {...props} />
  );
};

export default PencilButton;
