import { useHistory } from "react-router-dom";
import { IHttpRequest } from "interfaces/IHttpRequest";
import { iterateObject, iterateObject2 } from "utils/objectUtils";
import { httpMethodTypes } from "services/http/httpConf";
import { useState } from "react";
import { IRoute } from "interfaces/IRoute";
import { getPathFromParams } from "utils/stringUtils";

export const useRoute = () => {
  const history = useHistory();
  const [query] = useState(new URLSearchParams(history.location.search));

  const setQuery = (key: string, value: any) => {
    if (query.has(key)) query.delete(key);
    if (value) query.set(key, value);
    history.push(history.location.pathname + "?" + query.toString());
  };

  const getQuery = (key: string) => {
    let _query = new URLSearchParams(history.location.search);
    return _query.get(key);
  };

  const setQueries = (qs: any) => {
    let _query = new URLSearchParams(history.location.search);
    iterateObject2(_query, (key: string) => _query.delete(key));
    iterateObject(qs, (key: string, value: string) => {
      if (_query.has(key)) _query.delete(key);
      if (value) _query.set(key, value);
    });
    history.push(history.location.pathname + "?" + _query.toString());
  };

  const getQueries = (callback: any, finalCallback: any = null) => {
    let _query: URLSearchParams = new URLSearchParams(history.location.search);
    let num = 0;
    iterateObject2(_query, (key: string, value: string) => {
      num++;
    });
    let cIndex = 0;
    if (num === 0 && finalCallback) finalCallback({});
    let q: any = {};
    iterateObject2(_query, (key: string, value: string) => {
      cIndex++;
      q[key as any] = value;
      callback({ [key]: value });
      if (cIndex === num) {
        if (finalCallback) finalCallback(q);
      }
    });
  };

  const addParams = (route: IHttpRequest, params: any = null) => {
    if (route.method === httpMethodTypes.POST) return route;

    let p = "";

    if (route.addInitialQuery) {
      p += (p === "" ? "?" : "&") + route.addInitialQuery;
    }
    params !== null &&
      iterateObject(params, (key: any, value: any) => {
        p += (p === "" ? "?" : "&") + key + "=" + value;
      });

    if (route.addFinalQuery) {
      p += (p === "" ? "?" : "&") + route.addFinalQuery;
    }

    return {
      ...route,
      endPoint: route.endPoint + p,
    };
  };

  const getRouteFromParams = (route: IHttpRequest, params: any = null) => {
    return {
      ...route,
      endPoint: getPathFromParams(route.endPoint, params),
    };
  };

  const navigateWithQuery = (route: IRoute, param: any) => {
    const iq = new URLSearchParams(history.location.search).toString();
    history.push(route.path + "/" + param + (iq.length === 0 ? "" : "?" + iq));
  };

  const navigateToRoute = (route: IRoute) => {
    history.push(route.path);
  };

  const navigateToExternalRoute = (route: IRoute) => {
    window.location.href = process.env.REACT_APP_FRONTEND_V1_ROOT as string;
  };

  const getPathWithQuery = (route: IRoute, params: any) => {
    let p = "";

    iterateObject(params, (key: any, value: any) => {
      p += (p === "" ? "?" : "&") + key + "=" + value;
    });

    return route.path + p;
  };

  return {
    setQuery,
    getQuery,
    addParams,
    setQueries,
    getQueries,
    navigateWithQuery,
    navigateToRoute,
    navigateToExternalRoute,
    getRouteFromParams,
    getPathWithQuery,
  };
};
