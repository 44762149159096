import React, { useState } from "react";
import { smartStringValidator, smartValidationSchema } from "hooks/useSmartForm";
import { useFormik } from "formik";
import { useRoute } from "hooks/useRoute";

import { IAllInterventionPointsResult } from "../IInterventionPoint";
import { IPopupProps } from "components/common/popup/IPopupProps";
import { ISmartAction, ISmartState } from "interfaces/ISmart";

import { smartConnect } from "app/appUtils";
import { updateInterventionPointApi } from "config/apiConf";
import { updateInterventionPointModule } from "config/modulesConf";

import Column from "components/common/column/Column";
import Input from "components/common/input/Input";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import Popup from "components/common/popup/Popup";
import Row from "components/common/row/Row";

interface IProps extends IPopupProps {
  userSettings?: ISmartState;
  interventionPoint: IAllInterventionPointsResult;
  updateInterventionPoint?: ISmartState;
  updateInterventionPointActions?: ISmartAction;
}

const UpdateInterventionPointSectorsPopup = (props: IProps) => {
  const { getRouteFromParams } = useRoute();
  const [disabledBtns, setDisabledBtns] = useState(true);
  const interventionPoint = props.interventionPoint;

  const update = (values: IAllInterventionPointsResult) => {
    props.updateInterventionPointActions?.httpCall(
      getRouteFromParams(updateInterventionPointApi, { id: interventionPoint.id }),
      { ...values, interventionPointId: interventionPoint.id }
    );
  }

  const formik = useFormik({
    initialValues: props.interventionPoint,
    validationSchema: smartValidationSchema({
      code: smartStringValidator().required("Le champs est requis"),
      name: smartStringValidator()
        .nullable()
        .max(50, "erreur. Il doit avoir 50 caractère au maximum."),
      sector1: smartStringValidator()
        .nullable()
        .max(50, "erreur. Il doit avoir 50 caractère au maximum."),
      sector2: smartStringValidator()
        .nullable()
        .max(50, "erreur. Il doit avoir 50 caractère au maximum."),
      sector3: smartStringValidator()
        .nullable()
        .max(50, "erreur. Il doit avoir 50 caractère au maximum."),
    }),
    onSubmit: (values: IAllInterventionPointsResult) => {
      update(values);
      setDisabledBtns(true);
    },
  });

  const handleFormikChanged = (e: any) => {
    setDisabledBtns(false);
    formik.handleChange(e);
  }

  const code : keyof IAllInterventionPointsResult = "code";
  const name : keyof IAllInterventionPointsResult = "name";
  const sector1 : keyof IAllInterventionPointsResult = "sector1";
  const sector2 : keyof IAllInterventionPointsResult = "sector2";
  const sector3 : keyof IAllInterventionPointsResult = "sector3";

  return (
    <Popup scrollableContent {...props} bigSize={true} title={"Secteurs de l'utilisateur"} height={"500px"}
      footerContent={<Row><NeuroButton color="green" type="submit" size={'normal'} onClick={formik.handleSubmit} label={"Sauvegarder"} disabled={disabledBtns} /></Row>}>
      <Column>
        <Row>
          <Input
            error={formik.errors.code}
            label={"CodePos"}
            name={code}
            onNativeChange={handleFormikChanged}
            required={true}
            value={formik.values.code}
          />
          <Input
            error={formik.errors.name}
            label={"Name"}
            name={name}
            onNativeChange={handleFormikChanged}
            value={formik.values.name}
          />
        </Row>
        <Row />
        <Row fullSize>
          <Input
            error={formik.errors.sector1}
            label={"sector1"}
            name={sector1}
            onNativeChange={handleFormikChanged}
            value={formik.values.sector1}
          />
          <Input
            error={formik.errors.sector2}
            label={"sector2"}
            name={sector2}
            onNativeChange={handleFormikChanged}
            value={formik.values.sector2}
          />
          <Input
            error={formik.errors.sector3}
            label={"sector3"}
            name={sector3}
            onNativeChange={handleFormikChanged}
            value={formik.values.sector3}
          />
        </Row>
      </Column>
    </Popup>
  );
};

export default smartConnect(
  [updateInterventionPointModule],
  [updateInterventionPointModule]
)(UpdateInterventionPointSectorsPopup);
