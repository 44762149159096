import React, { useState, useEffect } from "react";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./radiogroup.scss";
import CheckButton from "../checkbutton/CheckButton";
import { IValue } from "interfaces/common/IValue";

interface IProps  extends IDefaultProps {
  name?: string;
  color: string;
  values: IValue[];
  selected?: IValue;
  onChange?: any;
  error?: string;
}

const RadioGroup = (props: IProps) => {
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    if (props.selected !== undefined) setSelected(props.selected);
  }, [props.selected]);

  const onChange = (val: IValue) => {
    setSelected(val);
    if (props.onChange) props.onChange(val);
  };

  return (
    <div className="radioGroup">        
      {
          Array.isArray(props.values) && props.values.map((val: IValue, index: any) => {
              return (
                <CheckButton
                  key={val.name.toString()}
                  checkFromParentOnly={true}
                  color={props.color}
                  label={val.name}
                  value={val}
                  onChange={onChange}
                  //onChange={(val: boolean) => uc.calculate(val, props.row.original.id)}
                  checked={selected?.id === val.id}/>
              );
          })
      }
      {props.error !== undefined && props.error !== "" ? <div className={"error"}>{props.error}</div> : null}
    </div>
  );
};

export default RadioGroup;