import moment from "moment";

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const uppercase = (s: string) => {
  if (!s) return null;
  return s.toUpperCase();
};

export const lowercase = (s: string) => {
  if (!s) return null;
  return s.toLowerCase();
};

export const replaceTags = (s: string, values: any) => {
  for (let i = 0; i < values.length; i++) s = s.replace("#" + (i + 1), values[i]);
  return s;
};

export const getPathFromParams = (path: string, params: any = null, sanitize: boolean = false) => {
  let finalPath = path;

  if (params) for (const [key, value] of Object.entries(params)) finalPath = finalPath.replace(`:${key}`, value as any);

  if (sanitize || !params) {
    const sp = finalPath.split("/:");
    sp.forEach((element: any, index: number) => {
      finalPath = finalPath.replace("/:" + element, "");
    });
  }

  return finalPath;
};

export const validateTime = (value: string) => {
  if (/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(value) && moment(value, "HH:mm").isValid()) {
    return true;
  } else {
    return false;
  }
};

export const isOtherLabel = (value: string, objectId: any) => {
  const lowerCaseValue = value ? value.toLowerCase() : "";
  if (objectId && (lowerCaseValue === "other" || lowerCaseValue === "autre" || lowerCaseValue === "others" || lowerCaseValue === "autres"))
    return true;
  else return false;
};
