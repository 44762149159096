import React, { useState } from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./fileUpload.scss";
import { AiOutlineUpload } from "react-icons/ai";
import { useTranslate } from "services/translationService";
import * as lngConf from "config/lngConf";

interface IProps extends IDefaultProps {
  label?: string;
  children?: any;
  content?: any;
  onChange?: any;
  accept?: string;
  name?: string;
  value?: any;
  onNativeChange?: any;
  error?: string;
  validationTypes: any;
  mini?: boolean;
  success?: string;
}

const FileUpload = (props: IProps) => {
  const { t } = useTranslate();
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [success] = useState("");
  const change = (e: any) => {
    const file = e.target.files[0];
    const type = file && (file.type as string).split("/")[1];
    if (props.validationTypes.length > 0 && props.validationTypes.indexOf(type) > -1) {
      var reader = new FileReader();
      setError("");
      reader.onload = (e: any) => {
        setName(file.name);
        if (props.onChange) props.onChange(file);
        if (props.onNativeChange) {
          const newData: any = {
            content: e.target.result,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            size: file.size,
            type: file.type,
            webkitRelativePath: file.webkitRelativePath,
          };
          props.onNativeChange({ target: { value: newData, name: props.name } });
        }
      };
      reader.readAsDataURL(file);
    } else setError(t(lngConf.ERR_INCORRECT_FILE_FORMAT));
  };
  return (
    <div className={makeClassName(props.mini ? "" : "fileUpload", props)}>
      <input type="file" onChange={change} accept={props.accept} />
      {!props.mini && (
        <div className="content">
          <AiOutlineUpload />
          {props.label && <span className="label">{props.label}</span>}
          <span className="name">{name}</span>
          {props.content}
          {props.children}
        </div>
      )}
      {props.error !== undefined && props.error !== "" ? <div className={"error"}>{props.error}</div> : null}
      {error !== "" ? <div className={"error"}>{error}</div> : null}
      {props.success !== undefined && props.success !== "" ? <div className={"success"}>{props.success}</div> : null}
      {success !== "" ? <div className={"success"}>{success}</div> : null}
    </div>
  );
};

export default FileUpload;
