import React from "react";
import Row from "components/common/row/Row";
import { getRandomColor } from "utils/colorUtils";

// import Label from "components/giCore/label/Label";
// import Row from "components/giCore/row/Row";

import "./stackBarChart.scss";
import Label from "components/common/label/Label";

export type SimpleStackBarData = {
  color: string;
  colorText: string;
  title: string;
  value: number;
  valueText: string;
};

interface IProps {
  data: SimpleStackBarData[];
  height?: string;
  id: string;
}

const StackBarToolTip = (props: SimpleStackBarData) => {
  return (
    <div className="stackBarToolTip">
      <Row backgroundColor={"lightgray"} padding={"5px"}>
        <Label text={props.title} color="gray"/>
      </Row>
      <Row height={"30px"} backgroundColor="white" justifyContent={"center"} alignItems={"center"} padding={"5px"} spacing={"5px"}>
        <Label text={"●"} style={{color: props.color}} width={"auto"} fontSize={"30px"} marginBottom={"5px"}/>
        <Label text={`${props.colorText} :`} color="gray"/>
        <Label text={props.valueText}/>
      </Row>
    </div>
  );
};

const StackBarChart = (props: IProps) => {
  return (
    <Row className="stackBarChart" height={props.height} width={'100%'} spacing={"1px"}>
      {props.data?.map((d, index) => {
        if (d.value > 0)
          return (
            <Row className="stackBar" flex={d.value} key={`${props.id}-${index}`} backgroundColor={d.color ?? getRandomColor()} alignItems={"center"} justifyContent={"center"}>
              <StackBarToolTip {... d}/>
              <Label text={d.value.toString()} color={"white"} size={"small"} weight={"bold"}/>
            </Row>);
        else
          return null;
      })}
    </Row>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default StackBarChart;