export const actionTypes = {
  RESET: "RESET",
  OP_STARTED: "OP_STARTED",
  DATA_RECEIVED: "DATA_RECEIVED",
  ERRORS: "ERROR",
};

export const saveTypes = {
  LOCAL_STORAGE: "LOCAL_STORAGE",
};
