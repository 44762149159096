import React, { useState, useEffect } from "react";
import { useRoute } from "hooks/useRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import { nameOf } from "utils/nameOfUtils";
import { useChecks } from "hooks/useChecks";
import moment from "moment";

import { ITableProps } from "components/common/table/ITable";
import { calculatePageFilters } from "utils/pageUtils";
import Table from "components/common/table/Table";

import { interventionPointsNextAvailabilitiesModule, selectedCampaignModule } from "config/modulesConf";
import { getInterventionPointByAvailabilityApi } from "config/apiConf";

import Checkbox from "components/common/checkbox/Checkbox";
import { deepClone } from "utils/cloneUtils";
import { IAllCampaignsResult } from "containers/campaigns/ICampaigns";
import ExpandButton from "components/buttons/expandButton/ExpandButton";
import MoreButton from "components/buttons/moreButton/MoreButton";
import Row from "components/common/row/Row";
import { MORE_VISIBLE_ITEMS } from "config/conf";
import { IInterventionPointNextAvailabilities, IInterventionPointNextAvailabilitiesData, IInterventionPointNextAvailabilityResult } from "containers/availabilities/IInterventionPointNextAvailabilities";
import { createComponent } from "utils/componentUtils";
import { useMore } from "hooks/useMore";
import Label from "components/common/label/Label";

interface IProps {
  selectedCampaign?: ISmartState;
  interventionPointsNextAvailabilities?: ISmartState;
  interventionPointsNextAvailabilitiesActions?: ISmartAction;
  onSelectInterventionPoint: any;
}

const SelectInterventionPoint = (props: IProps) => {
  const { addParams } = useRoute();
  const uc = useChecks({ single: true });
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const umNextAvailabilities = useMore({ visibleItems: MORE_VISIBLE_ITEMS });
  const selectedCampaign: IAllCampaignsResult = props.selectedCampaign?.data;
  const data: IInterventionPointNextAvailabilitiesData = props.interventionPointsNextAvailabilities?.data;
  const [selectedInterventionPoint, setSelectedInterventionPoint]: [IInterventionPointNextAvailabilityResult, any] = useState(null) as any;

  useEffect(() => {
    props.interventionPointsNextAvailabilitiesActions?.reset();
    return () => {
      props.interventionPointsNextAvailabilitiesActions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.selectedCampaign?.opCompleted) {
      props.interventionPointsNextAvailabilitiesActions?.reset();
      getData(crrPage, pageSize, tableFilters, tableSorter)
    }
    // eslint-disable-next-line
  }, [props.selectedCampaign?.opCompleted]);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);

    const filter: any = { pageNumber: cPage, pageSize: pageSize, withMissionNumber: true };
    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    let newFilter = filter;
    if (pageSize === 0) {
      newFilter = deepClone(filter);
      newFilter.pageSize = "";
    }

    //Dates
    newFilter.dateFrom = moment().add(3, 'day').format('YYYY-MM-DD');
    newFilter.dateTo = moment().add(1, 'week').endOf('week').format('YYYY-MM-DD');

    //CampaignId
    newFilter.CampaignId = selectedCampaign?.id; 

    props.interventionPointsNextAvailabilitiesActions?.httpCall(addParams(getInterventionPointByAvailabilityApi, newFilter));
    setTblLoader(true);
    //props.interventionPointsActions?.functionCall( () => { return { data: mockInterventionPoints } } );
  };

  useEffect(() => {
    if (props.interventionPointsNextAvailabilities?.opCompleted) {
      formatData();
      setTblData(data.results);
      if (data?.results?.length === 1)
        selectTheOnlyOne(data?.results[0]);

      setTblLoader(false);
      umNextAvailabilities.setValues(data.results, "id", "promoterAvailabilities");
    }
    // eslint-disable-next-line
  }, [props.interventionPointsNextAvailabilities?.opCompleted]);

  useEffect(() => {
    if (props.interventionPointsNextAvailabilities?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.interventionPointsNextAvailabilities?.errors]);

  const formatData = () => {
    data.results?.forEach(x => {
      const availableDates = new Set<string>();

      x.promoterAvailabilities?.forEach(promoterAvailability => {
        promoterAvailability.availabilities?.forEach(availability => availableDates.add(moment(availability).format('DD-MM-YYYY')));
      });

      //@ts-ignore
      x.promoterAvailabilities = [...availableDates];
      return data;
    });
  }

  const selectTheOnlyOne = (interventionPoint: IInterventionPointNextAvailabilityResult) => {
    uc.calculate(true, interventionPoint.id);
    setSelectedInterventionPoint(interventionPoint);
    props.onSelectInterventionPoint(interventionPoint);
  };

  const selectRow = (row: any) => {
    if (row.original.id === selectedInterventionPoint?.id)
    {
      uc.calculate(false, row.original.id);
      setTblData(data.results);
      setSelectedInterventionPoint(null);
      props.onSelectInterventionPoint(null);
      return;
    }
    uc.calculate(true, row.original.id);
    setTblData(data.results.filter(ip => ip.id === row.original.id));
    setSelectedInterventionPoint(row.original);
    props.onSelectInterventionPoint(row.original);
  };

  const onHeaderFilterChange = (colId: any, value: any) => {
    uc.calculate(false);
    setSelectedInterventionPoint(null);
    props.onSelectInterventionPoint(null);
    getData(1, pageSize, { [colId]: value }, tableSorter)
  };

  var disabled = false;
  return (
    <Table
      totalRows={data?.totalRows}
      minHeight={selectedInterventionPoint ? '140px' : "350px"}
      maxHeight={"450px"}
      onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
      onHeaderFilterChange={onHeaderFilterChange}
      onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
      sortBy={tableSorter}
      maxPages={data?.pageCount}
      loader={tblLoader}
      data={tblData}
      crrPage={crrPage}
      onRowSelect={selectRow}
      selectedRows={uc.checksData}
      columns={[
        {
          headerFilterProps: {
            type: "number",
          },
          Header: "x",
          hideHeaderLabel: true,
          headerFilter: false,
          columnWidth: 30,
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) => (
            <Checkbox
              disabled={disabled}
              onChange={(val: boolean) => selectRow(props.row)}
              checked={selectedInterventionPoint !== null && selectedInterventionPoint.id === props.row.original.id}//uc.checksData[props.row.original.id]}
            />
          ),
        },
        {
          Header: "POS",
          columnWidth: 1,
          columnMinWidth: 50,
          center: true,
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.code),
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            <Row justifyContent={"center"}>
              <p>
                {(props.row.original as IInterventionPointNextAvailabilities).code
                  ? (props.row.original as IInterventionPointNextAvailabilities).code
                  : null}
              </p>
            </Row>
        },
        {
          Header: "Zone",
          columnWidth: 1,
          columnMinWidth: 50,
          center: true,
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.sector3),
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            <Row justifyContent={"center"}>
              <p>
                {(props.row.original as IInterventionPointNextAvailabilities).sector3
                  ? (props.row.original as IInterventionPointNextAvailabilities).sector3
                  : null}
              </p>
            </Row>
        },
        {
          Header: "Area",
          columnWidth: 1,
          center: true,
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.sector2),
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            <Row justifyContent={"center"}>
              <p>
                {(props.row.original as IInterventionPointNextAvailabilities).sector2
                  ? (props.row.original as IInterventionPointNextAvailabilities).sector2
                  : null}
              </p>
            </Row>
        },
        {
          Header: "Secteur",
          columnWidth: 1,
          center: true,
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.sector1),
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            <Row justifyContent={"center"}>
              <p>
                {(props.row.original as IInterventionPointNextAvailabilities).sector1
                  ? (props.row.original as IInterventionPointNextAvailabilities).sector1
                  : null}
              </p>
            </Row>
        },
        {
          Header: "Name",
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.name),
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            (props.row.original as IInterventionPointNextAvailabilities).name
              ? (props.row.original as IInterventionPointNextAvailabilities).name
              : null,
        },
        {
          Header: "Adresse",
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.address),
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            (props.row.original as IInterventionPointNextAvailabilities).address
              ? (props.row.original as IInterventionPointNextAvailabilities).address
              : null,
        },
        {
          Header: "Dispo sur 2 semaines",
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.promoterAvailabilities),
          sortable: false,
          headerFilter: false,
          headerActions: [
            <ExpandButton
              expanded={umNextAvailabilities.getExpanded()}
              onClick={umNextAvailabilities.setExpanded}
            />,
          ],
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            createComponent(
              umNextAvailabilities.getValues(props.row.original.id),
              props.value.length > MORE_VISIBLE_ITEMS ? (
                <MoreButton
                  expanded={umNextAvailabilities.getRowExpanded(
                    props.row.original.id
                  )}
                  param={props.row.original.id}
                  onClick={umNextAvailabilities.setRowExpanded}
                />
              ) : null
            ),
        },
        {
          Header: "Réalisés",
          headerFilter: false,
          columnWidth: 100,
          accessor: nameOf((n: IInterventionPointNextAvailabilities) => n.nbMissions),
          center: true,
          Cell: (props: ITableProps<IInterventionPointNextAvailabilities>) =>
            <Row justifyContent={"center"}>
              <Label text={props.row.original.nbMissions.toString()} width="auto"/>
            </Row>
        }
      ]}
    />);
}

export default smartConnect([selectedCampaignModule, interventionPointsNextAvailabilitiesModule], [interventionPointsNextAvailabilitiesModule])(SelectInterventionPoint);
