import { iterateObject } from "./objectUtils";
import { deepClone } from "./cloneUtils";

export const calculatePageFilters = (baseFilters: any, cFlt: any, initialFilters: any, inserTo: any) => {
  const finalFilters = deepClone(initialFilters);
  iterateObject(cFlt, (key: any, value: any) => {
    if (value === "" || value === null) {
      delete finalFilters[key];
    } else {
      finalFilters[key] = value;
    }
  });
  inserTo(finalFilters);
  Object.assign(baseFilters, deepClone(finalFilters));
  return finalFilters;
};
