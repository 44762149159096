export interface IAllAnimationsFilters {
  interventionPointCodePOS: string;
  interventionPointName: string;
  promoterName: string;
  sectors1: string[];
  sector1: string;
  dateFrom: Date;
  dateTo: Date;
  startDate: Date;
}

export interface IAllAnimationsResult {
  id: number;
  sector1: number;
  sector2: number;
  sector3: number;
  campaignId: number;
  campaignName: string;
  interventionPointId: number;
  interventionPointCodePOS: string;
  interventionPointName: string;
  interventionPointAddress: string;
  interventionPointEmail: string;
  interventionPointTelephone: string;
  promoterId: number;
  promoterFirstname: string;
  promoterLastname: string;
  promoterName: string;
  startDate: Date;
  endDate: Date;
  isActiv: boolean;
  cancellationRequested: boolean;
  status: MissionStatusType;
}

export interface IAllAnimationsData {
  totalRows: number;
  pageCount: number;
  results: IAllAnimationsResult[];
}

export interface IAnimationData {
  id: number;
  interventionPointId: number;
  interventionPointCodePOS: string;
  interventionPointName: string;
  interventionPointPhone: any;
  interventionPointMobilePhone: any;
  interventionPointEmail: string;
  interventionPointComment: string;
  promoterId: number;
  promoterFirstname: string;
  promoterLastname: string;
  startDate: Date;
  endDate: Date;
  isActiv: boolean;
}

export interface INewAnimation {
  email: string
  telephone: string
  interventionPointId: number
  name: string
  description: string
  comment: string
  extraAttributes: { [date: string] : string } 
  missionDays: IMissionDayItem[];
}

export interface IMissionDayItem {
    date: Date;
    timeSlots: IMissionDayTimeSlot[]
}

export interface IMissionDayTimeSlot {
  promoterId: number
  interventionPointId: number
  startTime: string
  endTime: string
}

export enum MissionStatusType {
  Bad,
  Cancel,
  CancelBackOffice,
  CancelPending,
  Good,
  Neutral
}