import React from "react";
import { MissionStatusType } from "containers/animations/IAnimations";

import { StatusBad, StatusCancel, StatusCancelPending, StatusGood, StatusNeutral, StatusCancelBackOffice } from "assets/images";
import Row from "../row/Row";

interface IProps {
  status: MissionStatusType
}

const MissionStatus = (props: IProps) => {

  let icon = null;
  switch (props.status) {
    case MissionStatusType.Bad:
      icon = <StatusBad />;
      break;
    case MissionStatusType.Cancel:
      icon = <StatusCancel />;
      break;
    case MissionStatusType.CancelBackOffice:
      icon = <StatusCancelBackOffice />;
      break;
    case MissionStatusType.CancelPending:
      icon = <StatusCancelPending />;
      break;
    case MissionStatusType.Good:
      icon = <StatusGood />;
      break;
    default:
      icon = <StatusNeutral />;
      break;

  }
  return (
    <Row justifyContent={"center"}>
      {icon}
    </Row>
  );
};

export default MissionStatus;
