import React from "react";

import { IDefaultCssStyles } from "interfaces/IDefaultProps";

import { makeStyleProperties } from "utils/componentUtils";

import { FaCircleNotch } from "react-icons/fa";

import "./spinner.scss";

interface IProps extends IDefaultCssStyles {
  color?: "white" | "blue" | "black";
  size?: "small" | "normal" | "medium" | "large" | "big";
}

const Spinner = (props: IProps) => {
  return (
    <div 
      className={"spinContainer" + (props.size ? ` sizeSpin--${props.size}` : "") + (props.color ? ` colorSpin--${props.color}` : "")} 
      style={makeStyleProperties(props)}>
      <FaCircleNotch className="spinner" />
    </div>
  );
};

export default Spinner;
