import React from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./check.scss";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

interface IProps extends IDefaultProps {
  checked: boolean;
}

const Check = (props: IProps) => {
  return <div className={makeClassName("check" + (props.checked === true ? " checked" : ""), props)} >
      <span className="tooltiptext">{props.checked ? "Actif" : "Inactif"}</span>
      {props.checked ? <FaCheckCircle color='#046448' size={30}/> : <FaTimesCircle color='#bb0c0c' size={30}/>}
    </div>;
};

export default Check;
