import React from "react";
import "./linkButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";

interface IProps extends IButtonProps {}

const LinkButton = (props: IProps) => {
  return <Button middleClick={true} newTabOnMiddleClick={true} className="linkButton" {...props} />;
};

export default LinkButton;
