import React, { useEffect } from "react";
import { IRoute } from "interfaces/IRoute";
import PageContent from "pageWrapper/pageContent/PageContent";
import { smartConnect } from "app/appUtils";

import { homeModule, loginModule } from "config/modulesConf";
import { ISmartAction, ISmartState } from "interfaces/ISmart";

interface IProps {
  route: IRoute;
  home: ISmartState;
  homeActions: ISmartAction;
}

const Home = (props: IProps) => {

  useEffect(() => {
    props.homeActions.reset();
    return (()=>{
      props.homeActions.reset();
    })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.home.opCompleted) {
      //props.home
    }
    // eslint-disable-next-line
  }, [props.home.opCompleted]);

  useEffect(() => {
    if (props.home.errors) {
    }
    // eslint-disable-next-line
  }, [props.home.errors]);

  return (
    <PageContent/>
  );
};

export default smartConnect([homeModule,loginModule], [homeModule, loginModule])(Home);
