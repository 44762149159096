import React, { useEffect, useState } from "react";
import { deepClone } from "utils/cloneUtils";
import { nameOf } from "utils/nameOfUtils";
import { getArrayKeysFromValue } from "utils/objectUtils";
import { calculatePageFilters } from "utils/pageUtils";
import { useChecks } from "hooks/useChecks";
import { useRoute } from "hooks/useRoute";

import moment from 'moment';

import { smartConnect } from "app/appUtils";
import { IPopupProps } from "components/common/popup/IPopupProps";
import Popup from "components/common/popup/Popup";
import Row from "components/common/row/Row";
import Table from "components/common/table/Table";
import { TABLE_ITEMS_PER_PAGE } from "config/conf";

import { getAllCampaignsApi, getUserCampaignsApi, updateUserCampaignsApi } from "config/apiConf";
import { allCampaignsModule, userCampaignsModule, updateUserCampaignsModule } from "config/modulesConf";
import { IAllCampaignsData, IAllCampaignsResult, IUserCampaign } from "containers/campaigns/ICampaigns";
import Checkbox from "components/common/checkbox/Checkbox";
import { ITableProps } from "components/common/table/ITable";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import { IAllUsersResult } from "../IUsers";
import { ISmartState, ISmartAction } from "interfaces/ISmart";

interface IProps extends IPopupProps {
  user: IAllUsersResult;
  allCampaigns?: ISmartState;
  allCampaignsActions?: ISmartAction;
  userCampaigns?: ISmartState;
  userCampaignsActions?: ISmartAction;
  updateUserCampaigns?: ISmartState;
  updateUserCampaignsActions?: ISmartAction;
}

const UpdateUserCampaignsPopup = (props: IProps) => {
  const { addParams, getRouteFromParams } = useRoute();
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const allCampaigns: IAllCampaignsData = props.allCampaigns?.data;
  const userCampaigns: IUserCampaign[] = props.userCampaigns?.data;
  const uc = useChecks();

  useEffect(() => {
    props.allCampaignsActions?.reset();
    props.userCampaignsActions?.reset();
    
    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allCampaignsActions?.reset();
      props.userCampaignsActions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = { 
      pageNumber: cPage, 
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);
    
    props.userCampaignsActions?.httpCall(getRouteFromParams(getUserCampaignsApi, {userId: props.user?.id}));
    props.allCampaignsActions?.httpCall(addParams(getAllCampaignsApi, filter));
  };

  useEffect(() => {
    if (props.allCampaigns?.opCompleted && props.userCampaigns?.opCompleted) {
      setTblData(allCampaigns.results);

      //Checks
      let newChecksData = deepClone(uc.checksData);
      if (userCampaigns.length === allCampaigns.results.length) {
        newChecksData.all = true;
      }
      allCampaigns.results.forEach((element: IAllCampaignsResult) => {
        newChecksData[element.id] = userCampaigns.some(s => s.id === element.id)
      });

      uc.setData(newChecksData);
    }
    // eslint-disable-next-line
  }, [props.allCampaigns?.opCompleted, props.userCampaigns?.opCompleted]);

  useEffect(() => {
    if (props.allCampaigns?.errors || props.allCampaigns?.opCompleted 
      || props.updateUserCampaigns?.opCompleted || props.updateUserCampaigns?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allCampaigns?.errors, props.allCampaigns?.opCompleted, props.updateUserCampaigns?.opCompleted, props.updateUserCampaigns?.errors]);

  const save = (values: any) => {
    setTblLoader(true);
    var checkedIds = getArrayKeysFromValue(uc.checksData, true, ["all"]);
    props.updateUserCampaignsActions?.httpCall(getRouteFromParams(updateUserCampaignsApi, {userId: props.user?.id}), {userId: props.user.id, campaignIds: checkedIds });
  }

  const disabled = false;
  return (
    <Popup {...props} bigSize={true} title={"Campagnes de l'utilisateur"}
    footerContent={<Row><NeuroButton color="green" size={'normal'} onClick={save} param={uc.checksData} label={"Sauvegarder"} disabled={false} /></Row>}>
        <Table style={{marginBottom: `50px`}}
        totalRows={allCampaigns?.totalRows}
        hidePagination={true}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
        onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
        onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
        sortBy={tableSorter}
        maxPages={allCampaigns?.pageCount}
        loader={tblLoader}
        data={tblData}
        crrPage={crrPage}
        selectedRows={uc.checksData}
        columns={[
          {
            headerFilterProps: {
              type: "number",
            },
            Header: "x",
            hideHeaderLabel: true,
            headerFilter: false,
            columnWidth: 30,
            headerComponent: <Checkbox checked={uc.checksData.all} onChange={(val: boolean) => uc.calculate(val)} />,
            Cell: (props: ITableProps<IAllCampaignsResult>) => (
              <Checkbox
                disabled={disabled}
                onChange={(val: boolean) => uc.calculate(val, props.row.original.id)}
                checked={uc.checksData[props.row.original.id]}
              />
            ),
          },
          {
            Header: "Campaign",
            accessor: nameOf((n: IAllCampaignsResult) => n.name),
            Cell: (props: ITableProps<IAllCampaignsResult>) =>
              (props.row.original as IAllCampaignsResult).name
                ? (props.row.original as IAllCampaignsResult).name
                : null,
          },
          {
            Header: "Début",
            columnWidth: 1,
            headerFilter: false,
            accessor: nameOf((n: IAllCampaignsResult) => n.startDate),
            Cell: (props: ITableProps<IAllCampaignsResult>) => moment(props.value).format("DD/MM/yyyy"),
          },
          {
            Header: "Fin",
            columnWidth: 1,
            headerFilter: false,
            accessor: nameOf((n: IAllCampaignsResult) => n.endDate),
            Cell: (props: ITableProps<IAllCampaignsResult>) => moment(props.value).format("DD/MM/yyyy"),
          },
        ]} />
    </Popup>
  );
};

export default smartConnect(
  [userCampaignsModule, allCampaignsModule, updateUserCampaignsModule],
  [userCampaignsModule, allCampaignsModule, updateUserCampaignsModule]
)(UpdateUserCampaignsPopup);
