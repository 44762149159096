import React, { useState } from "react";
import moment from "moment";

import { smartConnect } from "app/appUtils";
import { interventionPointsNextAvailabilitiesModule } from "config/modulesConf";
import { IRoute } from "interfaces/IRoute";
import { nameOf } from "utils/nameOfUtils";

import DateSelector from "components/common/dateSelector/DateSelector";
import Label from "components/common/label/Label";
import Row from "components/common/row/Row";
import { IAllAnimationsFilters } from "containers/animations/IAnimations";
import PageContent from "pageWrapper/pageContent/PageContent";
import InterventionPointsNextAvailabilitiesList from "./tabs/InterventionPointsNextAvailabilitiesList";

interface IProps {
  route: IRoute;
  dateFrom: Date;
  dateTo: Date;
}

const InterventionPointsNextAvailabilities = (props: IProps) => {
  const [dateFrom, setDateFrom] = useState<Date>(moment().startOf('isoWeek').add(1, 'week').toDate());
  const [dateTo, setDateTo] = useState<Date>(moment().startOf('isoWeek').add(2, 'week').toDate());

  return (
    <PageContent>
      <Row justifyContent='center'>
        <Label text="Du" weight="bold" size="normal" />
        <DateSelector
          name={nameOf((n: IAllAnimationsFilters) => n.dateFrom)}
          onChange={(filter: Date) => setDateFrom(filter)}
          dateFormat={"dd/MM/yyyy"}
          value={dateFrom}
        />
        <Label text="Au" weight="bold" size="normal" />
        <DateSelector fullSize={true}
          name={nameOf((n: IAllAnimationsFilters) => n.dateTo)}
          onChange={(filter: Date) => setDateTo(filter)}
          dateFormat={"dd/MM/yyyy"}
          value={dateTo}
        />
      </Row>
      <InterventionPointsNextAvailabilitiesList dateFrom={dateFrom}  dateTo={dateTo}/>
    </PageContent>
  );
};

export default smartConnect(
  [interventionPointsNextAvailabilitiesModule],
  [interventionPointsNextAvailabilitiesModule]
)(InterventionPointsNextAvailabilities);
