import { ILanguage } from "interfaces/ILanguage";

export const EN: ILanguage = {
  id: 1,
  language: "en",
  default: true,
};

export const FR: ILanguage = {
  id: 2,
  language: "fr",
};
