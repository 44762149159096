import React from "react";
import { useEffect, useRef } from "react";

import { MdClose } from "react-icons/md";
import SimpleButton from "components/buttons/simpleButton/SimpleButton";
import Label from "components/common/label/Label";

import "./rightPopup.scss";

interface IProps {
  closePopup: () => void;

  children?: React.ReactNode;
  closeWhenOutsideClicked?: boolean;
  footer?: React.ReactNode;
  scrollable?: boolean;
  title?: string;
}

const RightPopup = (props: IProps) => {
  const node = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.closeWhenOutsideClicked)
      document.addEventListener("mousedown", handleClickOutside);
    else
      document.removeEventListener("mousedown", handleClickOutside);

    return () => {
      if (props.closeWhenOutsideClicked)
        document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.closeWhenOutsideClicked]);

  const handleClickOutside = (ev: MouseEvent) => {
    if (node?.current?.contains(ev.target as Node | null)) {
      // inside click
      return;
    }
    // outside click
    props.closePopup();
  };

  return (
    <div className="rightPopup">
      <div className="rightContainer" ref={node}>
        <div className="header">
          <Label text={props.title} size="title" color="black" weight="bold" />
          <SimpleButton color="transparent-black" size="normal" 
            icon={<MdClose transform="scale(2.5)"/>}
            onClick={() => props.closePopup()} />
        </div>
        <div className={"content" + ((props.scrollable) ? " scrollable" : "")}>
          {props.children}
        </div>
        {props.footer && <div className="footer">{props.footer}</div>}
      </div>
    </div>
  );
};

export default RightPopup;
