import React, { useRef, ReactElement, CSSProperties, useMemo, forwardRef } from "react";
import { useTable, useBlockLayout, HeaderGroup, Column } from 'react-table'
import { VariableSizeList } from 'react-window'
import "./simpleTable.scss";
import Spinner from "components/common/spinner/Spinner";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";

const sorTypes = {
  ASC: "asc",
  DESC: "desc"
};

export type ColumnSort = {
  column?: string;
  order?: string;
};

export type Pagination = {
  number?: number;
  size?: number;
}

export type TableParams<F = any> = {
  filters?: F;
  page?: Pagination;
  sort?: ColumnSort;
}

export type TableData<T> = {
  totalRows: number | undefined;
  results: T[] | undefined;
};

export type TableColumn<T> = {
  Cell: ((props: T) => React.ReactNode | null) | undefined
  Filter?: ((props: T) => React.ReactNode) | undefined,
  Header: ((props: T) => React.ReactNode) | string | undefined,

  accessor?: keyof T | any,
  hideFilter?: boolean,
  hideHeader?: boolean,
  sortable?: boolean,
  maxWidth?: number;
  minWidth?: number;
  width?: number | string;
}

interface ICommonTableProps<T> {
  columns: Array<TableColumn<T>>;
  data: TableData<T>;

  paramsChanged?: (params: TableParams) => void;
  params?: TableParams;
  loading?: boolean;
  hidePagination?: boolean;
  hideFilters?: boolean;
  height?: string | number;
}

interface ITableMemorizedProps<T> extends ICommonTableProps<T> {
  virtualized?: false;
}

interface ITableVirtualizedProps<T> extends ICommonTableProps<T> {
  virtualized: true;
  itemsSize: (index: number) => number;
}

export type ITableProps<T> = ITableVirtualizedProps<T> | ITableMemorizedProps<T>;

const SimpleTable = <T extends object>(props: ITableProps<T>): ReactElement => {
  const tableRef = useRef<HTMLDivElement>(null);
  const tHeadRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const bodyHeight = (tableRef.current?.offsetHeight ?? 0) - (tHeadRef.current?.clientHeight ?? 0);
  const bodyScrollBarWidth = (tableRef.current?.scrollWidth ?? 0) - (outerRef.current?.clientWidth ?? 0);
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    totalColumnsWidth,
  } = useTable(
    {
      columns: props.columns as Column<T>[],
      data: props.data?.results ?? []
    },
    useBlockLayout
  )

  const sort = (column: HeaderGroup<T> & TableColumn<T>) => {
    if (props.paramsChanged && props.params) {
      const sort = { ...props.params.sort }
      if (sort.column !== column.id) {
        sort.column = column.id;
        sort.order = sorTypes.DESC;
      }
      else if (sort.column === column.id) {
        switch (sort.order) {
          case sorTypes.DESC:
            sort.order = sorTypes.ASC;
            break;
          case sorTypes.ASC:
            sort.column = undefined;
            sort.order = undefined;
            break;
          default:
            sort.order = sorTypes.DESC;
        }
      }
      props.paramsChanged({ ...props.params, sort });
    }
  };

  const RenderHeaderCell = React.useCallback(
    (column: HeaderGroup<T> & TableColumn<T>, index: number) => {
      const style: CSSProperties = {
        display: "flex",
        position: "relative",
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        width: column.width,
      };
      return (
        <div className="th header" {...column.getHeaderProps({ style })} onClick={column.sortable ? () => sort(column) : undefined}>
          {!column.hideHeader && column.render('Header')}
          {column.sortable === true && props.params?.sort && props.params.sort.column === column.id &&
            (
              (props.params.sort.order === sorTypes.DESC && <span className="sort"><BiChevronDown className="sort" /></span>)
              ||
              (props.params.sort.order === sorTypes.ASC && <span className="sort"><BiChevronUp className="sort" /></span>)
            )
          }
        </div>
      )
    },
    // eslint-disable-next-line
    [props.params?.sort]
  );

  const RenderFilterCell = React.useCallback(
    (column: HeaderGroup<T> & TableColumn<T>, index: number) => {
      const style: CSSProperties = {
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        width: column.width,
      };
      return (
        <div className="th filters" style={style} key={`th filters${index}`}>
          {!column.hideFilter && column.Filter && column.render('Filter')}
        </div>
      )
    },
    []
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      if (!style) style = {};
      style.width = isNaN(totalColumnsWidth) ? "100%" : totalColumnsWidth + bodyScrollBarWidth;
      const row = rows[index]
      prepareRow(row)
      return (
        <div className={`tr ${index % 2 === 1 ? "odd" : "even"}`}
          {...row.getRowProps({ style })}>
          {row.cells.map((cell, index) => {
            const style: CSSProperties = {
              minWidth: cell.column.minWidth,
              maxWidth: cell.column.maxWidth,
              width: cell.column.width,
            };
            return (
              <div className="td" {...cell.getCellProps({ style })}>
                {cell.render('Cell', row.original)}
              </div>
            )
          })}
        </div>
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prepareRow, rows]
  );

  const onBodyScroll = (e: any) => {
    if (tHeadRef.current) {
      //Fix tHeadRef.current.scrollTo does work but hidden overflow on thead make buggy height.
      tHeadRef.current.style.transform = `Translate(${- e.target.scrollLeft}px, 0)`;
    }
  };

  const tbodyMemorized = useMemo(() => {
    var outerStyle: CSSProperties = {
      position: "relative",
      height: bodyHeight,
      overflow: "auto",
      width: "100%"
    }
    return (
      <div className="tbody memorized"
        {...getTableBodyProps({ style: { height: '100%' } })}>
        <div onScroll={onBodyScroll} ref={outerRef} style={outerStyle}>
          <div style={{ width: "100%" }}>
            {rows.map(RenderRow)}
          </div>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [props.data?.results]);

  const tbodyVirtualized = useMemo(() => {
    if (props.virtualized) {
      const outerElementType = forwardRef((props, ref) => (
        <div onScroll={onBodyScroll} ref={ref as React.RefObject<HTMLDivElement>} {...props}/>
      ));

      return (
        <div className="tbody virtualized"
          {...getTableBodyProps({ style: { height: '100%' } })}>
          <VariableSizeList
            height={bodyHeight}
            itemCount={rows.length}
            itemSize={props.itemsSize}
            width={"100%"}
            outerRef={outerRef}
            outerElementType={outerElementType}
          >
            {RenderRow}
          </VariableSizeList>
        </div >
      )
    }
    // eslint-disable-next-line
  }, [props.data?.results]);

  return (
    <div className="simpleTable"
      ref={tableRef}
      {...getTableProps({ style: { height: props.height ? props.height : "100%"  } })}>
      <div
        className="thead"
        ref={tHeadRef}
        style={{ marginRight: bodyScrollBarWidth }}>
        {headerGroups.length &&
          <>
            <div className="tr headers" {...headerGroups[0].getHeaderGroupProps()}>
              {headerGroups[0].headers.map((column, index) => RenderHeaderCell(column as any, index))}
            </div>
            {!props.hideFilters &&
              <div className="tr filters" {...headerGroups[0].getHeaderGroupProps({ key: "tr filters" })}>
                {headerGroups[0].headers.map((column, index) => RenderFilterCell(column as any, index))}
              </div>
            }
          </>
        }
      </div>
      {props.virtualized
        ? tbodyVirtualized
        : tbodyMemorized
      }
      {props.loading && <Spinner color="blue" />}
    </div>
  )
};

export default SimpleTable;
