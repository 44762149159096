const createBackgroundColor = (
  pos: number,
  checkedPos: number,
  uncheckedPos: number,
  offColor: string,
  onColor: string,
  midColor?: string,
) => {
    const relativePos = (pos - uncheckedPos) / (checkedPos - uncheckedPos);
    if (relativePos === 0) {
      return offColor;
    }
    if (midColor !== undefined && relativePos === 0.5)
    {
      return midColor;
    }
    if (relativePos === 1) {
      return onColor;
    }
    
    if (midColor === undefined) {
      return proportionColor(relativePos, offColor, onColor);
    }
    else {
      if (relativePos < 0.5)
      {
        return proportionColor(relativePos * 2, offColor, midColor);
      }
      else {
        return proportionColor((relativePos - 0.5) * 2, midColor, onColor);
      }
    }
  }

  const proportionColor = (relativeProportion: number, startColor: string, endColor: string) => {
    let newColor = "#";
    for (let i = 1; i < 6; i += 2) {
      const offComponent = parseInt(startColor.substr(i, 2), 16);
      const onComponent = parseInt(endColor.substr(i, 2), 16);
      const weightedValue = Math.round(
        (1 - relativeProportion) * offComponent + relativeProportion * onComponent
      );
      let newComponent = weightedValue.toString(16);
      if (newComponent.length === 1) {
        newComponent = `0${newComponent}`;
      }
      newColor += newComponent;
    }
    return newColor;
  }
  
  const convertShorthandColor = (color: string) => {
    if (color.length === 7) {
      return color;
    }
    let sixDigitColor = "#";
    for (let i = 1; i < 4; i += 1) {
      sixDigitColor += color[i] + color[i];
    }
    return sixDigitColor;
  }
  
  const getBackgroundColor = (
    pos: number,
    checkedPos: number,
    uncheckedPos: number,
    offColor: string,
    onColor: string,
    midColor?: string,
  ) => {
    const sixDigitOffColor = convertShorthandColor(offColor);
    const sixDigitOnColor = convertShorthandColor(onColor);
    const sixDigitMidColor = (midColor !== undefined) ? convertShorthandColor(midColor) : undefined;
    return createBackgroundColor(
      pos,
      checkedPos,
      uncheckedPos,
      sixDigitOffColor,
      sixDigitOnColor,
      sixDigitMidColor
    );
  }

  export default getBackgroundColor;