import React, { useEffect, useState } from "react";
import { IRoute } from "interfaces/IRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import { useRoute } from "hooks/useRoute";
import { nameOf } from "utils/nameOfUtils";

import { TABLE_ITEMS_PER_PAGE } from "config/conf";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import { IUserCampaign } from "containers/campaigns/ICampaigns";
import { ITableProps } from "components/common/table/ITable";

import { IAllAnimationsData, IAllAnimationsFilters, IAllAnimationsResult } from "containers/animations/IAnimations";
import { BusinessLeader, SuperAdministrator } from "config/permsConf";
import {
  allAnimationsModule, allAnimationsTmpModule, cancellationRequestModule,
  userCampaignsModule, userSettingsModule, exportMissionsTmpModule
} from "config/modulesConf";
import { animationRoute } from "config/routeConf";
import { calculatePageFilters } from "utils/pageUtils";
import { downloadFileHandler } from "utils/fileUtils";
import { getAllAnimationsApi, getUserCampaignsApi, exportMissionsPlanningApi } from "config/apiConf";

import moment from 'moment';

import CancellationRequestPopup from "./popups/CancellationRequestPopup"
import CircleButton from "components/buttons/circleButton/CircleButton";
import Column from "components/common/column/Column";
import DateSelector from "components/common/dateSelector/DateSelector";
import Dropdown from "components/common/dropdown/Dropdown";
import Label from "components/common/label/Label";
import MissionStatus from "components/common/missionStatus/MissionStatus";
import Row from "components/common/row/Row";
import Switch from "components/common/switch/Switch"
import Table from "components/common/table/Table";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import PageContent from "pageWrapper/pageContent/PageContent";
import BookingStatistics from "components/chart/BookingStatistics";
import SimpleButton from "components/buttons/simpleButton/SimpleButton";
import { useHistory } from "react-router-dom";

interface IProps {
  route: IRoute;
  userSettings: ISmartState<IUserSettingsData>;
  cancellationRequest?: ISmartState;
  cancellationRequestActions?: ISmartAction;
  allAnimations?: ISmartState;
  allAnimationsActions?: ISmartAction;
  allAnimationsTmp?: ISmartState;
  allAnimationsTmpActions?: ISmartAction;
  userCampaigns?: ISmartState;
  userCampaignsActions?: ISmartAction;
  exportMissionsTmp?: ISmartState;
  exportMissionsTmpActions?: ISmartAction;
}

const AllAnimations = (props: IProps) => {
  const history = useHistory();
  const { getRouteFromParams } = useRoute();
  const [ddlLoader, setddlLoader] = useState(true);
  const [campaignIds, setCampaignIds] = useState<number[]>([]);
  const [popup, setPopup] = useState<number>();
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const [dateFrom, setDateFrom] = useState(moment().startOf("month").toDate());
  const [dateTo, setDateTo] = useState(moment().endOf("month").toDate());
  const data: IAllAnimationsData = props.allAnimations?.data;
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const userCampaigns: IUserCampaign[] = props.userCampaigns?.data === undefined ? [] : props.userCampaigns?.data;
  const sector1: string = userSettingsData.role !== BusinessLeader ? "Multi" : userSettingsData.sectors1.length < 1 ? "Non Défini" : userSettingsData.sectors1[0];
  const quota: number = userSettingsData.quota;
  const totalMissions = props.userSettings?.data?.bookingStatistics.currentMonth?.count ?? 0;

  useEffect(() => {
    props.allAnimationsActions?.reset();
    props.allAnimationsTmpActions?.reset();

    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allAnimationsActions?.reset();
      props.allAnimationsTmpActions?.reset();
    };

    // eslint-disable-next-line
  }, [dateFrom, dateTo, campaignIds]);

  useEffect(() => {
    if (props.cancellationRequest?.opCompleted) {
      setPopup(undefined);
      props.cancellationRequestActions?.reset();
      getData(1, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.cancellationRequest?.opCompleted]);

  useEffect(() => {
    if (props.exportMissionsTmp?.opCompleted) {
      var data = props.exportMissionsTmp.data;
      downloadFileHandler(
        data.name,
        data.content,
        props.exportMissionsTmpActions
      );
    }
    // eslint-disable-next-line
  }, [props.exportMissionsTmp?.opCompleted]);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = {
      pageNumber: cPage,
      pageSize: pageSize,
      campaignIds: campaignIds,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    props.allAnimationsActions?.httpCall(getAllAnimationsApi, filter);
  };

  useEffect(() => {
    if (props.allAnimations?.opCompleted) {
      setTblData(data.results);
      setTblLoader(false);
      //uc.newData(data.results);
    }
    // eslint-disable-next-line
  }, [props.allAnimations?.opCompleted]);

  useEffect(() => {
    if (props.allAnimationsTmp?.opCompleted) {
      props.allAnimationsTmpActions?.reset();
      getData(1, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.allAnimationsTmp?.opCompleted]);

  useEffect(() => {
    if (props.allAnimationsTmp?.errors || props.allAnimations?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allAnimationsTmp?.errors, props.allAnimations?.errors]);

  useEffect(() => {
    props.userCampaignsActions?.reset();

    props.userCampaignsActions?.httpCall(getRouteFromParams(getUserCampaignsApi, { userId: userSettingsData?.id }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.userCampaigns?.opCompleted || props.userCampaigns?.errors) {
      setddlLoader(false);
    }
    // eslint-disable-next-line
  }, [props.userCampaigns?.opCompleted, props.userCampaigns?.errors]);

  const onDropdownChange = (filter: any) => {
    var campaignIds = filter.map((s: any) => s.id);
    setCampaignIds(campaignIds);
  };

  const handleExportBooking = () => {
    const filter: any = {
      campaignIds: campaignIds,
      dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
      dateTo: moment(dateTo).format('YYYY-MM-DD'),
    };
    calculatePageFilters(filter, {}, tableFilters, setTableFilters);
    props.exportMissionsTmpActions?.httpCall(exportMissionsPlanningApi, filter);
  };

  return (
    <PageContent
      actions={userSettingsData.role === BusinessLeader && [
        <Row justifyContent={"space-between"} alignItems="center" width={"100%"} margin="0px 20px">
          <Column alignItems='center' justifyContent={"center"}>
            <Label text={`Secteur`} weight="bold" size="big" />
            <Label text={`${sector1} `} weight="bold" size="big" color="blue" />
          </Column>
          <Column alignItems='center' justifyContent={"center"}>
            <Label text={`Animations`} weight="bold" size="big" />
            <Label text={`${totalMissions}`} weight="bold" size="big" color="green" />
          </Column>
          <Column alignItems='center' justifyContent={"center"}>
            <Label text={`Quota`} weight="bold" size="big" />
            <Label text={`${quota}`} weight="bold" size="big" color="red" />
          </Column>
          <Column alignItems='center' justifyContent={"center"}>
            <Label text={`Manquant`} weight="bold" size="big" />
            <Label text={`${Math.max(0, quota - totalMissions)}`} weight="bold" size="big" color="orange" />
          </Column>
          <BookingStatistics />
        </Row>]}
    >
      <Column spacing="10px">
        <Row justifyContent={"space-between"} alignItems='flex-end'>
          <SimpleButton color="green" label={"Animation"} icon={<FaPlusCircle size={20} />} onClick={() => history.push(animationRoute.path)} size="large" minWidth={"140px"}/>
          <Row justifyContent='center' alignItems={"center"}>
            <Label text="Du" weight="bold" size="normal" />
            <DateSelector
              name={nameOf((n: IAllAnimationsFilters) => n.dateFrom)}
              onChange={(filter: Date) => setDateFrom(filter)}
              dateFormat={"dd/MM/yyyy"}
              value={dateFrom}
            />
            <Label text="Au" weight="bold" size="normal" />
            <DateSelector fullSize={true}
              name={nameOf((n: IAllAnimationsFilters) => n.dateTo)}
              onChange={(filter: Date) => setDateTo(filter)}
              dateFormat={"dd/MM/yyyy"}
              value={dateTo}
            />
          </Row>
          {userSettingsData.role === SuperAdministrator
            ? <SimpleButton color="blue" label={"Export Planning"} onClick={handleExportBooking} loading={props.exportMissionsTmp?.loading} size="large" minWidth={"140px"}/>
            : <Row minWidth={"140px"} maxWidth={"140px"} />}
          {/* <MonthPagination currentMonth={currentMonth} onCurrentMonthChange={setCurrentMonth} /> */}
        </Row>
        <Table
          totalRows={data?.totalRows}
          onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
          onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
          onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
          sortBy={tableSorter}
          maxPages={data?.pageCount}
          loader={tblLoader}
          data={tblData}
          crrPage={crrPage}
          columns={[
            {
              Header: "Id",
              columnWidth: 1,
              accessor: nameOf((n: IAllAnimationsResult) => n.id),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).id
                  ? (props.row.original as IAllAnimationsResult).id
                  : null,
            },
            {
              Header: "Campagne",
              columnWidth: 1,
              accessor: nameOf((n: IAllAnimationsResult) => n.campaignName),
              Filter: Dropdown,
              filterProps: {
                loader: ddlLoader,
                multi: true,
                onChange: onDropdownChange,
                options: userCampaigns,
                enlarge: true,
                optionsIdent: "name",
                ident: "id",
                filterEscapeLabels: true
              },
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).campaignName
                  ? (props.row.original as IAllAnimationsResult).campaignName
                  : null,
            },
            {
              Header: "Date",
              columnWidth: 1,
              accessor: nameOf((n: IAllAnimationsResult) => n.startDate),
              Filter: DateSelector,
              filterProps: {
                dateFormat: "dd/MM/yyyy",
              },
              Cell: (props: ITableProps<IAllAnimationsResult>) => moment(props.value).format("DD/MM/yyyy"),
            },
            {
              Header: "POS",
              columnWidth: 1,
              accessor: nameOf((n: IAllAnimationsResult) => n.interventionPointCodePOS),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).interventionPointCodePOS
                  ? (props.row.original as IAllAnimationsResult).interventionPointCodePOS
                  : null,
            },
            {
              Header: "Zone",
              columnWidth: 1,
              accessor: nameOf((n: IAllAnimationsResult) => n.sector3),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).sector3
                  ? (props.row.original as IAllAnimationsResult).sector3
                  : null,
            },
            {
              Header: "Area",
              columnWidth: 1,
              accessor: nameOf((n: IAllAnimationsResult) => n.sector2),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).sector2
                  ? (props.row.original as IAllAnimationsResult).sector2
                  : null,
            },
            {
              Header: "Secteur",
              columnWidth: 1,
              accessor: nameOf((n: IAllAnimationsResult) => n.sector1),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).sector1
                  ? (props.row.original as IAllAnimationsResult).sector1
                  : null,
            },
            {
              Header: "Nom",
              accessor: nameOf((n: IAllAnimationsResult) => n.interventionPointName),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).interventionPointName
                  ? (props.row.original as IAllAnimationsResult).interventionPointName
                  : null,
            },
            {
              Header: "Adresse",
              accessor: nameOf((n: IAllAnimationsResult) => n.interventionPointAddress),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).interventionPointAddress
                  ? (props.row.original as IAllAnimationsResult).interventionPointAddress
                  : null,
            },
            {
              Header: "Animateur",
              accessor: nameOf((n: IAllAnimationsResult) => n.promoterName),
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                (props.row.original as IAllAnimationsResult).promoterName
                  ? (props.row.original as IAllAnimationsResult).promoterName
                  : null,
            },
            {
              Header: "Statut",
              accessor: nameOf((n: IAllAnimationsResult) => n.isActiv),
              columnWidth: 1,
              sortable: false,
              Filter: Switch,
              filterProps: { checked: tableFilters.isActiv, nullable: true, },
              Cell: (props: ITableProps<IAllAnimationsResult>) =>
                <MissionStatus status={props.row.original.status} />
            },
            {
              headerFilter: false,
              Header: "Actions",
              columnWidth: 30,
              center: true,
              sortable: false,
              Cell: (props: ITableProps<IAllAnimationsResult>) => (
                <Row center={true}>
                  <CircleButton color="orange" disabled={(props.row.original.isActiv === false || props.row.original.cancellationRequested
                    || moment(props.row.original.startDate).isSameOrBefore(moment(), "day"))} param={props.row.original.id} onClick={setPopup}
                    content={<FaTimes size={20} />}
                    tooltip="Demander l'annulation" />
                </Row>
              ),
            },
          ]}
        />
      </Column>
      {popup && <CancellationRequestPopup onClose={setPopup} missionId={popup} />}
    </PageContent>
  );
};
export default smartConnect(
  [userSettingsModule, allAnimationsModule, allAnimationsTmpModule, cancellationRequestModule, userCampaignsModule, exportMissionsTmpModule],
  [allAnimationsModule, allAnimationsTmpModule, cancellationRequestModule, userCampaignsModule, exportMissionsTmpModule]
)(AllAnimations);