import React from "react";
import { makeStyleProperties } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./row.scss";

interface IProps extends IDefaultProps {
  children?: React.ReactNode;
  className?: string;
  rowRef?: React.LegacyRef<HTMLDivElement>;
  spacing?: "0px" | "1px" |  "5px" | "10px" | "15px" | "20px" | "25px" | "30px";
}

const Row = (props: IProps) => {
  return (
    <div
      className={"row " + (props.className ? ` ${props.className}` : "") + (props.spacing !== undefined ? ` rwSpace--${props.spacing} ` : "")}
      ref={props.rowRef}
      style={makeStyleProperties(props)}>
      {props.children}
    </div>
  );
};

export default Row;
