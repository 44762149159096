import React, { useEffect } from "react";

import { smartConnect } from "app/appUtils";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import Popup from "components/common/popup/Popup";
import { IPopupProps } from "components/common/popup/IPopupProps";
import { useSmartForm, smartValidationSchema } from "hooks/useSmartForm";

import { cancellationRequestModule } from "config/modulesConf";
import { createCancellationRequestApi } from "config/apiConf";

import Form from "components/common/form/Form";

import NeuroButton from "components/buttons/neuroButton/NeuroButton";

interface IProps extends IPopupProps {
  missionId: number;
  cancellationRequest?: ISmartState;
  cancellationRequestActions?: ISmartAction;
}

const CancellationRequestPopup = (props: IProps) => {

  useEffect(() => {
    props.cancellationRequestActions?.reset();
    return () => {
      props.cancellationRequestActions?.reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.cancellationRequest?.errors) {
    }
    // eslint-disable-next-line
  }, [props.cancellationRequest?.errors]);

  const create = (values: any) => {
    props.cancellationRequestActions?.httpCall(createCancellationRequestApi, values);
  };

  const smartForm = useSmartForm({
    initialValues: {
      missionId: props.missionId,
    },
    validationSchema: smartValidationSchema({}),
    onSubmit: (values: any) => {
      create(values);
    },
  });

  return (
    <Popup {...props} title={"Demande d'annulation"}>
      <Form
        loader={false}
        fullHeight={true}
        actions={[<NeuroButton color={'red'} size={'normal'} onClick={smartForm.submit} label={"Confirmer l'annulation"} />]}
      >
        <h4>Votre demande d'annulation va être prise en compte par nos équipes.</h4>
        <h4>Vous serez informé de l’avancée du statut de cette demande sur votre planning.</h4>
        <h4>Pour tout complément d’informations n’hésitez pas de nous contacter</h4>
        <h4>01 53 65 25 31</h4>
        <br/>
        <br/>
      </Form>
    </Popup>
  );
};

export default smartConnect(
  [cancellationRequestModule],
  [cancellationRequestModule]
)(CancellationRequestPopup);
