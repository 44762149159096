import React, { useEffect, useState } from "react";

import * as lngConf from "config/lngConf";
import { useRoute } from "hooks/useRoute";
import { arrayFilterByArray } from "utils/arrayUtils";
import { ISmartAction, ISmartState } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import { useTranslation } from "react-i18next";

import { sectors3DropdownModule, sector3StatisticsModule, userSettingsModule, userCampaignsModule, sectors1DropdownModule } from "config/modulesConf";
import { getSectors1DropdownApi, getSectors3DropdownApi, getUserCampaignsApi } from "config/apiConf";

import Column from "components/common/column/Column";
import RightPopup from "components/popups/rightPopup/RightPopup";
import SimpleButton from "components/buttons/simpleButton/SimpleButton";
import Dropdown from "components/common/dropdown/Dropdown";

interface IProps {
    closePopup: () => void;
    registerFilter: (name: any, value: any) => void;
    filters: any;

    sectors3Dropdown?: ISmartState;
    sectors3DropdownActions?: ISmartAction;
    sectors1Dropdown?: ISmartState;
    sectors1DropdownActions?: ISmartAction;
    userCampaigns?: ISmartState;
    userCampaignsActions?: ISmartAction;
    userSettings?: ISmartState;
}

const StatisticsFilterPopup = (props: IProps) => {
    const { t } = useTranslation();
    const { getRouteFromParams } = useRoute();
    const [sectors3DdlLoader, setLoaderDdlSectors3D] = useState(true);
    const [sectors1DdlLoader, setLoaderDdlSectors1D] = useState(true);
    const [ddlLoader, setddlLoader] = useState(true);

    useEffect(() => {
        props.userCampaignsActions?.reset();
        props.userCampaignsActions?.reset();
        props.sectors3DropdownActions?.reset();

        props.sectors1DropdownActions?.httpCall(getSectors1DropdownApi);
        props.sectors3DropdownActions?.httpCall(getSectors3DropdownApi);
        props.userCampaignsActions?.httpCall(getRouteFromParams(getUserCampaignsApi, { userId: props.userSettings?.data.id }));

        return () => {
            props.sectors3DropdownActions?.reset();
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.userCampaigns?.opCompleted || props.userCampaigns?.errors) {
            setddlLoader(false);
        }
        // eslint-disable-next-line
    }, [props.userCampaigns?.opCompleted, props.userCampaigns?.errors]);

    useEffect(() => {
        if (props.sectors3Dropdown?.opCompleted || props.sectors3Dropdown?.errors) {
            setLoaderDdlSectors3D(false);
        }
        // eslint-disable-next-line
    }, [props.sectors3Dropdown?.opCompleted, props.sectors3Dropdown?.errors]);

    useEffect(() => {
        if (props.sectors1Dropdown?.opCompleted || props.sectors1Dropdown?.errors) {
            setLoaderDdlSectors1D(false);
        }
        // eslint-disable-next-line
    }, [props.sectors1Dropdown?.opCompleted, props.sectors1Dropdown?.errors]);

    const sectors3DropdownChange = (filter: any, dataIsFetching?: boolean) => {
        let sectors3;
        if (dataIsFetching) sectors3 = filter;
        else
            sectors3 = filter.map((m: any) => {
                return m.id;
            });
        props.registerFilter(
            'sectors3',
            sectors3.length > 0 ? sectors3 : ""
        );
    };

    const sectors1DropdownChange = (filter: any, dataIsFetching?: boolean) => {
        let sectors1;
        if (dataIsFetching) sectors1 = filter;
        else
            sectors1 = filter.map((m: any) => {
                return m.id;
            });
        props.registerFilter(
            'sectors1',
            sectors1.length > 0 ? sectors1 : ""
        );
    };

    const onDropdownChange = (filter: any, dataIsFetching?: boolean) => {
        let campaignIds;
        if (dataIsFetching) campaignIds = filter;
        else
            campaignIds = filter.map((m: any) => {
                return m.id;
            });
        props.registerFilter(
            'campaignIds',
            campaignIds.length > 0 ? campaignIds : ""
        );
    };

    return (
        <RightPopup
            closePopup={props.closePopup}
            closeWhenOutsideClicked
            footer={
                <>
                    <SimpleButton
                        color="gray"
                        label={"Fermer"}
                        onClick={() => props.closePopup()}
                        size="normal"
                    />
                </>}
            title={"Filtres"}
        >
            <Column marginTop="40px" spacing="20px">
                <Dropdown
                    loader={ddlLoader}
                    multi={true}
                    autoSelectIfOneItem={true}
                    placeholder={t(lngConf.PH_SELECT)}
                    value={arrayFilterByArray(props.userCampaigns?.data, "id", props.filters['campaignIds'])}
                    onChange={onDropdownChange}
                    label={"Campagnes"}
                    options={props.userCampaigns?.data}
                    optionsIdent={"name"}
                    enlarge={true}
                    ident={"id"}
                />
                <Dropdown
                    loader={sectors3DdlLoader}
                    filter={true}
                    autoSelectIfOneItem={true}
                    clearOnNullValue={true}
                    placeholder={t(lngConf.PH_SELECT)}
                    value={arrayFilterByArray(props.sectors3Dropdown?.data, "id", props.filters['sectors3'])}
                    onChange={(filter: any) => sectors3DropdownChange(filter)}
                    label={"Zones"}
                    options={props.sectors3Dropdown?.data}
                    optionsIdent={"name"}
                    ident={"id"}
                    multi={true}
                />
                {(props.sectors1Dropdown?.data?.length ?? 1) > 1 && <Dropdown
                    loader={sectors1DdlLoader}
                    filter={true}
                    autoSelectIfOneItem={true}
                    clearOnNullValue={true}
                    placeholder={t(lngConf.PH_SELECT)}
                    value={arrayFilterByArray(props.sectors1Dropdown?.data, "id", props.filters['sectors1'])}
                    onChange={(filter: any) => sectors1DropdownChange(filter)}
                    label={"Secteurs"}
                    options={props.sectors1Dropdown?.data}
                    optionsIdent={"name"}
                    ident={"id"}
                    multi={true}
                />}
            </Column>
        </RightPopup>
    );
};

export default smartConnect(
    [userSettingsModule, sector3StatisticsModule, sectors3DropdownModule, sectors1DropdownModule, userCampaignsModule],
    [sector3StatisticsModule, sectors3DropdownModule, sectors1DropdownModule, userCampaignsModule])(StatisticsFilterPopup);