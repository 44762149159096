import React, { useEffect, useState } from "react";
import { useRoute } from "hooks/useRoute";
import PageContent from "pageWrapper/pageContent/PageContent";
import { IRoute } from "interfaces/IRoute";
import { nameOf } from "utils/nameOfUtils";
import { smartConnect } from "app/appUtils";
import { ISmartState, ISmartAction } from "interfaces/ISmart";

import Table from "components/common/table/Table";
import { TABLE_ITEMS_PER_PAGE } from "config/conf";
import { ITableProps } from "components/common/table/ITable";
import { useChecks } from "hooks/useChecks";
import { calculatePageFilters } from "utils/pageUtils";
import { FaGlobe } from "react-icons/fa";

import { SuperAdministrator } from "config/permsConf";
import Row from "components/common/row/Row";
import CircleButton from "components/buttons/circleButton/CircleButton";

import { getAllInterventionPointsApi } from "config/apiConf";
import { allInterventionPointsModule, updateInterventionPointModule, userSettingsModule } from "config/modulesConf";
import { IAllInterventionPointsData, IAllInterventionPointsResult } from "./IInterventionPoint";
import UpdateInterventionPointSectorsPopup from "./popup/UpdateInterventionPointSectorsPopup";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import Label from "components/common/label/Label";

interface IProps {
  route: IRoute;
  allInterventionPoints?: ISmartState;
  allInterventionPointsActions?: ISmartAction;
  updateInterventionPoint?: ISmartState;
  userSettings?: ISmartState;
}

const AllInterventionPointsTabs = (props: IProps) => {
  const { addParams } = useRoute();
  const [sector1Popup, setSector1Popup] = useState<IAllInterventionPointsResult | null>();
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const data: IAllInterventionPointsData = props.allInterventionPoints?.data;
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const uc = useChecks();

  useEffect(() => {
    props.allInterventionPointsActions?.reset();

    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allInterventionPointsActions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = {
      pageNumber: cPage,
      pageSize: pageSize,
      withMissionNumber: true
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    props.allInterventionPointsActions?.httpCall(addParams(getAllInterventionPointsApi, filter));
  };

  useEffect(() => {
    if (props.allInterventionPoints?.opCompleted) {
      setTblData(data.results);
      setTblLoader(false);
      uc.newData(data.results);
    }
    // eslint-disable-next-line
  }, [props.allInterventionPoints?.opCompleted]);

  useEffect(() => {
    if (props.allInterventionPoints?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allInterventionPoints?.errors]);

  useEffect(() => {
    if (props.updateInterventionPoint?.opCompleted) {
      setSector1Popup(null);
      getData(crrPage, pageSize, tableFilters, tableSorter);
    }
    // eslint-disable-next-line
  }, [props.updateInterventionPoint?.opCompleted]);

  const onHeaderFilterChange = (colId: any, value: any) => {
    getData(1, pageSize, { [colId]: value }, tableSorter)
  };

  const columns : any = [
    {
      Header: "Code POS",
      columnWidth: 1,
      accessor: nameOf((n: IAllInterventionPointsResult) => n.code),
      Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
        (props.row.original as IAllInterventionPointsResult).code
          ? (props.row.original as IAllInterventionPointsResult).code
          : null,
    },
    {
      Header: "Zone",
      columnWidth: 120,
      accessor: nameOf((n: IAllInterventionPointsResult) => n.sector3),
      Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
        (props.row.original as IAllInterventionPointsResult).sector3
          ? (props.row.original as IAllInterventionPointsResult).sector3
          : null,
    },
    {
      Header: "Area",
      columnWidth: 1,
      accessor: nameOf((n: IAllInterventionPointsResult) => n.sector2),
      Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
        (props.row.original as IAllInterventionPointsResult).sector2
          ? (props.row.original as IAllInterventionPointsResult).sector2
          : null,
    },
    {
      Header: "Secteur",
      columnWidth: 1,
      accessor: nameOf((n: IAllInterventionPointsResult) => n.sector1),
      Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
        (props.row.original as IAllInterventionPointsResult).sector1
          ? (props.row.original as IAllInterventionPointsResult).sector1
          : null,
    },
    {
      Header: "Point de ventes",
      accessor: nameOf((n: IAllInterventionPointsResult) => n.name),
      Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
        (props.row.original as IAllInterventionPointsResult).name
          ? (props.row.original as IAllInterventionPointsResult).name
          : null,
    },
    {
      Header: "Adresse",
      accessor: nameOf((n: IAllInterventionPointsResult) => n.address),
      Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
        (props.row.original as IAllInterventionPointsResult).address
          ? (props.row.original as IAllInterventionPointsResult).address
          : null,
    },
    {
      Header: "Réalisés",
      headerFilter: false,
      columnWidth: 100,
      accessor: nameOf((n: IAllInterventionPointsResult) => n.nbMissions),
      center: true,
      Cell: (props: ITableProps<IAllInterventionPointsResult>) =>
        <Row justifyContent={"center"}>
          <Label text={props.row.original.nbMissions.toString()} width="auto"/>
        </Row>
    }
  ];

  if (userSettingsData.role === SuperAdministrator) {
    columns.push({
      headerFilter: false,
      Header: "Actions",
      columnWidth: 60,
      center: true,
      sortable: false,
      Cell: (props: any) => (
        <Row justifyContent={"center"}>
          <CircleButton color="blue" param={props.row.original} onClick={setSector1Popup} tooltip="Modifier secteur" content={<FaGlobe size={20}/>} />
        </Row>
      )
    });
  }

  return (
    <PageContent>
      <Row justifyContent="space-between" height="52px">
      </Row>
      <Table
        totalRows={data?.totalRows}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
        onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
        onHeaderFilterChange={onHeaderFilterChange}
        sortBy={tableSorter}
        maxPages={data?.pageCount}
        loader={tblLoader}
        data={tblData}
        crrPage={crrPage}
        selectedRows={uc.checksData}
        columns={columns}
      />
      {sector1Popup && <UpdateInterventionPointSectorsPopup onClose={setSector1Popup} interventionPoint={sector1Popup}/>}
    </PageContent>
  );
};

export default smartConnect(
  [allInterventionPointsModule, updateInterventionPointModule, userSettingsModule],
  [allInterventionPointsModule]
)(AllInterventionPointsTabs);
