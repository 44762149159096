import React, { useState, useEffect } from "react";
import { useRoute } from "hooks/useRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import { nameOf } from "utils/nameOfUtils";
import { useChecks } from "hooks/useChecks";

import { ITableProps } from "components/common/table/ITable";
import { calculatePageFilters } from "utils/pageUtils";
import Table from "components/common/table/Table";

import { userBookableCampaignsModule, userSettingsModule } from "config/modulesConf";
import { getUserBookableCampaignsApi } from "config/apiConf";

import Checkbox from "components/common/checkbox/Checkbox";
import { deepClone } from "utils/cloneUtils";
import moment from "moment";
import { IAllCampaignsData, IAllCampaignsResult } from "./ICampaigns";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";

interface IProps {
  userSettings?: ISmartState;
  onSelectBookableCampaigns: any;
  userBookableCampaigns?: ISmartState;
  userBookableCampaignsActions?: ISmartAction;
  forcedId?: number;
}

const SelectBookableCampaigns = (props: IProps) => {
  const { getRouteFromParams } = useRoute();
  const uc = useChecks({ single: true });
  const [tblLoader, setTblLoader] = useState(true);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const userBookableCampaigns: IAllCampaignsData = props.userBookableCampaigns?.data;
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const [selectedCampaign, setSelectedCampaign]: [IAllCampaignsResult, any] = useState(null) as any;

  useEffect(() => {
    props.userBookableCampaignsActions?.reset();
    getData(crrPage, pageSize, tableFilters, tableSorter);


    return () => {
      props.userBookableCampaignsActions?.reset()
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.forcedId && props.forcedId > 0) {
      onHeaderFilterChange("id", props.forcedId);
    }
    // eslint-disable-next-line
  }, [props.forcedId])

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);

    const filter: any = { pageNumber: cPage, pageSize: pageSize };
    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

    let newFilter = filter;
    if (pageSize === 0) {
      newFilter = deepClone(filter);
      newFilter.pageSize = "";
    }
    if (!tblFilters.id)
      delete newFilter.id

    props.userBookableCampaignsActions?.httpCall(getRouteFromParams(getUserBookableCampaignsApi, {userId: userSettingsData?.id}), newFilter);
    setTblLoader(true);
    //props.campaignsActions?.functionCall( () => { return { data: mockInterventionPoints } } );
  };

  useEffect(() => {
    if (props.userBookableCampaigns?.opCompleted) {
      if (props.forcedId && props.forcedId > 0)
        userBookableCampaigns.results = userBookableCampaigns.results.filter(x => x.id === props.forcedId);
      
      setTblData(userBookableCampaigns.results);
      if (userBookableCampaigns.results.length === 1)
        selectTheOnlyOne(userBookableCampaigns.results[0]);

      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.userBookableCampaigns?.opCompleted]);

  useEffect(() => {
    if (props.userBookableCampaigns?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.userBookableCampaigns?.errors]);

  const selectTheOnlyOne = (campaign: IAllCampaignsResult) => {
    uc.calculate(true, campaign.id);
    setSelectedCampaign(campaign);
    props.onSelectBookableCampaigns(campaign);
  };

  const selectRow = (row: any) => {
    if (row.original.id === selectedCampaign?.id)
    {
      uc.calculate(false, row.original.id);
      setTblData(userBookableCampaigns.results);
      setSelectedCampaign(null);
      props.onSelectBookableCampaigns(null);
      return;
    }
    uc.calculate(true, row.original.id);
    setTblData(userBookableCampaigns.results.filter(ip => ip.id === row.original.id));
    setSelectedCampaign(row.original);
    props.onSelectBookableCampaigns(row.original);
  };

  const onHeaderFilterChange = (colId: any, value: any) => {
    uc.calculate(false);
    setSelectedCampaign(null);
    props.onSelectBookableCampaigns(null);
    getData(1, pageSize, { [colId]: value }, tableSorter)
  };

  var disabled = false;
  return (
    <Table
      totalRows={userBookableCampaigns?.totalRows}
      onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
      onHeaderFilterChange={onHeaderFilterChange}
      hidePagination={true}
      minHeight={selectedCampaign ? '100px' : "250px"}
      onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
      sortBy={tableSorter}
      maxPages={userBookableCampaigns?.pageCount}
      loader={tblLoader}
      data={tblData}
      crrPage={crrPage}
      onRowSelect={selectRow}
      selectedRows={uc.checksData}
      columns={[
        {
          headerFilterProps: {
            type: "number",
          },
          Header: "x",
          hideHeaderLabel: true,
          headerFilter: false,
          columnWidth: 30,
          Cell: (props: ITableProps<IAllCampaignsResult>) => (
            <Checkbox
              disabled={disabled}
              onChange={(val: boolean) => selectRow(props.row)}
              checked={selectedCampaign !== null && selectedCampaign.id === props.row.original.id}//uc.checksData[props.row.original.id]}
            />
          ),
        },
        {
          Header: "Campaign",
          accessor: nameOf((n: IAllCampaignsResult) => n.name),
          Cell: (props: ITableProps<IAllCampaignsResult>) =>
            (props.row.original as IAllCampaignsResult).name
              ? (props.row.original as IAllCampaignsResult).name
              : null,
        },
        {
          Header: "Début",
          columnWidth: 1,
          headerFilter: false,
          accessor: nameOf((n: IAllCampaignsResult) => n.startDate),
          Cell: (props: ITableProps<IAllCampaignsResult>) => moment(props.value).format("DD/MM/yyyy"),
        },
        {
          Header: "Fin",
          columnWidth: 1,
          headerFilter: false,
          accessor: nameOf((n: IAllCampaignsResult) => n.endDate),
          Cell: (props: ITableProps<IAllCampaignsResult>) => moment(props.value).format("DD/MM/yyyy"),
        },
      ]}
    />);
}

export default smartConnect([userSettingsModule, userBookableCampaignsModule], [userBookableCampaignsModule])(SelectBookableCampaigns);

