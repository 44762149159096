export const NV_PAGE_1 = "NV_PAGE_1";
export const NV_PAGE_3 = "NV_PAGE_3";
export const NV_ANIMATIONSPAGE = "NV_ANIMATIONSPAGE";
export const NV_ADMINISTRATIONPAGE = "NV_ADMINISTRATIONPAGE";

export const COL_ROLES = "COL_ROLES";

export const TOOLTIP_SAVE = "TOOLTIP_SAVE";
export const TOOLTIP_SEND = "TOOLTIP_SEND";
export const NV_USERSPAGE = "NV_USERSPAGE";
export const NV_INTERVENTIONPOINTSPAGE = "NV_INTERVENTIONPOINTSPAGE";
export const NV_SECTORSPAGE = "NV_SECTORSPAGE";
export const NV_IMPORTSPAGE = "NV_IMPORTSPAGE";
export const NV_STATISTICSPAGE = "NV_STATISTICSPAGE";
export const NV_AVAILABILITY = "NV_AVAILABILITY";

export const DP_ALL = "DP_ALL";

export const PAG_PAGE = "PAG_PAGE";
export const PAG_OF =  "PAG_OF";
export const PH_SELECT =  "PH_SELECT";
export const PH_CHOOSE_PROMOTERS_POS_FILE = "PH_CHOOSE_PROMOTERS_POS_FILE";

export const BTN_ACTIVATE= "BTN_ACTIVATE";
export const BTN_PREVIOUS= "BTN_PREVIOUS";
export const BTN_NEXT= "BTN_NEXT";
export const BTN_LOGIN= "BTN_LOGIN";
export const BTN_SEND = "BTN_SEND";
export const BTN_SAVE = "BTN_SAVE";

export const WELCOME = "WELCOME";
export const HELLO = "HELLO";

export const PAGE_1_CONTENT = "PAGE_1_CONTENT";
export const ADMINISTRATION_CONTENT = "ADMINISTRATION_CONTENT";
export const PAGE_3_CONTENT = "PAGE_3_CONTENT";

export const ERR_NAME = "ERR_NAME";
export const ERR_PASSWORD = "ERR_PASSWORD";
export const ERR_INVALID_EMAIL = "ERR_INVALID_EMAIL";
export const ERR_REQ_FIELD = "ERR_REQ_FIELD";
export const ERR_INCORRECT_FILE_FORMAT = "ERR_INCORRECT_FILE_FORMAT";
export const ERR_REQ_FILE = "ERR_REQ_FILE";

export const LABEL_SEND_INVITATION = "LABEL_SEND_INVITATION";
export const LABEL_QUOTA = "LABEL_QUOTA";


