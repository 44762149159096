import { deepClone } from "utils/cloneUtils";
import { iterateObject } from "utils/objectUtils";
import { useState } from "react";

interface IProps{
  single?:boolean,
  autoSelect?:boolean;
};

export const useChecks = (props:IProps = {}) => {
  const [checksData, setChecksData] = useState({ all: false,...props as IProps }) as any;

  const newData = (data: any) => {
    const newChecksData: any = { all: false };
    data.forEach((element: any) => (newChecksData[element.id] = false));
    setChecksData(newChecksData);
  };

  const setData = (data: any) => {
    setChecksData(deepClone(data));
  };

  const calculate = (_value: boolean, id: number = -1) => {
    const newChecksData = deepClone(checksData);
    if (id > -1) {
      newChecksData[id] = _value;
      let allSelected = newChecksData.all;
      if (newChecksData.all === false) allSelected = true;
      iterateObject(newChecksData, (key: string, value: any) => {
        if (key !== "all" && parseInt(key) !== id && newChecksData[key] === false) allSelected = false;
        if (props.single === true){
          newChecksData[key] = false;
        }
      });
      if (_value === false) allSelected = false;
      newChecksData.all = allSelected;
    } else {
      newChecksData.all = _value;
      iterateObject(newChecksData, (key: string, value: any) => (newChecksData[key] = _value));
    }
    if (props.single) newChecksData[id] = !newChecksData[id];
    setChecksData(newChecksData);
  };

  return {
    newData,
    calculate,
    checksData,
    setData,
  };
};
