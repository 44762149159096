import React, { useState } from "react";
import "./navbar.scss";
import { smartConnect } from "app/appUtils";
import * as navbarConf from "config/navbarConf";
import NavbarContentItem from "./navbarContentItem/NavbarContentItem";
import { INavbarContentItem } from "./navbarContentItem/INavbarContentItem";
import { useHistory } from "react-router-dom";
import { homeRoute } from "config/routeConf";
import HamburgerButton from "components/buttons/hamburgerButton/HamburgerButton";
import OverlayMenu from "./overlayMenu/OverlayMenu";
import { loginModule, userSettingsModule } from "config/modulesConf";
import { ISmartAction, ISmartState } from "interfaces/ISmart";
import LinkButton from "components/buttons/linkButton/LinkButton";
import { IAzureADFunctionProps } from "react-aad-msal";
import Row from "components/common/row/Row";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";

interface IProps {
  login?: ISmartState;
  loginActions?: ISmartAction;
  azureADFunction?: IAzureADFunctionProps;
  userSettings?: ISmartState;
}

const Navbar = (props: IProps) => {
  const history = useHistory();
  const [showOverlay, setShowOverlay] = useState(false);
  const userSettingsData: IUserSettingsData = props.userSettings?.data;

  const logoNavigate = () => {
    history.push(homeRoute.path);
  };

  const buildNavbarItems = (data: INavbarContentItem, index: number) => {
    if (data.roleRequired !== undefined) {
      const hasPermission = data.roleRequired.filter((element: any) => element === userSettingsData.role)[0]
      if (!hasPermission) return null;
      }
    return <NavbarContentItem key={index} {...data} />;
  };

  const navbarItems = Object.values(navbarConf);

  return (
    <>
    <div className="navbar">
      <img
        onClick={logoNavigate}
        alt=""
        className="logo"
        src={require("assets/images/logo.png")}
      />
      <div className="actions">
        {navbarItems.map(buildNavbarItems)}
      </div>
      <div className="info">
      <Row>Bonjour {props.azureADFunction?.accountInfo?.account.name} <span> </span> | <span> </span> 
      {<LinkButton onClick={props.azureADFunction?.logout}>Se déconnecter</LinkButton>}</Row>
      </div>
      <div className="actions2">
        <HamburgerButton param={true} onClick={setShowOverlay} />
      </div>
    </div>
    {showOverlay && <OverlayMenu items={navbarItems} closeFromBackground={true} onClose={setShowOverlay} />}
    </>
  );
};

export default smartConnect([loginModule,userSettingsModule], [loginModule,userSettingsModule])(Navbar);