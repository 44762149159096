import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter as Router } from "react-router-dom";
import { setupTranslation } from "services/translationService";
import PageWrapper from "pageWrapper/PageWrapper";
import { getSmartReducers, getFinalRoutes } from "./appUtils";
import * as langsConf from "config/langsConf";
import Spinner from "components/common/spinner/Spinner";
import { setupHttpService } from "services/http/httpService";
import { getValueByKey } from "utils/objectUtils";
import { APi_VERSION, IS_DEVELOPMENT } from "config/conf";
import { ISmartState } from "interfaces/ISmart";
import { ILoginData } from "interfaces/modules/ILoginData";
import * as conf from "config/conf";
import moment from 'moment'
import 'moment/locale/fr';
import AzureAD, { AuthenticationState, IAzureADFunctionProps } from "react-aad-msal";
import { authProvider } from "services/authProvider";
import LinkButton from "components/buttons/linkButton/LinkButton";

setupHttpService({
  devMode: IS_DEVELOPMENT,
  version: APi_VERSION,
  devUrl: process.env.REACT_APP_HTTP_SERVICE_API_URL,
});

const finalRoutes = getFinalRoutes();
const store: any = createStore(combineReducers(getSmartReducers()), applyMiddleware(thunk));

const translation = setupTranslation(langsConf, getValueByKey(langsConf, "default", true).language);

const App = () => {
  const [loader, setLoader] = useState(false);
  const [canRender, setCanRender] = useState(false);

  moment.locale('fr');

  useEffect(() => {
    if (translation.instance.isInitialized) {
      const login: ISmartState = store.getState().login;
      const loginData: ILoginData = login.data;
      if (conf.USE_AZURE){
        loginData.access_token = "fake";
      }
      setCanRender(true);
    }
    // eslint-disable-next-line
  }, [translation.instance.isInitialized]);

  return (
    <Provider store={store}>
          {/* AZURE AUTHENTICATION */}
    {conf.USE_AZURE ? (
      <AzureAD provider={authProvider} forceLogin={true}>
        {
          (azureADFunction :  IAzureADFunctionProps) => {
            switch (azureADFunction.authenticationState) {
              case AuthenticationState.Authenticated:
                return (
                  <Router>{canRender && <PageWrapper azureADFunction={azureADFunction} setLoader={setLoader} finalRoutes={finalRoutes} />}</Router>
                );
              case AuthenticationState.Unauthenticated:
                return (
                  <div>
                  {azureADFunction.error && <p><span>Une erreur s'est produite lors de l'authentification, veuillez réessayer!</span></p>}
                  <p>
                    <span>Veuillez vous authentifier :</span>
                    <LinkButton onClick={azureADFunction.login}>Se connecter</LinkButton>
                  </p>
                </div>
                );
              case AuthenticationState.InProgress:
                return (<p>Authentification...</p>);
            }
          }
        }
      </AzureAD>
      ) : (
        <Router>{canRender && <PageWrapper setLoader={setLoader} finalRoutes={finalRoutes} />}</Router>
      )}
      {loader && <Spinner />}
    </Provider>
  );
};

export default translation.connectTo()(App);
