import { numberValidator, smartEmailValidator, smartStringValidator, smartValidationSchema, useSmartForm } from "hooks/useSmartForm";
import { IRoute } from "interfaces/IRoute";
import { ISmartAction, ISmartState } from "interfaces/ISmart";
import React, { useEffect, useState } from "react";
import { useTranslate } from "services/translationService";
import { arrayFilter } from "utils/arrayUtils";
import { deepClone } from "utils/cloneUtils";
import { nameOf } from "utils/nameOfUtils";
import { smartConnect } from "app/appUtils";
import * as lngConf from "config/lngConf";

import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import Dropdown from "components/common/dropdown/Dropdown";
import Form from "components/common/form/Form";
import Input from "components/common/input/Input";
import Popup from "components/common/popup/Popup";

import { createInvitationApi, getRolesDropdownApi, getCampaignDropdownApi } from "config/apiConf";
import { createInvitationModule, rolesDropdownModule, campaignsDropdownModule } from "config/modulesConf";
import { IRolesDropdown } from "containers/roles/IRoles";
import { ICreateInvitationData } from "../IUsers";
import { IPopupProps } from "components/common/popup/IPopupProps";

interface IProps extends IPopupProps {
  route?: IRoute;
  onSubmit? : any;
  onChange? : any;
  inputDefaultValue? : string;
  createInvitation?: ISmartState;
  createInvitationActions?: ISmartAction;
  rolesDropdown?: ISmartState;
  rolesDropdownActions?: ISmartAction;
  campaignsDropdown?: ISmartState;
  campaignsDropdownActions?: ISmartAction;
}

const CreateInvitationPopup = (props: IProps) => {
    const { t } = useTranslate();
    const [disabledBtns, setDisabledBtns] = useState(true);
    const [loaderRoles, setLoaderRoles] = useState(true);
    const [loaderCampaigns, setLoaderCampaigns] = useState(true);
    const rolesDropdownData: IRolesDropdown = props.rolesDropdown?.data;
    const campaignsDropdownData: IRolesDropdown = props.campaignsDropdown?.data;

    useEffect(() => {
        props.rolesDropdownActions?.reset();
        props.createInvitationActions?.reset();
        props.campaignsDropdownActions?.reset();

        props.rolesDropdownActions?.httpCall(getRolesDropdownApi);
        props.campaignsDropdownActions?.httpCall(getCampaignDropdownApi);

        return () => {
          props.rolesDropdownActions?.reset();
          props.createInvitationActions?.reset();
          props.campaignsDropdownActions?.reset();
        };
        // eslint-disable-next-line
      }, []);

      useEffect(() => {
        if (props.rolesDropdown?.opCompleted) {
          setLoaderRoles(false);
        }
        // eslint-disable-next-line
      }, [props.rolesDropdown?.opCompleted]);
    
      useEffect(() => {
        if (props.rolesDropdown?.errors) {
          setLoaderRoles(false);
        }
        // eslint-disable-next-line
      }, [props.rolesDropdown?.errors]);

      useEffect(() => {
        if (props.campaignsDropdown?.opCompleted) {
          setLoaderCampaigns(false);
        }
        // eslint-disable-next-line
      }, [props.campaignsDropdown?.opCompleted]);
    
      useEffect(() => {
        if (props.campaignsDropdown?.errors) {
          setLoaderCampaigns(false);
        }
        // eslint-disable-next-line
      }, [props.campaignsDropdown?.errors]);

    const create = (values: any) => {
        const newValues = deepClone(values);
        props.createInvitationActions?.httpCall(createInvitationApi, newValues);
      };

    const smartForm = useSmartForm({
        initialValues: {
           email: ""
        },
        validationSchema: smartValidationSchema({
          roleId: numberValidator().required("Un role doit être séléctionné"),
          campaignId: numberValidator().required("Une campagne doit être séléctionné"),
           email: smartStringValidator()
           .required(t(lngConf.ERR_INVALID_EMAIL))
           .matches(smartEmailValidator(), t(lngConf.ERR_INVALID_EMAIL)),
        }),
        onSubmit: (values: any) => {
          create(values);
          setDisabledBtns(true);
        },
        onChanged: () => {
          setDisabledBtns(false);
        },
      });

    const email = nameOf((n: ICreateInvitationData) => n.email);
    const roleId = nameOf((n: ICreateInvitationData) => n.roleId);
    const campaignId = nameOf((n: ICreateInvitationData) => n.campaignId);
    return (
      <Popup {...props} bigSize={true} title={"Ajouter un utilisateur"}>
        <Form loader={false}
        actions={[<NeuroButton color="green" size={'normal'} onClick={smartForm.submit} label={t(lngConf.BTN_SEND)} disabled={disabledBtns} />]}>
        {/* <form onSubmit={props.onSubmit}> */}
          <Dropdown
            required={true}
            filter={true}
            loader={loaderRoles}
            placeholder={t(lngConf.PH_SELECT)}
            value={arrayFilter(rolesDropdownData, "id", smartForm.getValue(roleId))}
            name={roleId}
            onNativeChange={smartForm.change}
            error={smartForm.getError(roleId)}
            label={t(lngConf.COL_ROLES)}
            options={rolesDropdownData}
            optionsIdent={"name"}
            ident="id"
          />
          <Dropdown
            fullWidth={true}
            required={true}
            filter={true}
            loader={loaderCampaigns}
            placeholder={t(lngConf.PH_SELECT)}
            value={arrayFilter(campaignsDropdownData, "id", smartForm.getValue(campaignId))}
            name={campaignId}
            onNativeChange={smartForm.change}
            error={smartForm.getError(campaignId)}
            label={"Campagne Globe"}
            options={campaignsDropdownData}
            optionsIdent={"name"}
            ident="id"
          />
        
          <Input
          value={smartForm.getValue(email)}
          required={true}
          placeholder='Email'
          onNativeChange={smartForm.change}
          name={email}
          error={smartForm.getError(email)}
          label={t(lngConf.LABEL_SEND_INVITATION)}
        />
        </Form>
    </Popup>
    )
}

export default smartConnect([createInvitationModule, rolesDropdownModule, campaignsDropdownModule],[createInvitationModule, rolesDropdownModule, campaignsDropdownModule])(CreateInvitationPopup);