import React, { useEffect, useState } from "react";
import { IRoute } from "interfaces/IRoute";
import { smartConnect } from "app/appUtils";
import { useHistory } from "react-router-dom";
import { loginModule } from "config/modulesConf";
import { ISmartAction, ISmartState } from "interfaces/ISmart";
import Form from "components/common/form/Form";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import Input from "components/common/input/Input";
import { smartStringValidator, smartValidationSchema, useSmartForm } from "hooks/useSmartForm";
import { allAnimationsRoute } from "config/routeConf";

import Popup from "components/common/popup/Popup";
import { IPopupProps } from "components/common/popup/IPopupProps";

import "./signin.scss"

interface IProps extends IPopupProps{
    route?: IRoute;
    login?: ISmartState;
    loginActions?: ISmartAction;
  }

const NewAnimationPopup = (props: IProps) => {
    const [error, setError] = useState("");
    const history = useHistory();

    useEffect(() => {
      props.loginActions?.reset();
      return (()=>{
        props.loginActions?.reset();
      })
      // eslint-disable-next-line
    }, []);
  
    useEffect(() => {
      if (props.login?.opCompleted) {
        history.push(allAnimationsRoute.path);
      }
      // eslint-disable-next-line
    }, [props.login?.opCompleted]);
  
    useEffect(() => {
      if (props.login?.errors) {
      }
      // eslint-disable-next-line
    }, [props.login?.errors]);

  
    const smartForm = useSmartForm({
      initialValues: {
        username: "",
        password:"",
      },
      validationSchema: smartValidationSchema({
        username: smartStringValidator()
            .required("Email obligatoire"),
        password: smartStringValidator()
            .required("Mot de passe obligatoire"),
      }),
      onSubmit: (values: any) => {
        if ((values.username === "Sebastien_Abalam@bat.com" && values.password === "password") 
        || (values.username === "admin" && values.password === "admin" ))
        {
          props.loginActions?.functionCall( () => { return { data: {username: values.username, access_token: "test"}} } );
        }
        else
          setError("Email ou mot de passe invalide!")
      },
    });
  
    const userNameField = "username";
    const passwordField = "password";
  return (
    <Popup {...props} bigSize={true} title={`Identification`}>
      <Form>
          <div className="verticalIn">

          <img
                alt=""
                className="logo"
                src={require("assets/images/logo.png")}
            />

          <Input
            required={true}
            value={smartForm.getValue(userNameField)}
            name={userNameField}
            error={smartForm.getError(userNameField)}
            onNativeChange={smartForm.change}
            placeholder="Email"
          />
          <Input
            type = "password"
            required={true}
            value={smartForm.getValue(passwordField)}
            name={passwordField}
            error={smartForm.getError(passwordField)}
            onNativeChange={smartForm.change}
            placeholder="Mot de passe"
          />
          <NeuroButton onClick={smartForm.submit} label="S'identifier" color="blue"/>
          {error !== undefined && error !== "" ? <div className={"error"}>{error}</div> : null}
          </div>
      </Form>
    </Popup>
  );
};

export default smartConnect([loginModule], [loginModule])(NewAnimationPopup);
