import React from "react";
import moment from "moment";
import "./dayAgenda.scss"

interface IProps {
    date: Date;
  }

const DayAgenda = (props: IProps) => {

    return (
        <div className="dayagenda">
            <div className="headerday">
                <label className={"month"}>
                    {moment(props.date).format("MMMM")}
                </label>
            </div>
            
            <label className={"weekday"}>
                {moment(props.date).format('dddd')}
            </label>
            <label className={"monthday"}>
                {moment(props.date).format('DD')}
            </label>
        </div>
    );
};

export default DayAgenda;
