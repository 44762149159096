import moment from "moment";

export const substractYearsFromToday = (years: number) => {
  return moment(new Date()).subtract(years, "year").toDate();
};

export const addYearFromToday = (years: number) => {
  return moment(new Date()).add(years, "year").toDate();
};

export const formatDate = (date: any) => {
  if (!date) return null;
  return moment(date).toDate();
};

export const getMonthsOfYearByLanguage = (language: any) => {
  const months = Array.apply(0, Array(12)).map(function (_, i) {
    return {
      value: i + 1,
      label: moment().locale(language).month(i).format("MMMM"),
    };
  });
  return months;
};

export const formatTime = (time: any) => {
  return time.substr(0, time.lastIndexOf(":"));
};

export const getNumberOfDaysInMonth = (month: number, year: number) => {
  return new Date(year, month, 0).getDate();
}
