import axios from "axios";

//AZURE AUTHENTICATION
import { authProvider, authenticationParameters } from "services/authProvider";
import * as conf from "config/conf";

let BASE_URL = "";

interface IProps {
  devMode: boolean;
  version: string;
  devUrl?: string;
}

export const setupHttpService = (props: IProps) => {
  BASE_URL = props.devUrl + (props.version !== "" ? "/" + props.version : "");

  if (!props.devUrl) console.log("http missing dev url");

  //AZURE AUTHENTICATION
  if (conf.USE_AZURE) {
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(function (config): any {
      return new Promise(async (resolve: any, reject: any) => {
        await authProvider.getAccessToken(authenticationParameters).then((response: any) => {
          config.headers["Authorization"] = "Bearer " + response.accessToken;
          config.headers["Content-Type"] = "application/json";
          config.headers.Accept = "application/json";
          resolve(config);
        })
          .catch((error: any) => {
            console.log(error.message);
          });
      });
    });
  }
};

export const get = async (endPoint: string, config: any = null) => {
  try {
    return await axios.get(`${BASE_URL}${endPoint}`, { ...config });
  } catch (error) {
    throw error.response;
  }
};

export const post = async (endPoint: string, data: any = null, config: any = null) => {
  try {
    return await axios.post(`${BASE_URL}${endPoint}`, { ...data }, { ...config });
  } catch (error) {
    throw error.response;
  }
};

export const put = async (endPoint: string, data: any = null, config: any = null) => {
  try {
    return await axios.put(`${BASE_URL}${endPoint}`, { ...data }, { ...config });
  } catch (error) {
    throw error.response;
  }
};

export const deleteData = async (endPoint: string, config: any = null) => {
  try {
    return await axios.delete(`${BASE_URL}${endPoint}`, { ...config });
  } catch (error) {
    throw error.response;
  }
};

export const patch = async (endPoint: string, data: any = null, config: any = null) => {
  try {
    return await axios.patch(`${BASE_URL}${endPoint}`, { ...data, ...config });
  } catch (error) {
    throw error.response;
  }
};
