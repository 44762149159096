import { useFormik } from "formik";
import * as Yup from "yup";
import { IErrors, IErrorsData } from "interfaces/IErrors";
import { useState } from "react";
import { deepClone } from "utils/cloneUtils";
import { iterateObject } from "utils/objectUtils";
import moment from "moment";

interface IProps {
  initialValues: any;
  validationSchema: any;
  onSubmit: any;
  onChanged?: any;
}

export const smartValidationSchema = (values: any) => {
  return Yup.object(values);
};

export const smartStringValidator = () => {
  return Yup.string();
};

export const numberValidator = () => {
  return Yup.number();
};

export const smartNumberValidator = () => {
  return Yup.number();
};

export const smartObjectArrayValidator = (values: never) => {
  return Yup.array().of(Yup.object().shape(values));
};

export const smartObjectValidator = (values: any) => {
  return Yup.object().shape(values);
};

export const smartRef = (value: string) => {
  return Yup.ref(value);
};

export const smartPhoneNumberValidator = () => {
  return /^[+]*([0-9\s][0-9\s]{5,20})$/;
};

export const smartEmailValidator = () => {
  // eslint-disable-next-line
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
}

export const smartTimeValidator = (lang: string) => (value: string) => {
  if (value != null && !/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(value)) {
    let message = "";
    switch (lang) {
      case "fr":
        message = "Format d'heure non valide";
        break;
      default:
        message = "Invalid time format";
    }
    return message;
  }
  return undefined;
};

export const useSmartForm = (props: IProps) => {
  const [serverErrors, setServerErrors] = useState(null) as any;
  const formik = useFormik({
    initialValues: { ...props.initialValues },
    validationSchema: props.validationSchema,
    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  const setErrors = (errors: IErrors) => {
    if (errors) {
      const errorsData: IErrorsData = errors.data;
      const _errors = deepClone(errorsData.errors);
      const newErrors: any = {};
      iterateObject(_errors, (key: string, value: any) => (newErrors[key] = value[0]));
      setServerErrors(newErrors);
    }
  };

  const onChange = (e: any) => {
    formik.handleChange(e);
    setServerErrors(null);
    if (props.onChanged) props.onChanged();
  };

  const setValues = (_values: any) => {
    formik.setValues(deepClone(_values));
  };

  const setDateValuesAsCurrentDay = (_values: any, identifierKey: any, keysToSetDatesAsToday: any) => {
    let newValues = deepClone(_values);
    if (!_values[identifierKey] && keysToSetDatesAsToday.length > 0) {
      const date = new Date(moment(moment().toDate()).format("YYYY-MM-DD")).toISOString();
      if (typeof newValues === "boolean") newValues = {};
      keysToSetDatesAsToday.forEach((key: any) => {
        newValues[key] = date;
      });
    }
    return newValues;
  };

  const addValues = (_values: any) => {
    const nv = deepClone(formik.values);
    iterateObject(_values, (key: string, value: any) => {
      nv[key] = _values[key];
    });
    formik.setValues(nv);
  };

  const trySubmit = (e: any) => {
    formik.handleSubmit(e);
  };

  const getError = (name: any, key: string = "", index: number = -1) => {
    if (formik.submitCount > 0) {
      const error: any = formik.errors;
      if (Object.keys(error).length === 0) return "";
      const sp: any = name.split(".");
      if (sp.length > 1) {
        if (key === "") return error[sp[0]][sp[1]];
        if (index < 0) return error[key][sp[0][sp[1]]];
        return error[key][index][sp[0]][sp[1]];
      }
      if (key === "") return error[name];
      if (index < 0) return error[key][name];
      return error[key][index][name];
    }

    return "";
  };

  const getValue = (name: any) => {
    const sp: any = name.split(".");
    if (sp.length > 1) return formik.values[sp[0]][sp[1]];
    return formik.values[name];
  };

  const deleteValues = (names: any) => {
    const nv = deepClone(formik.values);
    names.forEach((element: any) => {
      if (nv.hasOwnProperty(element)) delete nv[element];
    });
    formik.setValues(nv);
  };

  const getObjectForDropdown = (objectIdKey: any, objectNameKey: any, objectIsActivKey: any) => {
    if (objectIdKey === undefined) return null;
    const values = deepClone(formik.values);
    const object = { id: values[objectIdKey], name: values[objectNameKey], isActiv: values[objectIsActivKey] };
    if (object.id === undefined) return null;
    return object;
  };

  const reset = () => {
    formik.setValues({});
    formik.setErrors({});
  };

  return {
    change: onChange,
    submit: trySubmit,
    values: formik.values,
    errors: serverErrors ? serverErrors : formik.errors,
    setErrors,
    setValues,
    setDateValuesAsCurrentDay,
    getError,
    getValue,
    getObjectForDropdown,
    addValues,
    deleteValues,
    reset,
  };
};
