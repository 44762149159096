//AZURE AUTHENTICATION
// authProvider
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { appsettings } from "./appSettings";

const config: any = {
  auth: {
    authority: appsettings.azureAD.authority,
    clientId: appsettings.azureAD.clientId,
    redirectUri: window.location.origin,
    validateAuthority: false,
    persistLoginPastSession: true,

    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

export const authenticationParameters = {
  scopes: appsettings.azureAD.scopes as string[],
};

const options = {
  loginType: LoginType.Redirect,
};

// Error Handler
const handleError = (error: any) => {
  if (error && error.errorMessage?.indexOf('AADB2C90118') > -1) {
    authProvider.setAuthenticationParameters ({authority: appsettings.azureAD.resetPasswordAuthority})
    authProvider.login()
  }
  if (error && error.errorMessage?.indexOf('AADB2C90091') > -1) {
    authProvider.setAuthenticationParameters ({authority: appsettings.azureAD.authority})
    authProvider.login()
  }
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
authProvider.registerErrorHandler(handleError)
