import React, { useMemo, useState, useEffect, useRef } from "react";
import { useTable } from "react-table";
import * as plugins from "react-table";
import { IDefaultProps } from "interfaces/IDefaultProps";
import { makeClassName, makeStyleProperties } from "utils/componentUtils";
import "./table.scss";
import Pagination from "./pagination/Pagination";
import { arrayCut } from "utils/arrayUtils";
import { TABLE_ITEMS_PER_PAGE, TIMEOUT_400, tablePagesDropDown, TABLE_EXPAND_OFFSET } from "config/conf";
import { deepClone } from "utils/cloneUtils";
import Input from "../input/Input";
import { useTranslate } from "services/translationService";
import { FaCircleNotch } from "react-icons/fa";

interface IProps extends IDefaultProps {
  columns: any;
  data: any;
  sortable?: boolean;
  maxPages?: number;
  crrPage?: number;
  onChange?: any;
  loader?: boolean;
  onHeaderFilterChange?: any;
  hideHeaderFilters?: boolean;
  hidePagination?: boolean;
  onSort?: any;
  sortBy?: any;
  expanded?: boolean;
  totalRows?: number;
  onRowSelect?: any;
  selectedRows?: any;
  firstRowsFontWeight?: any;
}

const sorTypes = {
  ASC: "asc",
  DESC: "desc",
};

const Table = (props: IProps) => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const ref = useRef<HTMLTableSectionElement>(null);
  const { t } = useTranslate();
  const selectedPlugins = useMemo(() => {
    const ret: any = [];
    //if (props.filter === true) ret.push(plugins.useFilters);
    //if (props.sortable !== false) ret.push(plugins.useSortBy);
    if (props.expanded) ret.push(plugins.useExpanded);
    return ret;

    // eslint-disable-next-line
  }, []);
  const [data, setData] = useState([]);
  const { headerGroups, rows, prepareRow } = useTable({ columns: props.columns, data: data }, ...selectedPlugins);
  const [crrPage, setCrrPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const pages = tablePagesDropDown(t);
  const [itemsPerPage, setItemsPerPage] = useState(TABLE_ITEMS_PER_PAGE);

  const sort = (column: any) => {
    if (props.sortable !== false && column.sortable !== false && props.onSort) {
      let st: any = sorTypes.DESC;
      let colId = column.id;
      if (props.sortBy.sortColumn === column.id) {
        st = props.sortBy.sortOrder === sorTypes.DESC ? sorTypes.ASC : props.sortBy.sortOrder === sorTypes.ASC ? null : sorTypes.DESC;
      }
      if (st === null) colId = null;
      props.onSort({ sortColumn: colId, sortOrder: st });
    }
  };

  const buildColumns = (column: any, index: number) => {
    const thProps: any = {};
    if (!thProps.style) thProps.style = {};
    if (column.columnWidth !== undefined) {
      thProps.style.width = column.columnWidth;
    }
    if (column.columnMinWidth !== undefined) {
      thProps.style.minWidth = column.columnMinWidth;
    }
    if (column.columnMaxWidth !== undefined) {
      thProps.style.maxWidth = column.columnMaxWidth;
    }

    if (column.center === true) {
      thProps.style.textAlign = "center";
    }
    let filterCnt = null;
    const ColumnFilter = column.Filter;
    if (props.hideHeaderFilters !== true && column.headerFilter !== false)
      filterCnt = ColumnFilter ? (
        <ColumnFilter className="filter" onChange={(value: any) => props.onHeaderFilterChange(column.id, value)} {...column.filterProps} />
      ) : (
        <Input
          {...column.headerFilterProps}
          onChange={(value: any) => props.onHeaderFilterChange(column.id, value)}
          delayOnChange={TIMEOUT_400}
          className="filter"
        />
      );
    return (
      <th key={index} {...thProps}>
        <div
          onClick={() => sort(column)}
          style={column.center === true ? { justifyContent: "center" } : {}}
          className={
            "headerLabel" +
            (column.hideHeaderLabel === true ? " hidden" : "" + (column.sortable !== false && props.sortable !== false ? " sortable" : ""))
          }
        >
          {column.render("Header")}
          {column.sortable !== false && props.sortable !== false ? (
            <span className="sort">
              {props.sortBy && props.sortBy.sortColumn === column.id
                ? props.sortBy.sortOrder === sorTypes.DESC
                  ? "🔽"
                  : props.sortBy.sortOrder === sorTypes.ASC
                  ? "🔼"
                  : ""
                : ""}
            </span>
          ) : null}
          {column.headerActions && (
            <div className="actions">
              {column.headerActions.map((Action: any, index: number) => {
                return <React.Fragment key={index}>{Action}</React.Fragment>;
              })}
            </div>
          )}
        </div>
        <div>{column.canFilter && column.Filter !== undefined ? column.render("Filter") : null}</div>
        {filterCnt}
        {column.headerComponent && <div className="headerComponent">{column.headerComponent}</div>}
      </th>
    );
  };
  const buildHeaderGroups = (headerGroup: any, index: number) => {
    return <tr key={index}>{headerGroup.headers.map(buildColumns)}</tr>;
  };

  const buildCells = (cell: any, index: number) => {
    if (cell.column.whiteSpace === true) {
      return (
        <td key={index}>
          <span
            style={{
              display: "flex",
              whiteSpace: cell.column.whiteSpace ? "nowrap" : "normal",
              marginLeft: cell.column.leftByDepth === true ? cell.row.depth * TABLE_EXPAND_OFFSET : 0,
            }}
          >
            {cell.render("Cell")}
          </span>{" "}
        </td>
      );
    }
    return <td key={index}>{cell.render("Cell")}</td>;
  };

  const selectRow = (row: any, index: number) => {
    if (props.onRowSelect) props.onRowSelect(row, index);
  };

  const buildRows = (row: any, index: number) => {
    prepareRow(row);
    let cls = "";
    if (props.onRowSelect) cls = " clickable";
    if (props.selectedRows && row.original && row.original.id && props.selectedRows[row.original.id]) cls += " selected";
    if (index < props.firstRowsFontWeight) {
      cls += " fontWeightBold";
    }
    return (
      <tr className={cls} onClick={() => selectRow(row, index)} key={index}>
        {row.cells.map(buildCells)}
      </tr>
    );
  };

  const recalculate = (itemsPerPage: number, cPage: number) => {
    setCrrPage(cPage);
    const len = props.data ? props.data.length : 0;
    props.maxPages !== undefined ? setMaxPages(props.maxPages) : setMaxPages(Math.ceil(len / itemsPerPage));
    if (props.data) {
      const newData = props.maxPages === undefined ? arrayCut(props.data, (cPage - 1) * itemsPerPage, itemsPerPage) : deepClone(props.data);
      setData(newData);
    }
  };

  const changeCurrentPage = (cPage: number) => {
    if (cPage > 0 && cPage <= maxPages) {
      setCrrPage(cPage);
      if (props.onChange) {
        props.onChange(cPage, itemsPerPage);
      } else {
        recalculate(itemsPerPage, cPage);
      }
    }
  };

  const changeItemsPerPage = (ipp: any) => {
    setCrrPage(1);
    setItemsPerPage(ipp.value);

    if (props.onChange) {
      props.onChange(1, ipp.value);
    } else {
      recalculate(ipp.value, 1);
    }
  };

  useEffect(() => {
    if (props.data) recalculate(itemsPerPage, crrPage);
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    if (props.maxPages !== undefined) {
      recalculate(itemsPerPage, crrPage);
    }
    // eslint-disable-next-line
  }, [props.maxPages]);

  useEffect(() => {
    if (props.crrPage !== undefined) {
      recalculate(itemsPerPage, props.crrPage as any);
    }
    // eslint-disable-next-line
  }, [props.crrPage]);

  useEffect(() => {
    if (ref.current) {
      setHeaderHeight(ref.current?.clientHeight);
    }
    // eslint-disable-next-line
  }, [ref.current?.clientHeight]);

  return (
    <div className={makeClassName("table", props)} style={makeStyleProperties(props, props.style)}>
      {props.hidePagination !== true && (
        <Pagination
          crrPage={crrPage}
          maxPages={maxPages}
          onChangeCurrentPage={changeCurrentPage}
          pages={pages}
          itemsPerPage={itemsPerPage}
          onChangeItemsPerPage={changeItemsPerPage}
          totalRows={props.totalRows}
        />
      )}
      <div className="table-content">
        <table cellSpacing={0}>
          <thead ref={ref}>{headerGroups.map(buildHeaderGroups)}</thead>
          <tbody style={{ opacity: props.loader === true ? 0.4 : 1 }}>{rows.map(buildRows)}</tbody>
          <tfoot/>
        </table>
      </div>
      {props.loader === true && (
          <div style={{ marginTop: `${headerHeight} px` }} className="spinContainer">
            <FaCircleNotch className="spinner" />
          </div>
        )}
    </div>
  );
};

export default Table;
