import React from "react";
import { ToolbarProps } from "react-big-calendar";
import NeuroButton from "components/buttons/neuroButton/NeuroButton"
import "./toolbar.scss"

export const navigateContants = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE'
};

export const views = {
    MONTH: 'month',
    WEEK: 'week',
    WORK_WEEK: 'work_week',
    DAY: 'day',
    AGENDA: 'agenda'
};

const Toolbar = (props: ToolbarProps) => {
    function navigate(action : any) {
        props.onNavigate(action);
    }

    return (
        <div className="toolbar">
                <NeuroButton onClick={navigate.bind(null, navigateContants.PREVIOUS)}>
                    {"< Précédent"}
                </NeuroButton>
                <div>
                <NeuroButton size="big" onClick={navigate.bind(null, navigateContants.TODAY)}>
                    {props.label}
                </NeuroButton>
                </div>
                <NeuroButton onClick={navigate.bind(null, navigateContants.NEXT)}>
                    {"Suivant >"}
                </NeuroButton>
        </div>
    );
};

export default Toolbar;
