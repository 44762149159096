import { DROPDOWN_NAME_KEY } from "config/conf";
import { deepClone } from "./cloneUtils";
import { iterateObject } from "./objectUtils";
import { IDropdownTreeItemProps } from "components/common/dropdownTreeSelect/IDropdownTreeProps";

export const getObjectByKey = (data: any, key: string) => {
  const ret: any = {};

  if (data != null) {
    data.forEach((element: any) => {
      if (element.hasOwnProperty(key)) {
        ret[element[key]] = deepClone(element);
      }
    });
  }
  return ret;
};

export const arrayFilter = (arr: any, key: string, value: any) => {
  if (!arr) return null;
  if (!arr.filter) return null;
  if (value === undefined) return null;
  if (typeof value === "number" && isNaN(value)) {
    return null;
  }
  return arr.filter((element: any) => element[key] === value)[0];
};

export const arrayFilterByArray = (array: object[], key: string, arrayToFilterBy: number[]) => {
  if (!array || !array.filter || !Array.isArray(arrayToFilterBy)) return null;
  return array.filter((element: any) => arrayToFilterBy.includes(element[key]));
};

export const searchArrayIndex = (arr: any, key: string, value: any) => {
  for (let i = 0; i < arr.length; i++) if (arr[i][key] === value) return i;
  return -1;
};

export const searchArrayIndexOfIndex = (arr: any, key: string, value: any, upperCase: boolean = true) =>
  arr.filter((element: any) => {
    const elemnentCopy = { ...element };
    elemnentCopy.name = elemnentCopy.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const el = elemnentCopy[key].toUpperCase();
    const v = value.toUpperCase();
    return el.indexOf(v) >= 0;
  });

export const searchArraySubArray = (arr: any, subArrKey: string, key: string) => {
  if (arr)
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][subArrKey] !== undefined) {
        const subArray = arr[i][subArrKey];
        for (let j = 0; j < subArray.length; j++) {
          if (subArray[j] === key) return arr[i];
        }
      }
    }
  return null;
};

export const arrayCut = (arr: any, start: number, len: number) => {
  return deepClone(arr).splice(start, len);
};

export const arrayToString = (arr: any) => {
  return arr.toString();
};

export const createArrayFromKeyValue = (obj: any, _key: any, _value: any, _except: any = null, intValue: boolean = false) => {
  const ret: any = [];
  iterateObject(obj, (key: any, value: any) => {
    const obj: any = {};
    let canAdd = true;
    if (_except) {
      _except.forEach((element: any) => {
        if (key === element) canAdd = false;
      });
    }
    if (canAdd) {
      obj[_key] = intValue === true ? parseInt(key) : key;
      obj[_value] = value;
      ret.push(obj);
    }
  });
  return ret;
};

export const createArrayFromKey = (arr: any, key: any) => {
  if (!arr) return null;
  const ret: any = [];
  arr.forEach((element: any) => {
    if (element && element.hasOwnProperty(key)) ret.push(element[key]);
  });
  return ret;
};

export const spliceMultiple = (array: any, indexes: any) => {
  return deepClone(array.filter((value: any, idx: number) => indexes.indexOf(idx) === -1));
};

export const createArrayFromKeys = (arr: any, keys: any, sep: string = "") => {
  if (!arr) return null;
  const ret: any = [];
  arr.forEach((element: any) => {
    let retElem = "";
    keys.forEach((keyElem: any) => {
      if (element && element.hasOwnProperty(keyElem)) retElem += (retElem.length > 0 ? sep : "") + element[keyElem];
    });
    ret.push(retElem);
  });
  return ret;
};

export const sortArray = (arr: any, t: any) => {
  if (Object.keys(arr).length === 0) return [];
  const optionType = arr.map((m: any) => (Object.keys(m)[1] === DROPDOWN_NAME_KEY ? Object.keys(m)[1] : DROPDOWN_NAME_KEY))[0];
  if (optionType === DROPDOWN_NAME_KEY) {
    const ret = arr.sort((a: any, b: any) => (a[optionType] ? a[optionType].localeCompare(b[optionType]) : 0));
    const other = ret.find((m: any) => m[optionType] === "Autre" || m[optionType] === "AUTRE");
    if (other) {
      const index = ret.indexOf(other);
      ret.splice(index, 1);
      ret.push(other);
    }
    return ret;
  } else return arr;
};

export const sortArrayByIndex = (arr: any, index: string) => {
  const ret = arr.sort((a: any, b: any) => (a[index] > b[index] ? 1 : b[index] > a[index] ? -1 : 0));
  return ret;
};

export const createArrayFromTree = (treeItems: IDropdownTreeItemProps[], entityKey: string, levelKey: string) => {
  let array = [] as any;
  treeItems.forEach((treeItem: IDropdownTreeItemProps) => {
    if (treeItem.children && treeItem.children.length > 0)
      treeItem.children.forEach((child: IDropdownTreeItemProps) => {
        if (child.checked === true) {
          let tempObject = {} as any;
          tempObject[entityKey] = treeItem.value;
          tempObject[levelKey] = child.value;
          array.push(tempObject);
        }
      });
  });
  return array;
};
