import React, { useState } from "react";
import PageContent from "pageWrapper/pageContent/PageContent";
import { ISmartState } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import "./charts.scss";

import { sectors3DropdownModule, sector3StatisticsModule, userSettingsModule, userCampaignsModule, sectors1DropdownModule, bookingStatisticsModule } from "config/modulesConf";

import Row from "components/common/row/Row";
import { IRoute } from "interfaces/IRoute";
import moment from "moment";
import { deepClone } from "utils/cloneUtils";
import Column from "components/common/column/Column";
import Label from "components/common/label/Label";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import { BusinessLeader } from "config/permsConf";
import BookingStatistics from "components/chart/BookingStatistics";
import DateSelector from "components/common/dateSelector/DateSelector";
import { IAllAnimationsFilters } from "containers/animations/IAnimations";
import { nameOf } from "utils/nameOfUtils";
import Sector3StatisticTable from "./Sector3StatisticTable";
import Sector1StatisticTable from "./Sector1StatisticTable";
import StatisticsFilterPopup from "./StatisticsFilterPopup";
import SimpleButton from "components/buttons/simpleButton/SimpleButton";
import { FaFilter } from "react-icons/fa";


interface IProps {
  route: IRoute;
  userSettings: ISmartState<IUserSettingsData>;
}

interface ISize {
  width: number
  height: number
}

const Statistics = (props: IProps) => {
  // const [currentMonth, setCurrentMonth] = useState<Date | null>(new Date());
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [dateFrom, setDateFrom] = useState(moment().clone().startOf("month")) as any;
  const [dateTo, setDateTo] = useState(moment().clone().endOf("month").toDate()) as any;
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  const [filters, setFilters] = useState({}) as any;
  const sector1: string = userSettingsData.role !== BusinessLeader ? "Multi" : userSettingsData.sectors1.length < 1 ? "Non Défini" : userSettingsData.sectors1[0];
  const quota: number = userSettingsData.quota;
  const totalMissions = props.userSettings?.data?.bookingStatistics.currentMonth?.count ?? 0;

  const registerFilter = (name: any, value: any) => {
    const newFilters = deepClone(filters);
    newFilters[name] = value;
    setFilters(newFilters);
  };

  return (
    <>
      <PageContent
        actions={userSettingsData.role === BusinessLeader && [
          <Row justifyContent={"space-between"} alignItems="center" width={"100%"} margin="0px 20px">
            <Column alignItems='center' justifyContent={"center"}>
              <Label text={`Secteur`} weight="bold" size="big" />
              <Label text={`${sector1} `} weight="bold" size="big" color="blue"/>
            </Column>
            <Column alignItems='center' justifyContent={"center"}>
              <Label text={`Animations`} weight="bold" size="big" />
              <Label text={`${totalMissions}`} weight="bold" size="big" color="green"/>
            </Column>
            <Column alignItems='center' justifyContent={"center"}>
              <Label text={`Quota`} weight="bold" size="big" />
              <Label text={`${quota}`} weight="bold" size="big" color="red"/>
            </Column>
            <Column alignItems='center' justifyContent={"center"}>
              <Label text={`Manquant`} weight="bold" size="big" />
              <Label text={`${Math.max(0, quota - totalMissions)}`} weight="bold" size="big" color="orange"/>
            </Column>
            <BookingStatistics/>
          </Row>]}
      >
        <Row justifyContent={"space-between"} alignItems='flex-end' width={"100%"}>
          {/* <MonthPagination currentMonth={currentMonth} onCurrentMonthChange={setCurrentMonth} /> */}
          <Row minWidth={"140px"} maxWidth={"140px"}/>
          <Row justifyContent='center' alignItems={"center"}>
            <Label text="Du" weight="bold" size="normal" />
            <DateSelector
              name={nameOf((n: IAllAnimationsFilters) => n.dateFrom)}
              onChange={(filter: Date) => setDateFrom(filter)}
              dateFormat={"dd/MM/yyyy"}
              value={dateFrom}
            />
            <Label text="Au" weight="bold" size="normal" />
            <DateSelector fullSize={true}
              name={nameOf((n: IAllAnimationsFilters) => n.dateTo)}
              onChange={(filter: Date) => setDateTo(filter)}
              dateFormat={"dd/MM/yyyy"}
              value={dateTo}
            />
          </Row>
          <SimpleButton
            color="orange"
            label={"Filtres"}
            icon={<FaFilter size={20} />}
            onClick={() => setShowFilters(true)}
            size="large"
            minWidth={"140px"}/>
        </Row>
        {userSettingsData.role === BusinessLeader
          ? <Sector3StatisticTable filters={filters} registerFilter={registerFilter} dateFrom={dateFrom} dateTo={dateTo} />
          : <Sector1StatisticTable filters={filters} registerFilter={registerFilter} dateFrom={dateFrom} dateTo={dateTo} />}
      </PageContent>
      {showFilters &&
        <StatisticsFilterPopup
          closePopup={() => setShowFilters(false)}
          filters={filters}
          registerFilter={registerFilter}
        />}
    </>
  );
};

export default smartConnect([userSettingsModule, sector3StatisticsModule, sectors3DropdownModule, sectors1DropdownModule, userCampaignsModule, bookingStatisticsModule], [sector3StatisticsModule, sectors3DropdownModule, sectors1DropdownModule, userCampaignsModule, bookingStatisticsModule])(Statistics);