import React from "react";
import "./hamburgerButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";

interface IProps extends IButtonProps {}

const HamburgerButton = (props: IProps) => {
  return (
    <Button className="hamburgerButton" {...props}>
      <div />
      <div />
      <div />
    </Button>
  );
};

export default HamburgerButton;
