import React, { useState, useEffect } from "react";
import { makeClassName } from "utils/componentUtils";
import "./dateSelector.scss";
import { IDefaultProps } from "interfaces/IDefaultProps";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";

import "react-datepicker/dist/react-datepicker.css";
import { lowercase } from "utils/stringUtils";

import moment from "moment";

interface IProps extends IDefaultProps {
  label?: string;
  error?: string;
  onChange?: any;
  onNativeChange?: any;
  name?: any;
  value?: any;
  dateFormat?: string;
  minDate?: any;
  maxDate?: any;
}

const DateSelector = (props: IProps) => {
  const [date, setDate] = useState() as any;

  const outDate = (_date: any) => {
    return _date ? new Date(moment(_date).format("YYYY-MM-DD")).toISOString() : "";
  };

  const change = (_date: any) => {
    setDate(_date);
    if (props.onChange) props.onChange(outDate(_date));
  };

  const nativeChange = (_date: any) => {
    setDate(_date);
    if (props.onNativeChange)
      props.onNativeChange({
        target: { value: outDate(_date), name: props.name },
      });
  };

  useEffect(() => {
    setDate(props.value ? new Date(props.value) : "");

    // eslint-disable-next-line
  }, [props.value]);

  return (
    <div className={makeClassName("dateSelector", props)}>
      {props.label && (
        <span className="label">
          {props.label}
          {props.required ? <span className="required"></span> : ""}
        </span>
      )}
      <DatePicker
        placeholderText={props.dateFormat ? (lowercase(props.dateFormat) as string) : ""}
        minDate={props.minDate}
        maxDate={props.maxDate}
        dateFormat={props.dateFormat}
        selected={date}
        locale={fr}
        onChange={props.onNativeChange ? nativeChange : change}
      />
      {props.error ? <span className="error">{props.error}</span> : null}
    </div>
  );
};

export default DateSelector;
