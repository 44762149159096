import React, { } from "react";
import "./footer.scss";
import { smartConnect } from "app/appUtils";


interface IProps {}

const Footer = (props: IProps) => {

  return (
    <div className="footer">
        @ 2021 - BAT Scheduler (beta)
    </div>
  );
};

export default smartConnect([], [])(Footer);
