import React, { MouseEventHandler } from "react";

import { makeStyleProperties } from "utils/componentUtils";
import { IDefaultCssStyles } from "interfaces/IDefaultProps";

import Spinner from "components/common/spinner/Spinner";

import "./simpleButton.scss";

export type ButtonColor = "red" | "blue" | "green" | "orange" | "light-gray" | "gray" | "black" | "white" | "transparent-white" | "transparent-black" | "disabled";
export interface IButtonProps extends IDefaultCssStyles {
  onClick: MouseEventHandler<HTMLButtonElement>;

  children?: React.ReactChildren | React.ReactChild;
  icon?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  label?: string;

  color?: ButtonColor;
  size?: "small" | "normal" | "medium" | "large" | "big";
  rounded?: boolean;
}

const SimpleButton = (props: IButtonProps) => {
  return (
    <button
      className={"simpleButton" + (props.color ? ` colorbtn--${props.color}` : "") + (props.size ? ` sizebtn--${props.size}` : "") + (props.rounded ? " rounded" : "") + (props.disabled ? " disabled" : "")}
      disabled={props.disabled}
      onClick={(e) => (!props.loading ? props.onClick(e) : null)}
      style={makeStyleProperties(props)}
      type="button"
    >
      <div className={"container" + (props.loading ? " loading" : "")}>
        {props.icon !== undefined && props.icon}
        {props.label && <span className="label">{props.label}</span>}
        {props.children}
      </div>
      {props.loading && <Spinner size={props.size} />}
    </button>
  );
};

export default SimpleButton;
