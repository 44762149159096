import React from "react";
import { IDefaultProps } from "interfaces/IDefaultProps";
import DeepSwitch from "./DeepSwitch";
import "./switch.scss";
import { makeStyleProperties } from "utils/componentUtils";

interface IProps extends IDefaultProps {
    className?: string;
    onChange?: any;
    readOnly?: boolean;
    checked?: boolean;
    id?: number;
    nullable?: boolean;
    param?: any;
}

const Switch = (props: IProps) => {

    const onChange = (e: any, event: any, id: number) => {
        if (props.onChange) 
        props.param !== undefined ? props.onChange(props.param) : props.onChange(e);
        //if (props.onChange) props.onChange(e,event,id);
    }

    return (
        <DeepSwitch
          style={makeStyleProperties(props)}
          nullable={props.nullable}
          onChange={onChange}
          checked={props.checked}
          disabled={props.disabled}
          midColor={"#66615b"}
          width={props.nullable ? "55" : "50"}
          height={"28"}
          handleDiameter={22}
          offColor={"#C50000"}
          onColor={"#28864e"}
          offHandleColor={"#fff"}
          onHandleColor={"#fff"}
          activeBoxShadow={"'0 0 2px 3px #3bf'"}
        >
          <div className="icon-logo">l</div>
          <div className="icon-logo">O</div>
        </DeepSwitch>
      );
    };
    export default Switch;
    