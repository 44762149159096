import React, { useState, useEffect } from "react";
import PageContent from "pageWrapper/pageContent/PageContent";
import { IRoute } from "interfaces/IRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";

import SelectInterventionPoint from "containers/interventionPoints/SelectInterventionPoint";
import InterventionPointAvailability from "containers/interventionPoints/InterventionPointAvailability";

import { allAnimationsRoute } from "config/routeConf";
import { selectedCampaignModule, selectedInterventionPointModule, selectedInterventionDateModule, animationModule, userSettingsModule } from "config/modulesConf";
import { IAllInterventionPointsResult } from "containers/interventionPoints/IInterventionPoint"
import { getInterventionPointApi } from "config/apiConf";

import Field from "components/common/field/Field"

import NewAnimationPopup from "./popups/NewAnimationPopup"
import { useRoute } from "hooks/useRoute";
import SelectBookableCampaigns from "containers/campaigns/SelectBookableCampaigns";
import { IAllCampaignsResult } from "containers/campaigns/ICampaigns";

interface IProps {
  route: IRoute;
  selectedCampaign?: ISmartState;
  selectedCampaignActions?: ISmartAction;
  selectedInterventionPoint?: ISmartState;
  selectedInterventionPointActions?: ISmartAction;
  selectedInterventionDate?: ISmartState;
  selectedInterventionDateActions?: ISmartAction;
  animation?: ISmartState;
  animationActions?: ISmartAction;
  userSettings?: ISmartState;
}

const NewAnimation = (props: IProps) => {
  const [interventionPointVisible, setInterventionPointVisible] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const { getRouteFromParams } = useRoute();
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    setCalendarVisible(false);
    props.selectedInterventionDateActions?.reset();
    props.selectedInterventionPointActions?.reset();
    return () => {
      props.selectedInterventionDateActions?.reset();
      props.selectedInterventionPointActions?.reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.animation?.opCompleted) {
      setPopup(false);
      props.selectedInterventionPointActions?.httpCall(getRouteFromParams(getInterventionPointApi, { id: props.selectedInterventionPoint?.data?.id }));
    }
    // eslint-disable-next-line
  }, [props.animation?.opCompleted]);

  useEffect(() => {
    if (props.selectedInterventionPoint?.opCompleted) {
      setCalendarVisible(true);
    }

    if (props.selectedInterventionPoint?.errors) {
      setCalendarVisible(false);
    }
    // eslint-disable-next-line
  }, [props.selectedInterventionPoint?.opCompleted, props.selectedInterventionPoint?.errors]);

  const onSelectCampaign = (campaign: IAllCampaignsResult) => {
    if (campaign === null)
    {
      props.selectedInterventionPointActions?.reset();
      setInterventionPointVisible(false);
      setCalendarVisible(false);
    }
    else
    {
      setInterventionPointVisible(true);
      props.selectedCampaignActions?.functionCall(() => { return { data: campaign } });
    }
  };

  const onSelectInterventionPoint = (interventionPoint: IAllInterventionPointsResult) => {
    if (interventionPoint === null)
      setCalendarVisible(false);
    else
    {
      props.selectedInterventionPointActions?.httpCall(getRouteFromParams(getInterventionPointApi, { id: interventionPoint?.id }));
    }
  };

  const onSelectDate = (date: Date) => {
    props.selectedInterventionDateActions?.functionCall(() => { return { data: date } });
    setPopup(true);
  };

  return (
    <PageContent
      generalLoader={false}
      title={`Nouvelle Animation`}
      backTo={allAnimationsRoute.path}
    >
      <>
        <Field label="Campagne" borderBox={true} spacing={true} size="big" content={
          <SelectBookableCampaigns onSelectBookableCampaigns={onSelectCampaign}/>
        } />

        <Field label="Point d'intervention" borderBox={true} spacing={true} size="big" isVisible={interventionPointVisible} content={
          <SelectInterventionPoint onSelectInterventionPoint={onSelectInterventionPoint}/>
        } />

        <Field label="Calendrier" borderBox={true} spacing={true} size="big" isVisible={calendarVisible} content={
          <InterventionPointAvailability onSelectDate={onSelectDate} />
        } />

        {popup && <NewAnimationPopup onClose={setPopup} />}

      </>
    </PageContent>
  );
};

export default smartConnect([userSettingsModule,
  selectedCampaignModule, selectedInterventionPointModule, selectedInterventionDateModule, animationModule
], [selectedCampaignModule, selectedInterventionPointModule, selectedInterventionDateModule, animationModule])(NewAnimation);
