import React, { useState, useEffect, useRef } from "react";
import { makeClassName, makeStyleProperties } from "utils/componentUtils";
import "./input.scss";
import { IInputProps } from "components/common/input/IInputProps";
import { uppercase, capitalize } from "utils/stringUtils";

const Input = (props: IInputProps) => {
  const [value, setValue] = useState("");
  const [delayTImer, setDelayTimer] = useState() as any;
  const [cursorPosition, setCursorPosition] = useState(0);
  const ref = useRef();

  const doChange = (e: any) => {
    setCursorPosition(e.target.selectionEnd);
    props.onNativeChange ? onNativeChange(e) : change(e);
  };

  const change = (e: any) => {
    const val = props.uppercase === true ? uppercase(e.target.value) : props.capitalize === true ? capitalize(e.target.value) : e.target.value;
    setValue(val);
    if (props.onChange) {
      if (props.delayOnChange !== undefined) {
        clearTimeout(delayTImer);
        const ta = setTimeout(
          (value: any) => {
            props.onChange(value);
          },
          props.delayOnChange,
          val
        );
        setDelayTimer(ta);
      } else {
        props.onChange(val);
      }
    }
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter" && props.onEnter)
      props.onEnter(props.uppercase === true ? uppercase(e.target.value) : props.capitalize === true ? capitalize(e.tarrget.value) : e.target.value);
  };

  const onNativeChange = (e: any) => {
    if (props.onNativeChange)
      props.onNativeChange({
        target: {
          name: props.name,
          value: props.uppercase === true ? uppercase(e.target.value) : props.capitalize === true ? capitalize(e.target.value) : e.target.value,
        },
      });
  };

  useEffect(() => {
    if (props.value === null || props.value === undefined) {
      setValue("");
    } else {
      setValue(props.value);
    }

    // eslint-disable-next-line
  }, [props.value]);

  useEffect(() => {
    if (value.length > 1 && props.type !== "number") {
      const inp: any = ref.current;
      if (inp !== undefined && inp.selectionStart) inp.selectionStart = inp.selectionEnd = cursorPosition;
    }
    // eslint-disable-next-line
  }, [value]);

  const hasInactiveDefaultValue = props.defaultInactiveValue !== undefined && props.defaultInactiveValue !== null && value === "";

  return (
    <div className={makeClassName("input", props)} style={makeStyleProperties(props, props.style)}>
      {props.label && (
        <span className="label">
          {props.label}
          {props.required ? <span className="required"></span> : ""}
        </span>
      )}
      <div className="content">
        {props.contentBefore}
        <input
          className={hasInactiveDefaultValue ? "inactive" : ""}
          ref={ref}
          onKeyPress={onKeyPress}
          type={props.type}
          name={props.name}
          id={props.id}
          autoComplete={props.autoComplete ? props.autoComplete : ""}
          value={
            props.inputProps && props.inputProps.autoComplete === "on" ? undefined : hasInactiveDefaultValue ? props.defaultInactiveValue : value
          }
          onChange={doChange}
          placeholder={props.placeholder !== undefined ? props.placeholder : ""}
          {...props.inputProps}
        />
        {props.content}
        {props.children}
      </div>
      {props.error !== undefined && props.error !== "" ? <div className={"error"}>{props.error}</div> : null}
    </div>
  );
};

export default Input;
