import React from "react";
import Spinner from "components/common/spinner/Spinner";
import "./pageContent.scss";
import BackButton from "components/buttons/backButton/BackButton";
import { useHistory } from "react-router-dom";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";

interface IProps extends IDefaultProps {
  loader?: boolean;
  generalLoader?: boolean;
  backTo?: any;
  title?: any;
  actions?: any;
  children?: any;
  tabsMode?: boolean;
  inTabs?: boolean;
  redirectToEntity?: any;
  redirectToTab?: string;
}

const PageContent = (props: IProps) => {
  const history = useHistory();

  const back = () => {
    if (props.redirectToEntity && props.redirectToTab) history.push(props.backTo + "/" + props.redirectToEntity + "?tab=" + props.redirectToTab);
    else history.push(props.backTo);
  };

  return (
    <>
      <div className={makeClassName("pageContent"+ (props.tabsMode === true ? " tabsMode" : "") + (props.inTabs === true ? " inTabs" : ""), props)}>
        <div className="header">
        <div className="title">
            {props.backTo && <BackButton onClick={back} />}
            {props.title && <span className="titleContent">{props.title}</span>}
          </div>
          {props.actions && (
            <div className="actions">
              {props.actions.map((action: any, index: number) => (
                <React.Fragment key={index}>{action}</React.Fragment>
              ))}
            </div>
          )}
        </div>
        <div className="content">
          {props.children}
          {props.loader === true && <Spinner />}
        </div>
        {props.generalLoader === true && <Spinner />}
      </div>
    </>
  );
};

export default PageContent;
