import { ILoginData } from "interfaces/modules/ILoginData";
import { IModule } from "interfaces/IModule";
import { watcherModule } from "config/modulesConf";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as httpService from "services/http/httpService";
import { ISmartRequest, ISmartState, ISmartActionResponse } from "interfaces/ISmart";
import * as modulesConf from "config/modulesConf";
import * as routeConf from "config/routeConf";
import { capitalize, getPathFromParams } from "utils/stringUtils";
import { IRoute } from "interfaces/IRoute";
import { httpMethodTypes } from "services/http/httpConf";
import { actionTypes, saveTypes } from "./appConf";
import { APP_ID } from "config/conf";

export const getHeaders = (state: any) => {
  const login: ISmartState = state.login;
  const loginData: ILoginData = login.data;
  return {
    headers: {
      Authorization: "Bearer " + (loginData.access_token !== undefined ? loginData.access_token : null),
    },
  };
};

export const checkAction = (dispatch: any, state: any, currentModule: IModule, action: string, payload: any, getAction: any) => {
  const module: IModule = watcherModule;
  let canExecute = true;
  if (module.name !== currentModule.name && canExecute)
    dispatch({
      type: getAction(module, action),
      payload: { data: payload, module: currentModule },
    });
  return canExecute;
};

const getAction = (module: IModule, action: string) => `${module.name}_${action}`;
const executeAction = (dispatch: any, state: any, currentModule: IModule, action: string, payload: any) => {
  const canExecuteAction = checkAction(dispatch, state, currentModule, action, payload, getAction);
  //check token expired
  //getNewToken
  //continue action
  if (canExecuteAction) {
    dispatch({ type: getAction(currentModule, action), payload: payload });
    if (currentModule.saveTo === saveTypes.LOCAL_STORAGE) {
      if (action === actionTypes.RESET) localStorage.removeItem(APP_ID + currentModule.name);
      if (action === actionTypes.DATA_RECEIVED) localStorage.setItem(APP_ID + currentModule.name, JSON.stringify(payload));
    }
  }
  return canExecuteAction;
};

export const smartConnect = (modules: any, modulesActions: any) => {
  return connect(
    (state: any) => {
      const reduxState: any = {};
      modules.forEach((element: IModule) => (reduxState[`${element.name}`] = state[`${element.name}`]));
      return reduxState;
    },
    (dispatch: any) => {
      const reduxActions: any = {};
      modulesActions.forEach((element: IModule) => {
        const actions = {
          reset: () => (dispatch: any, getState: any) => executeAction(dispatch, getState(), element, actionTypes.RESET, null),
          functionCall: (func: any, params: any = null) => async (dispatch: any, getState: any) => {
            const response = func(params);
            executeAction(dispatch, getState(), element, actionTypes.DATA_RECEIVED, response.data);
          },
          httpCall: (request: ISmartRequest, params: any = null) => async (dispatch: any, getState: any) => {
            let canExecute = executeAction(dispatch, getState(), element, actionTypes.OP_STARTED, request);
            if (canExecute) {
              try {
                let response: any = null;
                switch (request.method) {
                  case httpMethodTypes.GET:
                    response = await httpService.get(getPathFromParams(request.endPoint, params), getHeaders(getState()));
                    break;
                  case httpMethodTypes.POST:
                    response = await httpService.post(request.endPoint, params, getHeaders(getState()));
                    break;
                  case httpMethodTypes.PUT:
                    response = await httpService.put(request.endPoint, params, getHeaders(getState()));
                    break;
                  case httpMethodTypes.DELETE:
                    response = await httpService.deleteData(getPathFromParams(request.endPoint, params), getHeaders(getState()));
                    break;
                  case httpMethodTypes.PATCH:
                    response = await httpService.patch(request.endPoint, params, getHeaders(getState()));
                    break;
                }
                executeAction(dispatch, getState(), element, actionTypes.DATA_RECEIVED, response.data);
              } catch (errors) {
                executeAction(dispatch, getState(), element, actionTypes.ERRORS, errors);
              }
            }
          },
          setData: (params: any = null) => async (dispatch: any, getState: any) =>
            executeAction(dispatch, getState(), element, actionTypes.DATA_RECEIVED, params),
        };

        reduxActions[`${element.name}Actions`] = bindActionCreators(actions, dispatch);
      });
      return reduxActions;
    }
  );
};

export const getSmartReducers = () => {
  const _modules = Object.values(modulesConf);
  const reducers = {} as any;
  _modules.forEach((module: IModule) => {
    let iData: any = {};
    if (module.saveTo === saveTypes.LOCAL_STORAGE) {
      const storageData = localStorage.getItem(APP_ID + module.name);
      iData = storageData ? JSON.parse(storageData) : {};
    }
    const initialState: ISmartState = {
      opStarted: null,
      data: iData,
      response: null,
      loading: false,
      errors: null,
      opCompleted: null,
      module: module,
      actionType: null,
    };
    reducers[`${module.name}`] = (state: ISmartState = initialState, action: ISmartActionResponse) => {
      switch (action.type) {
        case getAction(module, actionTypes.RESET):
          return {
            ...state,
            ...initialState,
            data: {},
          };
        case getAction(module, actionTypes.ERRORS):
          return {
            ...state,
            //AZURE AUTHENTICATION
            errors: action.payload && action.payload.data, //FOR FIREFOX TO WORK: action.payload && action.payload.data
            loading: false,
            opCompleted: null,
            actionType: actionTypes.ERRORS,
          };
        case getAction(module, actionTypes.OP_STARTED):
          return {
            ...state,
            opStarted: action.payload,
            errors: null,
            loading: true,
            opCompleted: null,
            actionType: actionTypes.OP_STARTED,
          };
        case getAction(module, actionTypes.DATA_RECEIVED): {
          return {
            ...state,
            opCompleted: action.payload,
            loading: false,
            data: action.payload,
            response: action.payload,
            actionType: actionTypes.DATA_RECEIVED,
          };
        }
        default:
          return { ...state };
      }
    };
  });
  return reducers;
};

export const getFinalRoutes = () => {
  const _routes = Object.values(routeConf);
  const finalRoutes: any = [];
  _routes.forEach((route: IRoute) => {
    try {
      const parentRoute = route.parentContainer !== undefined ? route.parentContainer + "/" : "";
      const container = require("containers/" + parentRoute + capitalize(route.container));
    
      finalRoutes.push({
        route: route,
        container: container.default,
      });
    } catch (e) {
      console.log(route, e.toString());
    }
  });
  return finalRoutes;
};
