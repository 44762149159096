import React from "react";
import "./navbarContentItem.scss";
import { INavbarContentItem } from "./INavbarContentItem";
import * as lngConf from "config/lngConf";
import { useTranslate } from "services/translationService";
import { useHistory, useLocation } from "react-router-dom";

interface IProps extends INavbarContentItem {
  onSelect?: any;
}

const NavbarContentItem = (props: IProps) => {
  const { t } = useTranslate();
  const lbConf: any = (lngConf as any)[props.label];
  const label = t(lbConf);
  const history = useHistory();
  const location = useLocation();
  const active = location.pathname === props.route.path ? " active" : "";
  const navigate = () => {
    history.push(props.route.path);
    if (props.onSelect) props.onSelect(props);
  };

  return (
    <div onClick={navigate} className={"navbarContentItem" + active}>
      {label}
    </div>
  );
};

export default NavbarContentItem;
