export const getValueByKey = (object: any, key: string, keyCompare: any) => {
  for (let [, value] of Object.entries(object)) {
    const valueData: any = value;
    if (valueData.hasOwnProperty(key) && valueData[key] === keyCompare)
      return valueData;
  }

  return null;
};

export const getKeyByValue = (object: any, keyCompare: any) => {
  for (let [key, value] of Object.entries(object)) {
    if (value === keyCompare) return key;
  }
  return null;
};

export const getKeysByValue = (object: any, keyCompare: any) => {
  let ret = [];
  for (let [key, value] of Object.entries(object)) {
    if (value === keyCompare) ret.push(key);
  }
  return ret;
};

export const getKeyByKeyValue = (object: any, keyValue:any, keyCompare: any) => {
  let ret = [];
  for (let [key, value] of Object.entries(object)) {
    const keys:any = value;
    if (keys[keyValue] === keyCompare) ret.push(key);
  }
  return ret;
};

export const getKeyByKeyValueWithCondition = (object: any, keyValue:any, keyCompare: any, keyCondition:any, keyConditionValue:any, equal:boolean) => {
  let ret = [];
  for (let [key, value] of Object.entries(object)) {
    const keys:any = value;
    let cond = equal === true ? keys[keyCondition] === keyConditionValue : keys[keyCondition] !== keyConditionValue;
    if (keys[keyValue] === keyCompare && cond === true) ret.push(key);
  }
  return ret;
};

export const getArrayFromKey = (object: any, _key: string) => {
  const ret: any = [];
  for (let [, value] of Object.entries(object)) {
    const valueData: any = value;
    if (valueData.hasOwnProperty(_key)) ret.push(valueData[_key]);
  }

  return ret;
};

export const getArrayFromValue = (object: any, _value: any) => {
  const ret: any = [];
  for (let [, value] of Object.entries(object)) {
    const valueData: any = value;
    if (valueData === _value) ret.push(valueData);
  }

  return ret;
};

export const getArrayKeysFromValue = (
  object: any,
  _value: any,
  _excepts: any = null
) => {
  const ret: any = [];
  for (let [key, value] of Object.entries(object)) {
    const valueData: any = value;
    if (valueData === _value) {
      let canAdd = true;
      if (_excepts) {
        _excepts.forEach((element: any) => {
          if (key === element) canAdd = false;
        });
      }
      if (canAdd) ret.push(key);
    }
  }

  return ret;
};

export const iterateObject = (object: any, callback: any) => {
  for (let [key, value] of Object.entries(object)) {
    callback(key, value);
  }
};

export const iterateObject2 = (object: any, callback: any) => {
  for (let [key, value] of object) {
    callback(key, value);
  }
};
