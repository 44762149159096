import React from "react";
import { makeStyleProperties } from "utils/componentUtils";
import { IDefaultCssStyles } from "interfaces/IDefaultProps";
import "./column.scss";

interface IProps extends IDefaultCssStyles {
  children?: React.ReactNode;
  className?: string;
  columnRef?: React.LegacyRef<HTMLDivElement>;
  spacing?: "5px" | "10px" | "15px" | "20px" | "25px" | "30px";
}

const Column = (props: IProps) => {
  let className = "column";

  if (props.className)
    className += ` ${props.className}`;
  if (props.spacing)
    className += ` clmnSpace--${props.spacing}`;

  return (
    <div
      className={className}
      ref={props.columnRef}
      style={makeStyleProperties(props)}>
      {props.children}
    </div>
  );
};

export default Column;
