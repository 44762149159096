import React, { useState, useEffect } from "react";
import { useRoute } from "hooks/useRoute";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";

import Spinner from "components/common/spinner/Spinner";
import { Calendar, View, momentLocalizer } from "react-big-calendar";
import Toolbar from "components/calendar/toolbar/Toolbar"
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./interventionPoints.scss"

import { selectedInterventionPointModule, interventionPointAvaibilityModule, animationModule, selectedCampaignModule} from "config/modulesConf";
import { getInterventionPointAvailabilityApi } from "config/apiConf";
import { IInterventionPointAvailability, IInterventionPointData  } from "./IInterventionPoint";
import { IAllCampaignsResult } from "containers/campaigns/ICampaigns";

const localizer = momentLocalizer(moment);

interface IProps {
  onSelectDate: any;
  selectedCampaign?: ISmartState;
  selectedInterventionPoint?: ISmartState;
  interventionPointAvaibility?: ISmartState;
  interventionPointAvaibilityActions?: ISmartAction;
  animation?: ISmartState;
  animationActions?: ISmartAction;
}

const InterventionPointAvailability = (props: IProps) => {
  const { addParams } = useRoute();
  const selectedInterventionPoint: IInterventionPointData = props.selectedInterventionPoint?.data;
  const selectedCampaign: IAllCampaignsResult = props.selectedCampaign?.data;
  const interventionPointAvailabilities: IInterventionPointAvailability[] = props.interventionPointAvaibility?.data;
  const [displayMonth, setDisplayMonth] = useState(new Date());
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    props.interventionPointAvaibilityActions?.reset();

    if (selectedInterventionPoint?.id !== undefined)
      getData();

    return () => {
      props.interventionPointAvaibilityActions?.reset();
    };
    // eslint-disable-next-line
  }, [props.selectedInterventionPoint?.opCompleted]);

  useEffect(() => {
    if (props.interventionPointAvaibility?.opCompleted) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [props.interventionPointAvaibility?.opCompleted]);

  useEffect(() => {
    if (props.interventionPointAvaibility?.errors) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [props.interventionPointAvaibility?.errors]);

  const getData = () => {
    const filter: any = {
      campaignId: selectedCampaign.id,
      interventionPointId: selectedInterventionPoint.id, 
      month: moment(displayMonth).format('YYYY-MM-DD') 
    };
    props.interventionPointAvaibilityActions?.httpCall(addParams(getInterventionPointAvailabilityApi, filter));
  }

  function eventComponent(object: any) {
    var className = 'title'
    if (!moment(object.event.start ).isSame(displayMonth, "month"))
      className += " outOfMonth"
  
    return (
      <div className='event'>
        <label className={className}>{object.event.title}</label>
        {object.event.desc && ':  ' + object.event.desc}
      </div>
    )
  }

  const customDateHeader = (object: any) => {
    var className = '';
    if (moment(object.date).isSame(moment(), 'day'))
      {
        className += 'today';
        if (!moment(object.date).isSame(displayMonth, "month"))
          className += " outOfMonth"
      }

    return (
      <span className={className}>
          {object.label}
      </span>)
  }

  const customDayPropGetter = (date : Date) => {
    var className = "day outofmonth";

    if (!Array.isArray(interventionPointAvailabilities)) return { className };

    var event = interventionPointAvailabilities.find(d => moment(date).isSame(moment(d.start), 'day'));

    switch (event?.status) {
      case 'OutOfMonth':
        className = 'day outofmonth';
        break;
      case 'CancellationRequested':
        className = 'day cancelled';
        break;
      case 'Booked':
        className = 'day booked';
        break;
      case 'Available':
        className = 'day available';
        break;
      case 'QuotaFull':
        className = 'day quotafull';
        break;
      case 'Unavailable':
        className = 'day unavailable';
        break;
      case 'Backoffice':
        className = 'day backoffice';
        break;
      default:
        className = 'day disabled'
    }
    
    if (!moment(date).isSame(displayMonth, "month"))
      className += " outOfMonth"

    return { className }
  }

  const onSelectDate = (selectedSlots : any) => {
    if (selectedSlots.start === selectedSlots.end 
      && interventionPointAvailabilities.some(s => moment(s.start).isSame(moment(selectedSlots.start), 'day') && s.status === "Available") )
      {
        props.onSelectDate(moment(selectedSlots.start.toISOString()).utcOffset(0, true).format());
      }
  }

  const onNavigate = (date : any, view:any, action:any) => {
    setDisplayMonth(date);

    props.interventionPointAvaibilityActions?.reset();
    const filter: any = {
        campaignId: selectedCampaign.id,
        interventionPointId: selectedInterventionPoint.id, 
        month: moment(date).format('YYYY-MM-DD') 
      };
    props.interventionPointAvaibilityActions?.httpCall(addParams(getInterventionPointAvailabilityApi, filter));
  }

  const allViews: View[] = ['month']; //'agenda', 'day', 'week', 

  return (
    <div className="calendar-content">
        <Calendar className="calendaravaibility"
        longPressThreshold={0.0000000001}
        selectable={true}
        events={Array.isArray(interventionPointAvailabilities) ? interventionPointAvailabilities : []}
        localizer={localizer}
        defaultView={"month"}
        views={allViews}
        scrollToTime={new Date(1970, 1, 1, 6)}
        defaultDate={displayMonth}
        onSelectEvent={event => alert("toto")}
        onSelectSlot={onSelectDate}
        dayPropGetter={customDayPropGetter}
        onNavigate={onNavigate}
        //slotPropGetter={customSlotPropGetter}
        components={{
          eventWrapper: eventComponent,
          toolbar : Toolbar,
          month: {
            dateHeader: customDateHeader
        }
        }}
      />
      {loader === true && <Spinner />}
    </div>
  );
};

export default smartConnect([selectedCampaignModule, selectedInterventionPointModule, interventionPointAvaibilityModule, animationModule ], [ selectedInterventionPointModule, interventionPointAvaibilityModule, animationModule ])(InterventionPointAvailability);
