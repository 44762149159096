import React, { useState, useEffect, useMemo } from "react";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import "./charts.scss";

import { sector3StatisticsModule, userSettingsModule } from "config/modulesConf";
import { getSector3StatisticsApi } from "config/apiConf";

import moment from "moment";
import { deepClone } from "utils/cloneUtils";
import { calculatePageFilters } from "utils/pageUtils";
import { ISector3StatisticsData, ISector3StatisticsResult } from "./IStatistics";
import Label from "components/common/label/Label";
import StackBarChart, { SimpleStackBarData } from "components/chart/stackBarChart/StackBarChart";
import SimpleTable, { TableColumn } from "components/common/table/simpleTable/SimpleTable";
import Row from "components/common/row/Row";


interface IProps {
    filters: any;
    dateFrom: Date;
    dateTo: Date;
    registerFilter: (name: any, value: any) => void;
    sector3Statistics?: ISmartState;
    sector3StatisticsActions?: ISmartAction;
    userSettings?: ISmartState;
}

interface ISize {
    width: number
    height: number
}

const Sector3StatisticTable = (props: IProps) => {
    const [tblLoader, setTblLoader] = useState(true);
    const [crrPage, setCrrPage] = useState(1);
    const [pageSize, setPageSize] = useState(10000);
    const [tableSorter, setTableSorter] = useState({}) as any;
    const [tableFilters, setTableFilters] = useState({}) as any;
    const sector3StatisticsData: ISector3StatisticsData = props.sector3Statistics?.data;
    const quota: number = props.userSettings?.data?.quota ?? 0;
    const colors = ['#2E93fA', '#FF9800', '#16A085', '#CB4335', '#2980B9', '#40E0D0', '#95A5A6', '#27AE60', '#F1C40F', '#E74C3C',
        '#34495E', '#616A6B', '#8E44AD', '#CCCCFF', '#DE3163', '#9FE2BF']

    useEffect(() => {
        props.sector3StatisticsActions?.reset();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (props.dateFrom && props.dateTo)// currentMonth)
        {
            window.scrollTo(0, 0)
            setTblLoader(true);
            getData(crrPage, pageSize, props.filters, tableSorter);
        }

        // eslint-disable-next-line
    }, [props.dateFrom, props.dateTo, props.filters]); //currentMonth

    useEffect(() => {
        if (props.sector3Statistics?.opCompleted) {
            setTblLoader(false);
        }
        // eslint-disable-next-line
    }, [props.sector3Statistics?.opCompleted]);

    const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
        setCrrPage(cPage);
        setPageSize(pageSize);
        setTblLoader(true);

        const filter: any = {
            pageNumber: cPage,
            pageSize: pageSize,
        };

        calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
        calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);

        let newFilter = deepClone(filter);
        newFilter.sortColumn = 'sector3';
        newFilter.sortOrder = 'asc';

        newFilter.startDate = moment(props.dateFrom).format("YYYY-MM-DD");
        newFilter.endDate = moment(props.dateTo).format("YYYY-MM-DD");

        props.sector3StatisticsActions?.httpCall(getSector3StatisticsApi, newFilter);
    };

    const memoColumns = useMemo((): TableColumn<ISector3StatisticsResult>[] => {
        return [
            {
                Header: () => <Label text="Zones" weight="bold" />,
                Cell: (_props: ISector3StatisticsResult) => <span>{_props.sector3}</span>,
                accessor: "sector3",
                maxWidth: 200,
                minWidth: 120,
            },
            {
                Header: () => <Label text="Secteurs" weight="bold" />,
                width: '100%',
                minWidth: 300,
                accessor: "sector1Statistics",
                Cell: (_props: ISector3StatisticsResult) => {
                    if (!_props.sector1Statistics) {
                        return null;
                    }
                    var data: SimpleStackBarData[] = _props.sector1Statistics.map((s1, index) =>
                    ({
                        title: _props.sector3,
                        value: s1.totalMissions,
                        valueText: `${s1.totalMissions} Animation(s)`,
                        label: s1.totalMissions.toString(),
                        color: colors[index],
                        colorText: `${s1.sector1}`,
                    }));
                    return (data.length > 0)
                        ? <StackBarChart id={_props.sector3} data={data} height="40px" />
                        : null;
                }
            },
            {
                Header: () => <Label text="Animations" weight="bold" />,
                Filter: () => <Label text={sector3StatisticsData?.results?.reduce((a, b) => a + b.totalMissions, 0).toString()} weight="bold" size="medium" color="red" />,
                Cell: (_props: ISector3StatisticsResult) => {
                    const percentage = Math.round(_props.totalMissions * 100 / quota);
                    const displayPercentage = !isNaN(percentage) && isFinite(percentage);
                    return (
                        <Row width={"100%"} justifyContent="center">
                            <Label text={_props.totalMissions.toString()} textAlign="center" color="green" weight="bold" />
                            <Label text={displayPercentage ? ` (${percentage}%)` : "N/A"} textAlign="center" weight="bold" />
                        </Row>)
                },
                accessor: "totalMissions",
                minWidth: 100,
            },
            {
                Header: () => <Label text="BackOffice" weight="bold" />,
                Filter: () => <Label text={sector3StatisticsData?.results?.reduce((a, b) => a + b.totalMissionsBackOffice, 0).toString()} weight="bold" size="medium" color="red" />,
                minWidth: 100,
                accessor: "totalMissionsBackOffice",
                Cell: (_props: ISector3StatisticsResult) => <Label text={_props.totalMissionsBackOffice.toString()} textAlign="center" width={"100%"} weight="bold" />,
            },
        ]
      // eslint-disable-next-line
    }, [sector3StatisticsData]);

    return (
        <SimpleTable 
        columns={memoColumns} 
        data={sector3StatisticsData} 
        virtualized={true}
        itemsSize={() => 50}
        loading={tblLoader} 
        />
    );
};

export default smartConnect([userSettingsModule, sector3StatisticsModule], [sector3StatisticsModule])(Sector3StatisticTable);