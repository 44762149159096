import React from "react";
import { useHistory } from "react-router-dom";
import { IButtonProps } from "components/common/button/IButtonProps";
import { makeClassName } from "utils/componentUtils";
import "./button.scss";
import { FaCircleNotch } from 'react-icons/fa';

const Button = (props: IButtonProps) => {
  const history = useHistory();

  const click = (e: any, newTab = false  ) => {
    if (props.onClick || props.to) {
      e.preventDefault();
      e.stopPropagation();
      if (props.onClick) props.param !== undefined ? props.onClick(props.param) : props.onClick();
      if (props.to) {
        if (props.newTab || newTab === true) {
          if (props.param !== undefined) window.open(props.to + "/" + props.param, "_blank");
          else window.open(props.to, "_blank");
        } else {
          if (props.param !== undefined) history.push(props.to + "/" + props.param);
          else history.push(props.to);
        }
      }
    }
  };

  const rightClick = (e: any) => {
    if (props.rightClick !== undefined) {
      click(e);
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  const onMouseDown = (e:any) =>{
    if (props.middleClick === true && e.button === 1){
      click(e, props.newTabOnMiddleClick ? true : false);
    }
  }

  return (
    <button
      onContextMenu={rightClick}
      onMouseDown={onMouseDown}
      type={props.type}
      title={props.tooltip}
      form={props.form}
      className={makeClassName("button", props)}
      style={{ marginLeft: props.spaceBetween }}
      onClick={click}
    >
      <div className={'container'} style={{ opacity: props.loader === true ? 0 : 1}} >
        {props.content !== undefined && props.content}
        {props.label !== undefined &&
          <div className={"label" + (props.content !== undefined ? " content" : "")}>
            {props.label.split('\n').map(l => <span key={l}>{l}</span>)}
          </div>}
        {props.children}
      </div>
      {props.loader === true && <div className="spinContainer" ><FaCircleNotch className="spinner" /></div>}
    </button>
  );
};

export default Button;
