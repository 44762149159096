import React, { useEffect, useState } from "react";
import { deepClone } from "utils/cloneUtils";
import { nameOf } from "utils/nameOfUtils";
import { useRoute } from "hooks/useRoute";

import { ISmartAction, ISmartState } from "interfaces/ISmart";

import { smartConnect } from "app/appUtils";
import Form from "components/common/form/Form";
import { IPopupProps } from "components/common/popup/IPopupProps";
import Popup from "components/common/popup/Popup";

import { getRolesDropdownApi, updateUserApi } from "config/apiConf";
import { rolesDropdownModule, updateUserModule } from "config/modulesConf";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import { IAllUsersResult } from "../IUsers";
import { IRolesDropdown, IUpdateUserRoleData } from "containers/roles/IRoles";
import Dropdown from "components/common/dropdown/Dropdown";
import { useTranslate } from "services/translationService";
import * as lngConf from "config/lngConf";
import { arrayFilter } from "utils/arrayUtils";
import { numberValidator, smartValidationSchema, useSmartForm } from "hooks/useSmartForm";

interface IProps extends IPopupProps {
  user: IAllUsersResult;
  updateUser?: ISmartState;
  updateUserActions?: ISmartAction;
  rolesDropdown?: ISmartState;
  rolesDropdownActions?: ISmartAction;
}

const UpdateUserPopup = (props: IProps) => {
  const { t } = useTranslate();
  const [loaderRoles, setLoaderRoles] = useState(true);
  const { getRouteFromParams } = useRoute();
  const rolesDropdownData: IRolesDropdown = props.rolesDropdown?.data;

  useEffect(() => {
    props.rolesDropdownActions?.reset();
    props.updateUserActions?.reset();

    props.rolesDropdownActions?.httpCall(getRolesDropdownApi);

    return () => {
      props.rolesDropdownActions?.reset();
      props.updateUserActions?.reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.rolesDropdown?.opCompleted) {
      setLoaderRoles(false);
    }
    // eslint-disable-next-line
  }, [props.rolesDropdown?.opCompleted]);

  useEffect(() => {
    if (props.rolesDropdown?.errors) {
      setLoaderRoles(false);
    }
    // eslint-disable-next-line
  }, [props.rolesDropdown?.errors]);

  const update = (values: any) => {
    const newValues = deepClone(values);
    props.updateUserActions?.httpCall(getRouteFromParams(updateUserApi, { id: props.user?.id }), newValues);
  };

  const smartForm = useSmartForm({
    initialValues: {
      id: props.user?.id,
      roleId: props.user?.roleId,
    },
    validationSchema: smartValidationSchema({
      roleId: numberValidator().required("Un role doit être séléctionné"),
    }),
    onSubmit: (values: any) => {
      update(values);
    },
  });

  const roleId = nameOf((n: IUpdateUserRoleData) => n.roleId);
  return (
    <Popup {...props} height='250px' bigSize={true} title={"Edition de l'utilisateur"}>
      <Form
        actions={[
          <NeuroButton color="green" size={'normal'} onClick={smartForm.submit} label={"Sauvegarder"} disabled={false} />
        ]}
      >
        <Dropdown
          hideDeleteButton={true}
          required={true}
          filter={true}
          loader={loaderRoles}
          // clearOnNullValue={true}
          placeholder={t(lngConf.PH_SELECT)}
          value={arrayFilter(rolesDropdownData, "id", smartForm.getValue(roleId))}
          name={roleId}
          onNativeChange={smartForm.change}
          label={t(lngConf.COL_ROLES)}
          options={rolesDropdownData}
          optionsIdent={"name"}
          ident="id"
        />
      </Form>
    </Popup>
  );
};

export default smartConnect(
  [rolesDropdownModule, updateUserModule],
  [rolesDropdownModule, updateUserModule]
)(UpdateUserPopup);
