import React from "react";
import { makeClassName } from "utils/componentUtils";
import { IDefaultProps } from "interfaces/IDefaultProps";
import "./form.scss";
import Spinner from "../spinner/Spinner";

interface IProps extends IDefaultProps {
  children?: any;
  actions?: any;
  noWrap?: boolean;
  loader?: boolean;
  tabsMode?: boolean;
  scrollable?: boolean;
  height?:string;
  maxHeight?:string;
  maxHeightOffset?:string;
}

const Form = (props: IProps) => {

  const style:any = {};
  if (props.height !== undefined){
    style.height = props.height;
  }
  if (props.maxHeight !== undefined){
    style.maxHeight = props.maxHeight;
    if (props.maxHeightOffset !== undefined){
      style.maxHeight = `calc(${props.maxHeight} - ${props.maxHeightOffset})`;
    }
  }

  const contentStyle:any = {};
  if (props.height !== undefined || props.maxHeight !== undefined){
    contentStyle.overflowY = "auto";
    contentStyle.flex = "unset";
  }
  return (
    <div style={style} className={makeClassName("form" + (props.tabsMode === true ? " tabsMode" : "") + (props.scrollable === true ? " scrollable" : ""), props)}>
      <div style={contentStyle} className={"content" + (props.noWrap === true ? " noWrap" : "")}>{props.children}</div>
      {props.actions && (
        <div className="actions">
          {props.actions.map((action: any, index: number) => (
            <React.Fragment key={index}>{action}</React.Fragment>
          ))}{" "}
        </div>
      )}
      {props.loader === true && <Spinner />}
    </div>
  );
};

export default Form;
