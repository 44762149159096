import { IRoute } from "interfaces/IRoute";
import * as permsConf from "./permsConf";

const routePathParamsFormat = "/:id?";

export const noAccessRoute: IRoute = {
  name: "noAccess",
  path: "/noAccess",
  parentContainer: "noAccess",
  container: "noAccess",
};

//InterventionPoints
export const allInterventionPointsRoute: IRoute = {
  name: "allInterventionPoints",
  path: "/InterventionPoints",
  pathParams: routePathParamsFormat,
  parentContainer: "interventionPoints",
  container: "allInterventionPoints",
  roleRequired: [permsConf.Administrator, permsConf.SuperAdministrator, permsConf.AreaManager, permsConf.BusinessLeader],
};

//Imports
export const allImportsRoute: IRoute = {
  name: "allImports",
  path: "/Imports",
  pathParams: routePathParamsFormat,
  parentContainer: "imports",
  container: "allImports",
  roleRequired: [permsConf.SuperAdministrator],
};

//Sectors1
export const allSectors1Route: IRoute = {
  name: "allSectors1",
  path: "/Sectors1",
  pathParams: routePathParamsFormat,
  parentContainer: "sectors1",
  container: "allSectors1",
  roleRequired: [permsConf.Administrator, permsConf.SuperAdministrator],
};

//Statistics
export const statisticsRoute: IRoute = {
  name: "statistics",
  path: "/Statistics",
  pathParams: routePathParamsFormat,
  parentContainer: "statistics",
  container: "statistics",
  roleRequired: [permsConf.Administrator, permsConf.SuperAdministrator, permsConf.AreaManager, permsConf.BusinessLeader],
};

//Users
export const allUsersRoute: IRoute = {
  name: "allUsers",
  path: "/Users",
  pathParams: routePathParamsFormat,
  parentContainer: "users",
  container: "allUsers",
  roleRequired: [permsConf.AreaManager, permsConf.Administrator, permsConf.SuperAdministrator],
};

//Animations
export const allAnimationsRoute: IRoute = {
  name: "allAnimations",
  path: "/Animations",
  pathParams: routePathParamsFormat,
  parentContainer: "animations",
  container: "allAnimations",
  roleRequired: [permsConf.BusinessLeader, permsConf.AreaManager, permsConf.Administrator, permsConf.SuperAdministrator],
};

export const animationRoute: IRoute = {
  name: "animation",
  path: "/animation",
  pathParams: routePathParamsFormat,
  parentContainer: "animations",
  container: "NewAnimation",
  roleRequired: [permsConf.BusinessLeader, permsConf.AreaManager, permsConf.Administrator, permsConf.SuperAdministrator],
};

export const homeRoute: IRoute = {
  name: "home",
  path: "/",
  pathParams: routePathParamsFormat,
  redirectTo: allAnimationsRoute,
  parentContainer: "home",
  container: "home",
  roleRequired: [permsConf.BusinessLeader, permsConf.AreaManager, permsConf.Administrator, permsConf.SuperAdministrator],
};