import React, { useEffect, useState, useRef, MutableRefObject, CSSProperties } from "react";
import { IDefaultProps } from "interfaces/IDefaultProps";
import getBackgroundColor from "./getBackgroundColor";
import "./switch.scss";

interface IProps extends IDefaultProps {
  className?: string;
  onChange: any;
  readOnly?: boolean;
  handleDiameter?: any; 
  checked?: boolean;
  id?: number;
  width: string;
  height: string;
  offHandleColor: string;
  onHandleColor: string;
  offColor: string;
  onColor: string;
  midColor?: string;
  boxShadow?: string;
  activeBoxShadow?: string;
  checkedIcon?: any;
  uncheckedIcon?: any;
  nullable?: boolean;
  children?: JSX.Element[] | JSX.Element;
}

const DeepSwitch = (props: IProps) => {
  const switchRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const [isDragging, setDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [hasOutline, setOutline] = useState<boolean>(true);

  const height = parseInt(props.height);
  const width = parseInt(props.width);
  const handleDiameter = props.handleDiameter || height - 2;
  const checkedPos = Math.max(width - height, width - (height + handleDiameter) / 2);
  const uncheckedPos = Math.max(0, (height - handleDiameter) / 2);
  const undefinedPos = (checkedPos + uncheckedPos) / 2;

  const [pos, setPos] = useState<number>(props.checked ? checkedPos : (props.nullable && props.checked === undefined) ? undefinedPos : uncheckedPos);

  useEffect(() => {
    const newPos = props.checked ? checkedPos : (props.nullable && props.checked === undefined) ? undefinedPos : uncheckedPos;
    if (newPos !== pos)
      setPos(newPos);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.checked]);

  //#region DragEvents
  const onDragStart = (clientX: number) => {
    switchRef.current.focus();

    setStartX(clientX - switchRef.current.getBoundingClientRect().left);
    setOutline(true);
  };

  const onDrag = (clientX: number) => {
    const posX = clientX - switchRef.current.getBoundingClientRect().left;
    const startPos = props.checked ? checkedPos : uncheckedPos;
    const mousePos = startPos + posX - startX;

    // We need this check to fix a windows glitch where onDrag is triggered onMouseDown in some cases
    if (!isDragging && posX !== startX)
      setDragging(true);

    const newPos = Math.min(checkedPos, Math.max(uncheckedPos, mousePos));

    // Prevent unnecessary rerenders
    if (newPos !== pos)
      setPos(newPos);
  };

  const onDragStop = (event: MouseEvent | TouchEvent) => {
    const bounds = switchRef.current.getBoundingClientRect();
    let posX;

    if ("clientX" in event)
      posX = event.clientX - bounds.left;
    else
      posX = event.changedTouches[0].clientX - bounds.left;
    const denominator = bounds.width / (props.nullable === true ? 3 : 2);

    if ((props.nullable === true && posX > 2 * denominator) || (props.nullable !== true && posX >= denominator)) {
      setPos(checkedPos);
      onChange(event, true);
    } else if (posX < denominator) {
      setPos(uncheckedPos);
      onChange(event, false);
    } else {
      setPos(undefinedPos);
      onChange(event, undefined);
    }

    setDragging(false);
    setOutline(false);
  };
  //#endregion DragEvents

  //#region MouseEvents
  const onMouseDown = (event: MouseEvent) => {
    event.preventDefault();
    // Ignore right click and scroll
    if (typeof event.button === "number" && event.button !== 0) {
      return;
    }

    onDragStart(event.clientX);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const onMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    onDrag(event.clientX);
  };

  const onMouseUp = (event: MouseEvent) => {
    onDragStop(event);
    window.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("mouseup", onMouseUp);
  };
  //#endregion MouseEvents

  //#region TouchEvents
  const onTouchStart = (event: TouchEvent) => {
    onDragStart(event.touches[0].clientX);
  };

  const onTouchMove = (event: TouchEvent) => {
    onDrag(event.touches[0].clientX);
  };

  const onTouchEnd = (event: TouchEvent) => {
    event.preventDefault();
    onDragStop(event);
  };
  //#endregion TouchEvents

  const onClick = (event: MouseEvent) => {
    event.preventDefault();
    switchRef.current.focus();

    setOutline(false);

    const bounds = switchRef.current.getBoundingClientRect();
    const posX = event.clientX - bounds.left;

    const denominator = bounds.width / (props.nullable === true ? 3 : 2);
    if ((props.nullable === true && posX > 2 * denominator) || (props.nullable !== true && posX >= denominator)) {
      setPos(checkedPos);
      onChange(event, true);
    } else if (posX < denominator) {
      setPos(uncheckedPos);
      onChange(event, false);
    } else {
      setPos(undefinedPos);
      onChange(event, undefined);
    }
  };

  const onChange = (event: MouseEvent | TouchEvent, checked: boolean | undefined) => {
    if (props.onChange)
      props.onChange(checked, event, props.id);
  };

  const setHasOutline = () => {
    setOutline(true);
  };

  const unsetHasOutline = () => {
    setOutline(false);
  };

  const handleStyle: CSSProperties = {
    height: handleDiameter,
    width: handleDiameter,
    backgroundColor: getBackgroundColor(pos, checkedPos, uncheckedPos, props.offHandleColor, props.onHandleColor),
    cursor: props.disabled ? "default" : "pointer",
    borderRadius: "50%",
    position: "absolute",
    transform: `translateX(${pos}px)`,
    top: Math.max(0, (height - handleDiameter) / 2),
    outline: 0,
    boxShadow: hasOutline ? props.activeBoxShadow : props.boxShadow,
    border: 0,
    WebkitTransition: isDragging ? undefined : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
    MozTransition: isDragging ? undefined : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
    transition: isDragging ? undefined : "background-color 0.25s, transform 0.25s, box-shadow 0.15s",
  };

  const backgroundStyle: CSSProperties = {
    ...props.style,
    height,
    width,
    maxHeight: height,
    minHeight: height,
    maxWidth: width,
    minWidth: width,
    position: "relative",
    backgroundColor: getBackgroundColor(pos, checkedPos, uncheckedPos, props.offColor, props.onColor, props.midColor),
    borderRadius: height / 2,
    cursor: props.disabled ? "default" : "pointer",
    WebkitTransition: isDragging ? undefined : "background 0.25s",
    MozTransition: isDragging ? undefined : "background 0.25s",
    transition: isDragging ? undefined : "background 0.25s",
  };

  const backgroundContentStyle: CSSProperties = {
    opacity: isDragging ? Math.abs((pos - uncheckedPos) / (checkedPos - uncheckedPos) - 0.5) * 2 : props.nullable && props.checked === undefined ? 0 : 1,
    WebkitTransition: isDragging ? undefined : "background 0.25s",
    MozTransition: isDragging ? undefined : "background 0.25s",
    transition: isDragging ? undefined : "background 0.25s",
  };

  return (
    <div
      className={"switch"}
      onBlur={unsetHasOutline}
      onFocus={setHasOutline}
      ref={switchRef}
      style={backgroundStyle}
    >
      <div
        className={"switch-content-background"}
        onClick={props.disabled ? undefined : onClick as unknown as React.MouseEventHandler<HTMLDivElement>}
        onMouseDown={(e) => e.preventDefault()}
        style={backgroundContentStyle}
      >
        {props.children}
      </div>
      <div
        className="switch-handle"
        onClick={(e) => e.preventDefault()}

        //We need to cast because we also move of those event are actually base events and needed to be that way
        onMouseDown={props.disabled ? undefined : onMouseDown as unknown as React.MouseEventHandler<HTMLDivElement>}
        onTouchStart={props.disabled ? undefined : onTouchStart as unknown as React.TouchEventHandler}
        onTouchMove={props.disabled ? undefined : onTouchMove as unknown as React.TouchEventHandler}
        onTouchEnd={props.disabled ? undefined : onTouchEnd as unknown as React.TouchEventHandler}
        onTouchCancel={props.disabled ? undefined : unsetHasOutline as unknown as React.TouchEventHandler}

        style={handleStyle}
      />
    </div>
  );
};

export default DeepSwitch;
