import React from "react";
import "./backButton.scss";
import Button from "components/common/button/Button";
import { IButtonProps } from "components/common/button/IButtonProps";
import { FaArrowLeft } from "react-icons/fa";

interface IProps extends IButtonProps {}

const BackButton = (props: IProps) => {
  return <Button className="backButton" 
          content={<FaArrowLeft />}
          tooltip={props.tooltip ? props.tooltip : "Retour"} 
          {...props} />;
  };

export default BackButton;
