import React from "react";
import PageContent from "pageWrapper/pageContent/PageContent";
import { IRoute } from "interfaces/IRoute";

interface IProps {
  route: IRoute;
}

const NoAccess = (props: IProps) => {
  return <PageContent>NO ACCESS</PageContent>;
};

export default NoAccess;
