//AZURE AUTHENTICATION
export var appsettings = {
  env: process.env.REACT_APP_ENV,
  apiUrl: process.env.REACT_APP_APIURL,
  googleApiKey: process.env.REACT_APP_GOOGLE_APIKEY,
  signalRApiUrl: process.env.REACT_APP_SIGNALR,
  azureAD: {
    authority: process.env.REACT_APP_AZUREAD_AUTHORITY,
    resetPasswordAuthority: process.env.REACT_APP_AZUREAD_RESET_PASSWORD_AUTHORITY,
    clientId: process.env.REACT_APP_AZUREAD_CLIENTID,
    scopes: [process.env.REACT_APP_AZUREAD_SCOPES],
  },
};
