import { IDefaultProps } from "interfaces/IDefaultProps";
import React, { CSSProperties } from "react";

export const makeClassName = (cls: string, props: IDefaultProps) => {
  const className = props.className !== undefined ? " " + props.className : "";
  const disabled = props.disabled === true ? " disabled" : "";
  const fullSize = props.fullSize !== undefined ? " fullSize" : "";
  const spaceBetween = props.spaceBetween !== undefined ? " spaceBetween" : "";
  const center = props.center === true ? " center" : "";
  const active = props.active === true ? " active" : "";
  const middle = props.middle === true ? " middle" : "";
  const fullHeight = props.fullHeight === true ? " fullHeight" : "";
  const right = props.right === true ? " right" : "";
  const midSize = props.midSize === true ? " midSize" : "";
  const minHeight = props.miHeight === true ? " minHeight" : "";
  const left = props.left === true ? " left" : "";
  const fullWidth = props.fullWidth === true ? " fullWidth" : "";
  const bigSize = props.bigSize === true ? " bigSize" : "";
  const medHeight = props.medHeight === true ? " medHeight" : "";
  const bottom = props.bottom === true ? " bottom" : "";
  const relative = props.relative === true ? " relative" : "";
  const hidden = props.hidden === true ? " hidden" : "";
  const smallSize = props.smallSize === true ? " smallSize" : "";
  const micro = props.micro === true ? " micro" : "";

  const ret =
    cls +
    className +
    fullSize +
    disabled +
    spaceBetween +
    center +
    active +
    middle +
    fullHeight +
    right +
    midSize +
    minHeight +
    left +
    fullWidth +
    bigSize +
    medHeight +
    bottom +
    relative +
    hidden +
    smallSize + 
    micro;

  return ret;
};

export const makeStyleProperties = (props: IDefaultProps, style?: object) => {
  const styles: CSSProperties = style !== undefined ? style : {};

  if (props.backgroundColor !== undefined) styles.backgroundColor = props.backgroundColor;
  if (props.display !== undefined) styles.display = props.display;
  if (props.position !== undefined) styles.position = props.position;
  if (props.flexDirection !== undefined) styles.flexDirection = props.flexDirection;
  if (props.flex !== undefined) styles.flex = props.flex;
  if (props.justifyContent !== undefined) styles.justifyContent = props.justifyContent;
  if (props.justifyItems !== undefined) styles.justifyItems = props.justifyItems;
  if (props.justifySelf !== undefined) styles.justifySelf = props.justifySelf;
  if (props.alignContent !== undefined) styles.alignContent = props.alignContent;
  if (props.alignItems !== undefined) styles.alignItems = props.alignItems;
  if (props.alignSelf !== undefined) styles.alignSelf = props.alignSelf;
  if (props.textAlign !== undefined) styles.textAlign = props.textAlign;
  if (props.width !== undefined) styles.width = props.width;
  if (props.minWidth !== undefined) styles.minWidth = props.minWidth;
  if (props.maxWidth !== undefined) styles.maxWidth = props.maxWidth;
  if (props.height !== undefined) styles.height = props.height;
  if (props.minHeight !== undefined) styles.minHeight = props.minHeight;
  if (props.maxHeight !== undefined) styles.maxHeight = props.maxHeight;
  if (props.opacity !== undefined) styles.opacity = props.opacity;
  if (props.margin !== undefined) styles.margin = props.margin;
  if (props.marginLeft !== undefined) styles.marginLeft = props.marginLeft;
  if (props.marginTop !== undefined) styles.marginTop = props.marginTop;
  if (props.marginRight !== undefined) styles.marginRight = props.marginRight;
  if (props.marginBottom !== undefined) styles.marginBottom = props.marginBottom;
  if (props.padding !== undefined) styles.padding = props.padding;
  if (props.paddingLeft !== undefined) styles.paddingLeft = props.paddingLeft;
  if (props.paddingTop !== undefined) styles.paddingTop = props.paddingTop;
  if (props.paddingRight !== undefined) styles.paddingRight = props.paddingRight;
  if (props.paddingBottom !== undefined) styles.paddingBottom = props.paddingBottom;
  if (props.overflow !== undefined) styles.overflow = props.overflow;
  if (props.overflowX !== undefined) styles.overflowX = props.overflowX;
  if (props.overflowY !== undefined) styles.overflowY = props.overflowY;
  if (props.fontSize !== undefined) styles.fontSize = props.fontSize;
  if (props.transform !== undefined) styles.transform = props.transform;

  return styles;
};

export const createComponent = (arr: any, auxComp: any = null) => {
  if (arr.length === 0) return "";
  const ret = arr.map((element: any, index: number) => <div key={index}>{element}</div>);
  if (auxComp) ret.push(<React.Fragment key={arr.length}>{auxComp}</React.Fragment>);

  return <div>{ret}</div>;
};
