import React, { useEffect, useState } from "react";
import { deepClone } from "utils/cloneUtils";
import { nameOf } from "utils/nameOfUtils";
import { getArrayKeysFromValue } from "utils/objectUtils";
import { calculatePageFilters } from "utils/pageUtils";
import { useChecks } from "hooks/useChecks";
import { useRoute } from "hooks/useRoute";

import { BusinessLeader } from "config/permsConf";

import { smartConnect } from "app/appUtils";
import { IPopupProps } from "components/common/popup/IPopupProps";
import Popup from "components/common/popup/Popup";
import Row from "components/common/row/Row";
import Table from "components/common/table/Table";
import { TABLE_ITEMS_PER_PAGE } from "config/conf";

import { getAllSectors1Api, getUserSectors1Api, updateUserSectors1Api } from "config/apiConf";
import { allSectors1Module, userSectors1Module, updateUserSectors1Module } from "config/modulesConf";
import { IAllSectors1Data, IAllSectors1Result, IUserSector1 } from "containers/sectors1/ISectors";
import Checkbox from "components/common/checkbox/Checkbox";
import { ITableProps } from "components/common/table/ITable";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import { IAllUsersResult } from "../IUsers";
import { ISmartState, ISmartAction } from "interfaces/ISmart";

interface IProps extends IPopupProps {
  user: IAllUsersResult;
  allSectors1?: ISmartState;
  allSectors1Actions?: ISmartAction;
  userSectors1?: ISmartState;
  userSectors1Actions?: ISmartAction;
  updateUserSectors1?: ISmartState;
  updateUserSectors1Actions?: ISmartAction;
}

const UpdateUserSectors1Popup = (props: IProps) => {
  const { addParams, getRouteFromParams } = useRoute();
  const [tblLoader, setTblLoader] = useState(false);
  const [tblData, setTblData] = useState() as any;
  const [crrPage, setCrrPage] = useState(1);
  const [pageSize, setPageSize] = useState(TABLE_ITEMS_PER_PAGE);
  const [tableSorter, setTableSorter] = useState({}) as any;
  const [tableFilters, setTableFilters] = useState({}) as any;
  const allSectors1: IAllSectors1Data = props.allSectors1?.data;
  const userSectors1: IUserSector1[] = props.userSectors1?.data;
  const roleRestriction = props.user.role === BusinessLeader;
  const uc = useChecks({single: roleRestriction});

  useEffect(() => {
    props.allSectors1Actions?.reset();
    props.userSectors1Actions?.reset();
    
    getData(crrPage, pageSize, tableFilters, tableSorter);

    return () => {
      props.allSectors1Actions?.reset();
      props.userSectors1Actions?.reset();
    };

    // eslint-disable-next-line
  }, []);

  const getData = (cPage: number, pageSize: number, tblFilters: any, tblSorter: any) => {
    setCrrPage(cPage);
    setPageSize(pageSize);
    setTblLoader(true);

    const filter: any = { 
      pageNumber: cPage
    };

    calculatePageFilters(filter, tblFilters, tableFilters, setTableFilters);
    calculatePageFilters(filter, tblSorter, tableSorter, setTableSorter);
    
    props.userSectors1Actions?.httpCall(getRouteFromParams(getUserSectors1Api, {userId: props.user?.id}));
    props.allSectors1Actions?.httpCall(addParams(getAllSectors1Api, filter));
  };

  useEffect(() => {
    if (props.allSectors1?.opCompleted && props.userSectors1?.opCompleted) {
      //Add Ids for uc checks
      allSectors1.results.forEach((sector1, index) => {
        sector1.id = index + 1;
      });

      setTblData(allSectors1.results);

      //Checks
      let newChecksData = deepClone(uc.checksData);
      if (userSectors1.length === allSectors1.results.length) {
        newChecksData.all = true;
      }
      allSectors1.results.forEach((element: IAllSectors1Result) => {
        newChecksData[element.id] = userSectors1.some(s => s.sector1 === element.sector1)
      });
      uc.setData(newChecksData);
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allSectors1?.opCompleted, props.userSectors1?.opCompleted]);

  useEffect(() => {
    if (props.allSectors1?.errors || props.allSectors1?.opCompleted 
      || props.updateUserSectors1?.opCompleted || props.updateUserSectors1?.errors) {
      setTblLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allSectors1?.errors, props.allSectors1?.opCompleted, props.updateUserSectors1?.opCompleted, props.updateUserSectors1?.errors]);

  const save = (values: any) => {
    setTblLoader(true);
    var checkedIds = getArrayKeysFromValue(uc.checksData, true, ["all"]);
    var selectedSectors1 = allSectors1.results.filter(us => checkedIds.some((id : number) => id.toString() === us.id.toString())).map(s => s.sector1);
    props.updateUserSectors1Actions?.httpCall(getRouteFromParams(updateUserSectors1Api, {userId: props.user?.id}), {userId: props.user.id, sectors1: selectedSectors1 });
  }

  const disabled = false;
  return (
    <Popup {...props} bigSize={true} title={"Secteurs de l'utilisateur"}
    footerContent={<Row><NeuroButton color="green" size={'normal'} onClick={save} param={uc.checksData} label={"Sauvegarder"} disabled={false} /></Row>}>
        <Table style={{marginBottom: `50px`}}
        totalRows={allSectors1?.totalRows}
        hidePagination={true}
        onChange={(cp: number, ps: number) => getData(cp, ps, tableFilters, tableSorter)}
        onHeaderFilterChange={(colId: any, value: any) => getData(1, pageSize, { [colId]: value }, tableSorter)}
        onSort={(st: any) => getData(crrPage, pageSize, tableFilters, st)}
        sortBy={tableSorter}
        maxPages={allSectors1?.pageCount}
        loader={tblLoader}
        data={tblData}
        crrPage={crrPage}
        selectedRows={uc.checksData}
        columns={[
          {
            headerFilterProps: {
              type: "number",
            },
            Header: "x",
            hideHeaderLabel: true,
            headerFilter: false,
            columnWidth: 30,
            headerComponent: <Checkbox disabled={roleRestriction} checked={uc.checksData.all} onChange={(val: boolean) => uc.calculate(val)} />,
            Cell: (props: ITableProps<IAllSectors1Result>) => (
              <Checkbox
                disabled={disabled}
                onChange={(val: boolean) => uc.calculate(val, props.row.original.id)}
                checked={uc.checksData[props.row.original.id]}
              />
            ),
          },
          {
            Header: "Secteur",
            accessor: nameOf((n: IAllSectors1Result) => n.sector1),
            Cell: (props: ITableProps<IAllSectors1Result>) =>
              (props.row.original as IAllSectors1Result).sector1
                ? (props.row.original as IAllSectors1Result).sector1
                : null,
          },
        ]} />
    </Popup>
  );
};

export default smartConnect(
  [userSectors1Module, allSectors1Module, updateUserSectors1Module],
  [userSectors1Module, allSectors1Module, updateUserSectors1Module]
)(UpdateUserSectors1Popup);
