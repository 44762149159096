import React from "react";
import "./overlayMenu.scss";
import { smartConnect } from "app/appUtils";
import Popup from "components/common/popup/Popup";
import { IPopupProps } from "components/common/popup/IPopupProps";
import { INavbarContentItem } from "../navbarContentItem/INavbarContentItem";
import NavbarContentItem from "../navbarContentItem/NavbarContentItem";
import { userSettingsModule } from "config/modulesConf";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";
import { ISmartState } from "interfaces/ISmart";

interface IProps extends IPopupProps {
  items: INavbarContentItem[];
  userSettings?: ISmartState;
}

const OverlayMenu = (props: IProps) => {
  const userSettingsData: IUserSettingsData = props.userSettings?.data;
  
  const buildItems = (data: INavbarContentItem, index: number) => {

    if (data.roleRequired !== undefined) {
      const hasPermission = data.roleRequired.filter((element: any) => element === userSettingsData.role)[0]
      if (!hasPermission) return null;
    }
  return <NavbarContentItem key={index} {...data} onSelect={()=>props.onClose(false)} />;
  };

  return <Popup className="overlayMenu" {...props}>
      {props.items.map(buildItems)}
  </Popup>;
};

export default smartConnect([userSettingsModule], [])(OverlayMenu);
