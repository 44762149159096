import { ISmartAction } from "interfaces/ISmart";

export const getFileExtension = (file: any) => {
  return file.name.split(".").pop();
};

export const getFileName = (file: any) => {
  return file.name;
};

export const downloadFileHandler = (fileName: string, fileContent: string, smartActionToReset?: ISmartAction) => {
  let link = document.createElement("a");
  link.download = fileName;
  link.href = fileContent;
  link.click();
  document.body.appendChild(link);
  if (smartActionToReset) smartActionToReset?.reset();
};
