import React, { useEffect, useState } from "react";
import { smartConnect } from "app/appUtils";
import { allImportsModule, allImportsActionsModule } from "config/modulesConf";
import { ISmartAction, ISmartState } from "interfaces/ISmart";
import FileUpload from "components/common/fileUpload/FileUpload";
import Form from "components/common/form/Form";
import NeuroButton from "components/buttons/neuroButton/NeuroButton";
import { smartStringValidator, smartValidationSchema, useSmartForm } from "hooks/useSmartForm";
import { importPromoterInterventionPointcampaignsApi } from "config/apiConf";
import { ACCEPTED_IMPORT_FILE, BUTTON_TYPE_SUBMIT, FILE_VALIDATION } from "config/conf";
import { IImportFileResult } from "./IImport";
import { nameOf } from "utils/nameOfUtils";
import * as lngConf from "config/lngConf";
import { useTranslate } from "services/translationService";
import Column from "components/common/column/Column";
import Label from "components/common/label/Label";

interface IProps {
  data?: any;
  allImports?: ISmartState;
  allImportsActions?: ISmartAction;
}

const AllImports = (props: IProps) => {
  const [disabledBtn, setDisabledBtns] = useState(true);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const errors: string | undefined = props.allImports?.errors?.errors;
  const { t } = useTranslate();

  useEffect(() => {
    props.allImportsActions?.reset();
    return (()=>{
      props.allImportsActions?.reset();
    })
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.allImports?.opCompleted) {
      setLoader(false);
      setSuccess("Import réussi");
    }
    // eslint-disable-next-line
  }, [props.allImports?.opCompleted]);

  useEffect(() => {
    if (props.allImports?.errors) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [props.allImports?.errors]);

  const importPromoterInterventionPoints = (values: any) => {
    setLoader(true);
    props.allImportsActions?.httpCall(importPromoterInterventionPointcampaignsApi, values);
  };

  const smartFormImportPromoterInterventionPoints = useSmartForm({
    initialValues: {
      fileToImport: null,
    },
    validationSchema: smartValidationSchema({
      fileToImport: smartStringValidator().required(t(lngConf.ERR_REQ_FILE)),
    }),
    onSubmit: (values: any) => {
      importPromoterInterventionPoints(values);
      setDisabledBtns(true);
    },
    onChanged: () => {
      setDisabledBtns(false);
      setSuccess("");
    },
  });

  const fileToImport = nameOf((n: IImportFileResult) => n.fileToImport);
  return (
      <Form>
        <Column justifySelf='center' alignSelf='center' width='500px' height='300px' marginTop='50px'>
          <FileUpload
            value={smartFormImportPromoterInterventionPoints.getValue(fileToImport)}
            name={fileToImport}
            error={smartFormImportPromoterInterventionPoints.getError(fileToImport)}
            onNativeChange={smartFormImportPromoterInterventionPoints.change}
            label={t(lngConf.PH_CHOOSE_PROMOTERS_POS_FILE)}
            accept={ACCEPTED_IMPORT_FILE}
            validationTypes={FILE_VALIDATION}
            success={success}
          />
          <NeuroButton loader={loader} disabled={disabledBtn} type={BUTTON_TYPE_SUBMIT} onClick={smartFormImportPromoterInterventionPoints.submit} color='green' label={t(lngConf.BTN_SEND)} />
          {errors !== undefined ? errors[0].split('\n').map(e => <Label text={e}/>) : null}
        </Column>
      </Form>
  );
};

export default smartConnect([allImportsModule, allImportsActionsModule], [allImportsModule, allImportsActionsModule])(AllImports);
