import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import { getArrayFromKey } from "utils/objectUtils";
import { useTranslation } from "react-i18next";

export const setupTranslation = (languagesConfig: any, defaultLanguage: string = "") => {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      debug: false,
      lng: defaultLanguage,
      whitelist: getArrayFromKey(languagesConfig, "language"),
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false,
      },
      backend: {
        requestOptions: {
            mode: 'no-cors',
            cache: 'reload',
        }
      }
    });

  return {
    instance: i18n,
    connectTo: withTranslation,
  };
};

export const useTranslate = () => {
  const { t } = useTranslation();

  return { t, i18n };
};

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang);

  return { data: { lang: lang } };
};
