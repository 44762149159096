export const deepClone = (data: any) => {
  if (data == null) {
    return null;
  } else {
    try {
      return JSON.parse(JSON.stringify(data));
    } catch (e) {
      if (typeof data === "object") {
        return Object.assign({}, data);
      }
    }
  }
};
