import React, { useState, useEffect, useMemo } from "react";
import { ISmartState, ISmartAction } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import "./charts.scss";

import { sector1StatisticsModule, userSettingsModule } from "config/modulesConf";
import { getSector1StatisticsApi } from "config/apiConf";

import moment from "moment";
import { ISector1StatisticsData, ISector1StatisticsResult } from "./IStatistics";
import Label from "components/common/label/Label";
import StackBarChart, { SimpleStackBarData } from "components/chart/stackBarChart/StackBarChart";
import SimpleTable, { TableColumn, TableParams } from "components/common/table/simpleTable/SimpleTable";
import Row from "components/common/row/Row";


interface IProps {
    filters: any;
    dateFrom: Date;
    dateTo: Date;
    registerFilter: (name: any, value: any) => void;
    sector1Statistics?: ISmartState;
    sector1StatisticsActions?: ISmartAction;
}

interface ISize {
    width: number
    height: number
}

const Sector1StatisticTable = (props: IProps) => {
    const [tblLoader, setTblLoader] = useState(true);
    const [tableParams, setTableParams] = useState<TableParams>({ page: { number: 1 } });
    const sector1StatisticsData: ISector1StatisticsData = props.sector1Statistics?.data;
    const colors = ['#2E93fA', '#FF9800', '#16A085', '#CB4335', '#2980B9', '#40E0D0', '#95A5A6', '#27AE60', '#F1C40F', '#E74C3C',
        '#34495E', '#616A6B', '#8E44AD', '#CCCCFF', '#DE3163', '#9FE2BF']

    useEffect(() => {
        props.sector1StatisticsActions?.reset();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTableParams({...tableParams, filters: props.filters})
        // eslint-disable-next-line
    }, [props.filters]);

    useEffect(() => {
        if (props.dateFrom && props.dateTo)// currentMonth)
        {
            window.scrollTo(0, 0)
            setTblLoader(true);
            getData();
        }

        // eslint-disable-next-line
    }, [props.dateFrom, props.dateTo, tableParams]); //currentMonth

    useEffect(() => {
        if (props.sector1Statistics?.opCompleted) {
            setTblLoader(false);
        }
        // eslint-disable-next-line
    }, [props.sector1Statistics?.opCompleted]);

    const getData = () => {
        setTblLoader(true);

        const filters = {...tableParams.filters}
        filters.pageNumber = tableParams.page?.number;
        filters.pageSize = tableParams.page?.size;
        filters.sortColumn = tableParams.sort?.column;
        filters.sortOrder = tableParams.sort?.order;
        filters.startDate = moment(props.dateFrom).format("YYYY-MM-DD");
        filters.endDate = moment(props.dateTo).format("YYYY-MM-DD");

        props.sector1StatisticsActions?.httpCall(getSector1StatisticsApi, filters);
    };

    const memoColumns = useMemo((): TableColumn<ISector1StatisticsResult>[] => {
        const totalMissions = sector1StatisticsData?.results?.reduce((a, b) => a + b.totalMissions, 0);
        const totalMissionBackOffice = sector1StatisticsData?.results?.reduce((a, b) => a + b.totalMissionsBackOffice, 0);
        const totalQuota = sector1StatisticsData?.results?.reduce((a, b) => a + b.quota, 0);
        const missing = (totalQuota && totalMissions) ? Math.max(totalQuota - totalMissions, 0) : undefined;

        return [
            {
                Header: () => <Label text="Secteur" weight="bold" />,
                Cell: (_props: ISector1StatisticsResult) => <span>{_props.sector1}</span>,
                sortable: true,
                accessor: "sector1",
                minWidth: 150,
            },
            {
                Header: () => <Label text="Zones" weight="bold" />,
                width: '100%',
                minWidth: 300,
                accessor: "sector3Statistics",
                Cell: (_props: ISector1StatisticsResult) => {
                    if (!_props.sector3Statistics) {
                        return null;
                    }
                    var data: SimpleStackBarData[] = _props.sector3Statistics.map((s1, index) =>
                    ({
                        title: _props.sector1,
                        value: s1.totalMissions,
                        valueText: `${s1.totalMissions} Animation(s)`,
                        label: s1.totalMissions.toString(),
                        color: colors[index],
                        colorText: `${s1.sector3}`,
                    }));
                    return (data.length > 0)
                        ? <StackBarChart id={_props.sector1} data={data} height="40px" />
                        : null;
                }
            },
            {
                Header: () => <Label text="Animations" weight="bold" />,
                Filter: () => <Label text={totalMissions?.toString()} weight="bold" size="medium" color="red" />,
                Cell: (_props: ISector1StatisticsResult) => {
                    const percentage = Math.round(_props.totalMissions * 100 / _props.quota);
                    const displayPercentage = !isNaN(percentage) && isFinite(percentage);
                    return (
                        <Row width={"100%"} justifyContent="center">
                            <Label text={_props.totalMissions.toString()} textAlign="center" color="green" weight="bold" />
                            <Label text={displayPercentage ? ` (${percentage}%)` : "N/A"} textAlign="center" weight="bold" />
                        </Row>)
                },
                accessor: "totalMissions",
                minWidth: 80,
            },
            {
                Header: () => <Label text="BackOffice" weight="bold" />,
                Filter: () => <Label text={totalMissionBackOffice?.toString()} weight="bold" size="medium" color="red" />,
                minWidth: 80,
                accessor: "BackOffice",
                Cell: (_props: ISector1StatisticsResult) => <Label text={_props.totalMissionsBackOffice.toString()} textAlign="center" width={"100%"} color="black" weight="bold" />,
            },
            {
                Header: () => <Label text="Manquant" weight="bold" />,
                Filter: () => <Label text={missing?.toString()} weight="bold" size="medium" color="red" />,
                minWidth: 80,
                accessor: "Manquant",
                Cell: (_props: ISector1StatisticsResult) => <Label text={(Math.max(_props.quota - _props.totalMissions, 0)).toString()} textAlign="center" width={"100%"} color="orange" weight="bold" />,
            },
            {
                Header: () => <Label text="Quota" weight="bold" />,
                Filter: () => <Label text={totalQuota?.toString()} weight="bold" size="medium" color="red" />,
                minWidth: 80,
                accessor: "Quota",
                Cell: (_props: ISector1StatisticsResult) => <Label text={_props.quota.toString()} textAlign="center" width={"100%"} color="blue" weight="bold" />,
            },
        ]
      // eslint-disable-next-line
    }, [sector1StatisticsData]);

    return (
        <SimpleTable
            columns={memoColumns}
            data={sector1StatisticsData}
            virtualized={true}
            itemsSize={() => 50}
            params={tableParams}
            paramsChanged={setTableParams}
            loading={tblLoader}
        />
    );
};

export default smartConnect([userSettingsModule, sector1StatisticsModule], [sector1StatisticsModule])(Sector1StatisticTable);