import * as routeConf from "./routeConf";
import * as lngConf from "./lngConf";
import { INavbarContentItem } from "pageWrapper/navbar/navbarContentItem/INavbarContentItem";
import * as permsConf from "./permsConf";

export const animations: INavbarContentItem = {
  label: lngConf.NV_ANIMATIONSPAGE,
  route: routeConf.allAnimationsRoute,
  roleRequired: [permsConf.BusinessLeader, permsConf.AreaManager, permsConf.Administrator, permsConf.SuperAdministrator],
};

export const users: INavbarContentItem = {
  label: lngConf.NV_USERSPAGE,
  route: routeConf.allUsersRoute,
  roleRequired: [permsConf.AreaManager, permsConf.Administrator, permsConf.SuperAdministrator],
};

export const statistics: INavbarContentItem = {
  label: lngConf.NV_STATISTICSPAGE,
  route: routeConf.statisticsRoute,
  roleRequired: [permsConf.Administrator, permsConf.SuperAdministrator, permsConf.AreaManager, permsConf.BusinessLeader],
};

export const sectors1: INavbarContentItem = {
  label: lngConf.NV_SECTORSPAGE,
  route: routeConf.allSectors1Route,
  roleRequired: [permsConf.Administrator, permsConf.SuperAdministrator],
};

export const interventionPoints: INavbarContentItem = {
  label: lngConf.NV_INTERVENTIONPOINTSPAGE,
  route: routeConf.allInterventionPointsRoute,
  roleRequired: [permsConf.Administrator, permsConf.SuperAdministrator, permsConf.AreaManager, permsConf.BusinessLeader],
};

export const imports: INavbarContentItem = {
  label: lngConf.NV_IMPORTSPAGE,
  route: routeConf.allImportsRoute,
  roleRequired: [permsConf.SuperAdministrator],
};