import React from "react";
import "./label.scss";
import { IDefaultProps } from "interfaces/IDefaultProps";
import { makeStyleProperties } from "utils/componentUtils";

export type LabelColor = "black" | "gray" | "white" | "red" | "green" | "blue" | "orange";
export type LabelSize = "tiny" | "small" | "normal" | "medium" | "large" | "big" | "title";
interface IProps extends IDefaultProps {
  clamp?: boolean;
  color?: LabelColor;
  onClick?: React.MouseEventHandler<HTMLLabelElement>;
  size?: LabelSize;
  text?: string;
  weight?: "light" | "semi-light" | "normal" | "semi-bold" | "bold";
}

const Label = (props: IProps) => {

  const makeClassName = () => {
    let className = "simplelabel";
    if (props.color)
      className += ` colorlbl--${props.color}`;
    if (props.size)
      className += ` sizelbl--${props.size}`;
    if (props.weight)
      className += ` weightlbl--${props.weight}`;
    if (props.clamp)
      className += " clamp";

    return className;
  };

  return (
      <label className={makeClassName()}
      style={makeStyleProperties(props, props.style)}>
        {props.text}
      </label>
  );
};

export default Label;
