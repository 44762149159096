import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phone.scss";

interface IProps {
  value?: string;
  label?: string;
  required?: boolean;
  error?: string;
  onChange?: any;
  onNativeChange?: any;
  name?: string;
  disabled?: boolean;
}

const Phone = (props: IProps) => {
  const [value, setValue] = useState(props.value);

  const change = (val: any) => {
    if (props.onChange) props.onChange(val);
  };

  const onNativeChange = (val: any) => {
    if (props.onNativeChange)
      props.onNativeChange({
        target: {
          name: props.name,
          value: val,
        },
      });
  };

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <div className="phone">
      {props.label && (
        <span className="label">
          {props.label}
          {props.required ? <span className="required"></span> : ""}
        </span>
      )}
      <PhoneInput
        disabled={props.disabled}
        containerClass="phone-content"
        country={"fr"}
        value={value}
        onChange={(val: any) => {
          props.onNativeChange ? onNativeChange(val) : change(val);
        }}
      />
      {props.error ? <span className="error">{props.error}</span> : null}
    </div>
  );
};

export default Phone;