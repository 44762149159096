import React, { useEffect, useState } from "react";
import { ISmartState } from "interfaces/ISmart";
import { smartConnect } from "app/appUtils";
import Chart from "react-apexcharts";
import moment from 'moment'

import { userSettingsModule } from "config/modulesConf";

import { IBookingStatisticsData } from "./ICharts";
import { getNumberOfDaysInMonth } from "utils/dateUtils";
import "./charts.scss";
import { IUserSettingsData } from "interfaces/modules/IUserSettings";


interface IProps {
  userSettings?: ISmartState<IUserSettingsData>;
}

const BookingStatistics = (props: IProps) => {
  const [max, setMax] = useState<number>(10);
  const [series, setSeries] = useState<number[]>([]);
  const [firstMonthColor, setFirstMonthColor] = useState<string>("#0000FF")
  const data: IBookingStatisticsData = props.userSettings?.data?.bookingStatistics ?? { 
    currentMonth: {count: 0, total: 0},
    nextMonth: {count: 0, total: 0},
  };

  useEffect(() => {
    if (props.userSettings?.opCompleted) {
      setSeries([data.currentMonth.count, data.nextMonth.count]);
      setMax(Math.max(data.currentMonth.total, data.nextMonth.total));
      setFirstMonthColor(getColorForCurrentMonth());
    }
    // eslint-disable-next-line
  }, [props.userSettings?.opCompleted]);

  useEffect(() => {
    if (props.userSettings?.errors) {
      setSeries([]);
    }
    // eslint-disable-next-line
  }, [props.userSettings?.errors]);

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  var categories = [
    `${capitalizeFirstLetter(moment().format("MMMM"))} ${data.currentMonth?.count} / ${data?.currentMonth?.total}`,
    `${capitalizeFirstLetter(moment().add(1, 'M').format("MMMM"))} ${data?.nextMonth?.count} / ${data?.nextMonth?.total}`
  ]

  const getColorForCurrentMonth = () => {
    if (data?.currentMonth?.total) {
      const date = new Date();
      const daysInCurrentMonth = getNumberOfDaysInMonth(date.getMonth(), date.getFullYear());
      const quotaRatio = data?.currentMonth?.count / data.currentMonth?.total;
      const monthRatio = date.getDate() / daysInCurrentMonth;

      if (quotaRatio + 0.1 < monthRatio) {
        return "#F2685C"; //red
      } else if (quotaRatio - 0.1 > monthRatio) {
        return "#23DC3D"; //green
      }
    }
    return "#0000FF"; //blue
  }

  var options = {
    colors: [firstMonthColor, "#0000FF"],
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: true
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      show: false,
      categories: categories,
      max: max,
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      show: true,
    }
  };

  return (
    <div className="chartBox">
      <Chart options={options} series={[{ data: series }]} type={'bar'} />
    </div>
  );
};
export default smartConnect([userSettingsModule], [])(BookingStatistics);
